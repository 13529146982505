import { getClusterId } from './getClusterId';
import { PoiClusterListItem, PoiListAggregate, PoiListItemType } from '../model/poibase';

/**
 * Convert an ESRI cluster to an aggregate for the display on the map
 *
 * @param cluster ESRI clusters
 * @returns
 */
export const clusterToAggregate = (cluster: PoiClusterListItem): PoiListAggregate => {
  const agg = new PoiListAggregate();
  agg.coordinates = cluster.coordinates;
  agg.id = getClusterId(cluster);
  agg.count = cluster.pois.length;
  agg.name = null;
  agg.type = PoiListItemType.aggregate;
  return agg;
};
