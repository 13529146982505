import { Component, Input } from '@angular/core';

import { PoiListAggregate } from '@app/app/gis/model/poibase';

@Component({
  selector: 'app-aggregate-info-window',
  templateUrl: './aggregate-info-window.component.html',
  styleUrls: ['./aggregate-info-window.component.scss'],
})
export class AggregateInfoWindowComponent {
  @Input() poi: PoiListAggregate = null;
}
