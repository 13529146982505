import { AfterViewInit, Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';

import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { auditTime, distinctUntilChanged, throttleTime } from 'rxjs/operators';

import { ControllerService } from 'src/app/controller.service';
import { PoiList } from '@app/app/gis/model/poilist';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';

@Directive()
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseResultListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('resultlist') resultListElement: ElementRef;

  public poiList: PoiList;
  public showPleaseZoom = false;
  public showNoResults = false;
  public noPoisOnMap = false;
  public resultCount: Observable<number>;
  public resultCountObserver: IntersectionObserver;
  public scrollListener: EventListener;

  protected resizeEvent = new Subject<void>();
  protected isDesktop = false;
  protected isExpanding = false;
  protected zoomLevel: number;

  protected searchInitSub$: Subscription;
  protected zoomLevelSub$: Subscription;

  constructor(protected controllerService: ControllerService,
    @Inject(PLATFORM_ID) protected platformId: any) {

    this.poiList = new PoiList();
    this.resultCount = controllerService.getResultCount();

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    // Track visibility of the result list to change its click behaviour
    if (window && 'IntersectionObserver' in window) {
      this.resultCountObserver = new IntersectionObserver(this.toggleFromIntersectionObserver, {
        threshold: 0,
      });
    }

    this.isDesktop = window && window.innerWidth > 1024;
    this.resizeEvent
      .asObservable()
      .pipe(throttleTime(200))
      .subscribe(() => {
        this.isDesktop = window && window.innerWidth > 1024;
      });
  }

  @HostListener('window:resize')
  public onWindowResize() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.resizeEvent.next();
  }

  ngOnInit(): void {
    this.searchInitSub$ = this.controllerService.getSearchObservable().subscribe((pois: PoiList) => {
      this.showResult(pois);
    });

    this.zoomLevelSub$ = this.controllerService.getZoomLevelObservable().subscribe((zoomLevel) => {
      this.zoomLevel = zoomLevel;
    });
  }

  ngAfterViewInit(): void {
    if (this.resultCountObserver) {
      this.resultCountObserver.observe(this.resultListElement.nativeElement);
    }
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (window) {
      fromEvent(window, 'scroll')
        .pipe(untilDestroyed(this), auditTime(100), distinctUntilChanged())
        .subscribe(() => this.toggleFromScrollListener());
    }
  }

  ngOnDestroy(): void {
    if (this.searchInitSub$) {
      this.searchInitSub$.unsubscribe();
    }

    if (this.zoomLevelSub$) {
      this.zoomLevelSub$.unsubscribe();
    }

    if (this.resultCountObserver) {
      this.resultCountObserver.unobserve(this.resultListElement.nativeElement);
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (window) {
      window.removeEventListener('scroll', this.toggleFromScrollListener);
    }
  }

  public scrollResultList() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const e: HTMLElement = this.resultListElement.nativeElement;
    const w = window;
    const d = document.documentElement;

    // Compat check
    if (!e || !e.classList || !e.getBoundingClientRect) {
      return;
    }

    const bcr = e.getBoundingClientRect();

    // Get position of element relative to document element
    // https://stackoverflow.com/a/18673641/1059361
    const eOffsetTop = bcr.top + d.scrollTop;

    let scrollTo;

    // Check if the result list is clearly visible (class toggled by the intersection observer), if yes, hide it, otherwise, show it
    if (e.classList.contains('is-clearly-visible')) {
      scrollTo = eOffsetTop - w.innerHeight + 100;
    } else {
      scrollTo = bcr.height > w.innerHeight / 2 ? eOffsetTop - w.innerHeight / 3 : eOffsetTop - w.innerHeight + bcr.height;
    }

    try {
      w.scrollTo({
        top: scrollTo,
        behavior: 'smooth',
      });
    } catch {
      d.scrollTop = scrollTo;
    }
  }

  scrollToSearch() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } catch {
      document.documentElement.scrollTop = 0;
    }
  }

  tooltipPlacement() {
    return this.isDesktop ? 'right' : 'left';
  }

  disableTooltip() {
    return this.isDesktop ? false : true;
  }

  expandSearch() {
    this.controllerService.setZoom(this.zoomLevel - 1);
    this.isExpanding = true;
  }

  private toggleFromScrollListener() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const e: HTMLElement = this.resultListElement.nativeElement;

    // Compat check
    if (!e || !e.classList || !e.getBoundingClientRect) {
      return;
    }
    const bcr = e.getBoundingClientRect();
    e.classList.toggle('is-clearly-visible', bcr.top <= window.innerHeight / 2 && window.scrollY > 200);
  }

  private toggleFromIntersectionObserver([entry]: IntersectionObserverEntry[]) {
    // Check if the result list covers more than 50% of the viewport or is in sticky mode / scrolled to the bottom
    entry.target.classList.toggle(
      'is-clearly-visible',
      entry.intersectionRatio > 0.8 || (entry.intersectionRect.top === 0 && entry.intersectionRect.height > 0)
    );
  }

  protected abstract showResult(poiList: PoiList): void;
  protected abstract editSearch(): void;
  protected abstract expandSearchClick(): void;
}
