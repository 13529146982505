/* eslint-disable @typescript-eslint/naming-convention */
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import packageJson from '../../../package.json';
import { TranslateExtendedService } from './translate-extended.service';

@Injectable()
export class TagManagerService {
    config: { platform: string; environment: string; container: string };

    constructor(
        private translateExtendedService: TranslateExtendedService,
        private logger: NGXLogger,
        @Inject(PLATFORM_ID) private platformId: object) { }

    // Config settings used to define GTM endpoint and page view tag data
    public setConfig(config: { platform: string; environment: string; container: string }) {

        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.config = config;
    }

    /**
     * Adds the GTM script and pushes a view tag when the script is loaded
     */
    public loadScript(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const s = 'script';
        const l = 'dataLayer';
        const container = this.config.container;

        window[l] = window[l] || [];
        window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

        const firstScript = document.getElementsByTagName(s)[0];
        const gtmScript = document.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';

        gtmScript.async = true;
        gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + container + dl;

        firstScript.parentNode.insertBefore(gtmScript, firstScript);
    }

    /**
     * Public function for GMT tags triggerd by page call
     *
     * @param data GTM view data
     */
    public view(view: TagManagerView): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const url = document.URL.split('?')[0].split('#')[0];
        const unifiedUrl = view.unifiedURL ? view.unifiedURL.split('?')[0].split('#')[0] : url;

        this.translateExtendedService.getGermanTranslation('home.meta.og.title').subscribe((title) => {
            const data: any = {
                event: view.event.toLowerCase(),
                page_id: view.pageId.toLowerCase(),
                page_name_language_neutral: view.unifiedPageName.toLowerCase(),
                page_url_language_neutral: unifiedUrl,
                page_type: view.pageType.toLowerCase(),
                department_name: 'K33',
                business_unit: 'K',
                primary_segment: 'allgemein',
                page_category1: title.toLowerCase(),
                page_category2: view.unifiedPageName.toLowerCase(),
                content_geo_region: 'national',
                content_language: this.translateExtendedService.currentLang.toLowerCase(),
                source_code_version: packageJson.version,
                platform_name: this.config.platform.toLowerCase(),
                environment: this.config.environment,
                internal: 'false',
                login_status: 'false',
            };
            this.track(data);
        });
    }

    /**
     * Public function for GMT tags triggerd by page change (modal)
     *
     * @param data GTM view data
     */
    public change(view: TagManagerView): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const url = document.URL.split('?')[0].split('#')[0];
        const unifiedUrl = view.unifiedURL ? view.unifiedURL.split('?')[0].split('#')[0] : url;

        this.translateExtendedService.getGermanTranslation('home.meta.og.title').subscribe((title) => {
            const data: any = {
                event: view.event.toLowerCase(),
                page_id: view.pageId.toLowerCase(),
                page_name_language_neutral: view.unifiedPageName.toLowerCase(),
                page_url_language_neutral: unifiedUrl,
                page_type: view.pageType.toLowerCase(),
                page_category1: title.toLowerCase(),
                page_category2: view.unifiedPageName.toLowerCase(),
                content_geo_region: 'national',
                content_language: this.translateExtendedService.currentLang.toLowerCase(),
                virtual_pageview: 'true',
                virtual_url: unifiedUrl,
                virtual_page_name: view.pageName.toLowerCase(),
            };
            this.track(data);
        });
    }

    /**
     * Public function for GMT tags triggered by user interaction
     *
     * @param data GTM click data
     */
    public click(data?: TagManagerClick): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.track(data);
    }

    /**
     * Creates a GMT tag with the given data
     *
     * @param data GTM view or click data
     */
    private track(data?: any): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        if ((window as any).dataLayer === undefined) {
            this.logger.error('Google Tag Manager not found.');
        } else {
            (window as any).dataLayer.push(data);
        }
    }
}

export interface TagManagerView {
    event: string;
    pageId: string;
    pageName: string;
    unifiedPageName: string;
    pageType: string;
    unifiedURL?: string;
}

export interface TagManagerKeys {
    event: string;  // static, required
    page_id?: string;  // recommended
    page_name_language_neutral: string;   // required
    page_url_language_neutral: string;  // required
    page_type?: string;  // recommended
    department_name: string;  // required
    department_id?: string;  // recommended
    business_unit: string;  // required
    primary_segment: string;  // required
    secondary_segment?: string;  // recommended
    page_category1?: string;  // recommended
    page_category2?: string;  // recommended
    page_category3?: string;  // recommended
    page_category4?: string;  // recommended
    content_geo_region: string;  // required, default "national"
    content_language: string;  // required
    source_code_version?: string;  // recommended
    platform_name: string;  // required, provided
    environment: string;  // required
    internal: string;  // required
    login_status: string;  // required
    // only for SPA page transitions
    virtual_pageview: string;  // required for SPA transitions
    virtual_url: string;  // required for SPA transitions
    virtual_page_name: string;  // required for SPA transitions
    // only for search (events)
    search_results?: string;  // required for search results
    search_term?: string;  // required for search results
    original_search_term?: string;  // recommended for search results
    // may contain additional parameters, depending on site
}

export interface TagManagerClick {
    event: string;
    type?: string;
    label?: string;
    text?: string;
    link_text?: string;
    link_url?: string;
    additional_info?: string;
    search_results?: number;
    search_term?: string;
    original_search_term?: string;
}
