import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/app/config/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Mp24Service {
  private mp24Url: string;

  constructor(private configurationService: ConfigurationService, private http: HttpClient) {
    const configuration = this.configurationService.getConfiguration();
    this.mp24Url = configuration.mp24Url;
  }

  // Fetch all terminals
  getAllTerminals(): Observable<any> {
    return this.http.get(`${this.mp24Url}/terminals`);
  }

  // Fetch a terminal by ID
  getTerminalById(id: string, includeTimestamp: boolean = true): Observable<any> {
    return this.http.get(`${this.mp24Url}/terminals/${id}?includeTimestamp=${includeTimestamp}`);
  }

  // Fetch a terminal by technical zip code
  getTerminalsByTechnicalZip(technicalZip: number, includeTimestamp: boolean = true): Observable<any> {
    return this.http.get(`${this.mp24Url}/terminals/technicalzip/${technicalZip}?includeTimestamp=${includeTimestamp}`);
  }

  // Fetch the count of all terminals
  getTerminalsCount(): Observable<any> {
    return this.http.get(`${this.mp24Url}/terminals/count`);
  }
}
