import { Observable } from 'rxjs';

import { SimpleCoordinates } from '../model/simplecoordinates';

export abstract class BaseLocationService {

  /**
   * Returns true if location service is enabled
   * Could be made the sync way now. I leave it as it is.
   */
  public abstract isLocationServiceEnabled(): Observable<boolean>;

  /**
   * Tries to get the current coordinates. The browser will ask the user for permission
   * if not granted already.
   */
  public abstract askForPermission(): Observable<boolean>;

  /**
   * Gets the last position; reads it form cache.
   * Check if it is enabled first with isLocationServiceEnabled.
   * Could be made the sync way now. I leave it as it is.
   */
  public abstract getLocation(): Observable<SimpleCoordinates>;
}
