import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { combineLatest, Observable, of, zip } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { WINDOW } from 'src/app/common/window.service';

import { ConfigurationService } from 'src/app/config/configuration.service';
import { NeedConfig } from '../model/needconfig';
import { SecondLevelNeed } from '../model/secondlevelneed';
import { ServiceTypesCache } from '../model/types-cache.type';
import { TypesCacheService } from './types-cache.service';

@Injectable({
  providedIn: 'root',
})
export class NeedsCacheService {
  private _needIdMap: Observable<{ [key: string]: NeedConfig }>;
  private _secondLevelNeedIdMap: Observable<{ [key: string]: SecondLevelNeed }>;

  constructor(
    private http: HttpClient,
    private typesCacheService: TypesCacheService,
    private readonly configurationService: ConfigurationService,
    @Inject(WINDOW) private window: Window,
    private logger: NGXLogger
  ) { }

  /**
   * Initilaizes the needs cache or replays the response
   */
  public getNeeds(): Observable<NeedConfig[]> {
    /**
     * needs.json can be supplied using 2 methods:
     * - cached HTTP response (default)
     * - inlined javascript object (used for external integration)
     */
    let needsSrc: Observable<NeedConfig[]> = null;
    if (this.window['__SWISSPOST_TOPOS_NEEDS__'] !== undefined) {
      this.logger.debug(`needs-cache.service: loading needs from __SWISSPOST_TOPOS_NEEDS__.`);
      needsSrc = of(this.window['__SWISSPOST_TOPOS_NEEDS__']);
    } else {
      this.logger.debug(`needs-cache.service: loading needs from server.`);
      needsSrc = this.requestNeeds();
    }

    return needsSrc.pipe(
      map((needsConfig) => this.cloneNeedsConfig(needsConfig)),
      switchMap((needsConfig: NeedConfig[]) => {
        const sortedNeeds: NeedConfig[] = this.sortNeeds(needsConfig);
        const observables: Observable<NeedConfig>[] = sortedNeeds.map((x) => this.replaceIdsWithTagsInNeedConfig(x));
        return combineLatest(observables);
      }),
      shareReplay()
    );
  }

  /**
   * Get a need based on its Post id
   *
   * @param id Post id
   */
  public getNeedById(id: number): Observable<NeedConfig> {
    if (!this._needIdMap) {
      this._needIdMap = this.getNeeds().pipe(
        map((needs) => this.mapNeedsById(needs)),
        shareReplay(1)
      );
    }
    return this._needIdMap.pipe(map((needs) => needs[id]));
  }

  /**
   * Get second level need object
   *
   * @param id
   */
  public getSecondLevelNeedById(id: string): Observable<SecondLevelNeed> {
    if (!this._secondLevelNeedIdMap) {
      this._secondLevelNeedIdMap = this.getNeeds().pipe(
        map((needs) => this.mapSecondLevelNeedById(needs)),
        shareReplay(1)
      );
    }
    return this._secondLevelNeedIdMap.pipe(map((needs) => needs[id]));
  }

  /**
   * Gets the first need id of the needconfiguration
   */
  public getDefaultNeedId(): Observable<number> {
    return this.getNeeds().pipe(
      map((needs: NeedConfig[]) => {
        if (needs.length > 0) {
          return needs[0].id;
        }
        return null;
      })
    );
  }

  /**
   * Gets the first second level need id of the needconfiguration
   */
  public getDefaultSecondLevelNeedId(): Observable<string> {
    return this.getNeeds().pipe(
      map((needs: NeedConfig[]) => {
        if (needs.length > 0) {
          if (needs[0].secondlevelneeds.length > 0) {
            return needs[0].secondlevelneeds[0].id;
          }
        }
        return null;
      })
    );
  }

  /**
   * Parses query and replaces ids (ASDP IDs) with tags (for StaoCache).
   * Seperator is , or .
   *
   * @param query Query for Stao-Cache service, ids instead of tags
   */
  public replaceIdsWithTags(query: string): Observable<string> {
    return this.typesCacheService.getTypes().pipe(
      map((serviceTypesCache: ServiceTypesCache) =>
        query
          .split(',')
          .reduce((orAcc: string[], orCombinator: string) => {
            const orString = orCombinator
              .split('.')
              .reduce((andAcc: string[], andCombinator: string) => {
                const translation = serviceTypesCache.typesById[andCombinator.trim()];
                // Skip translation if there is an invalid id in needs.json
                if (translation) {
                  andAcc.push(translation.tag);
                } else if (serviceTypesCache.distributionTypes?.find(d => d === andCombinator)) {
                  andAcc.push(`DT:${andCombinator}`);
                } else {
                  this.logger.warn(`needs.json is misconfigured, skipped id "${andCombinator}".`);
                }
                return andAcc;
              }, [])
              .join('.');

            // Skip string if empty
            if (orString) {
              orAcc.push(orString);
            }
            return orAcc;
          }, [])
          .join(',')
      )
    );
  }

  /**
   * Clone the object because SSR HTTP-transfer-state will
   * not create clones by itself.
   * Better solution would be: Use TransferState and transfer the object
   * that has the id's replaced with tags explicitly. Not big deal, though.
   */
  private cloneNeedsConfig(needconfig: NeedConfig[]): NeedConfig[] {
    return JSON.parse(JSON.stringify(needconfig));
  }

  /**
   * Sorts all needs and secondlevelneeds by sortId
   *
   * @param needsConfig
   * @returns sorted needConfig
   */
  private sortNeeds(needsConfig: NeedConfig[]): NeedConfig[] {
    needsConfig.sort((a, b) => a.sortId - b.sortId);

    for (const need of needsConfig) {
      need.secondlevelneeds.sort((a, b) => a.sortId - b.sortId);
    }

    return needsConfig;
  }

  /**
   * Transform query ids to esri tags
   *
   * @param needConfig needs.json
   */
  private replaceIdsWithTagsInNeedConfig(needConfig: NeedConfig): Observable<NeedConfig> {
    return zip(
      this.replaceIdsWithTags(needConfig.query),
      this.translatePois(needConfig.pois),
      this.translateSecondLevelNeeds(needConfig.secondlevelneeds)
    ).pipe(
      map(([query, pois, secondlevelneeds]) => {
        needConfig.query = query;
        needConfig.pois = pois;
        needConfig.secondlevelneeds = secondlevelneeds;
        return needConfig;
      })
    );
  }

  /**
   * Transform poi ids to esri tags
   *
   * @param pois Pois from needs.json
   */
  private translatePois(pois: string[]): Observable<string[]> {
    return this.typesCacheService.getTypes().pipe(map((types) => this.translateArrayWithIdsToTags(pois, types)));
  }

  private translateSecondLevelNeeds(secondLevelNeeds: SecondLevelNeed[]): Observable<SecondLevelNeed[]> {
    return this.typesCacheService.getTypes().pipe(
      map((types) => {
        for (const entry of secondLevelNeeds) {
          entry.productTypeDeadlines = this.translateArrayWithIdsToTags(entry.productTypeDeadlines, types);
          entry.counterTypesOpenAt = this.translateArrayWithIdsToTags(entry.counterTypesOpenAt, types);
        }
        return secondLevelNeeds;
      })
    );
  }

  /**
   * Replaces each id with its corresponding tag.
   *
   * @param pois
   * @param types
   */
  private translateArrayWithIdsToTags(pois: string[], types: ServiceTypesCache) {
    return pois.map((id) => {
      const type = types.typesById[id];
      if (!type || !type.tag) {
        this.logger.warn(`needs.json is misconfigured, skipped id "${id}".`);
        return id;
      }
      return type.tag;
    });
  }

  /**
   * Load needs.json from the server
   */
  private requestNeeds(): Observable<NeedConfig[]> {
    const webBaseUrl = this.configurationService.getConfiguration().webBaseUrl;
    const url = `${webBaseUrl}/assets/config/needs.json`;
    return this.http.get<NeedConfig[]>(url);
  }

  /**
   * Create indices for fast reference by needid.
   *
   * @param needs Array of returned need configs
   * @returns Need config index
   */
  private mapNeedsById(needs: NeedConfig[]): { [key: string]: NeedConfig } {
    return needs.reduce((needsMap: { [key: string]: NeedConfig }, need: NeedConfig) => {
      needsMap[need.id] = need;
      return needsMap;
    }, {});
  }

  /**
   * Creates indices for fast refernce by second level need id.
   *
   * @param needs
   */
  private mapSecondLevelNeedById(needs: NeedConfig[]): { [key: string]: SecondLevelNeed } {
    return needs.reduce((secondLevelNeedMap: { [key: string]: SecondLevelNeed }, need: NeedConfig) =>
      need.secondlevelneeds.reduce((innerSecondLevelNeedMap: { [key: string]: SecondLevelNeed }, secondLevelNeed: SecondLevelNeed) => {
        innerSecondLevelNeedMap[secondLevelNeed.id] = secondLevelNeed;
        return innerSecondLevelNeedMap;
      }, secondLevelNeedMap)
      , {});
  }
}
