<post-tabs>
  <post-tab-header slot="tabs" panel="mp24-boxes" *ngIf="poi.hasMp24Boxes">{{getTabTranslationByKey('mp24-boxes')
    | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="addresses" *ngIf="poi.hasAddresses">{{getTabTranslationByKey('addresses') |
    translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="opening-hours"
    *ngIf="poi.hasOpeningHours">{{getTabTranslationByKey('opening-hours') | translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="deadlines" *ngIf="poi.hasDeadlines">{{getTabTranslationByKey('deadlines') |
    translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="services" *ngIf="poi.hasServices">{{getTabTranslationByKey('services') |
    translate}}</post-tab-header>
  <post-tab-header slot="tabs" panel="partners" *ngIf="poi.hasPartners">{{getTabTranslationByKey('partners') |
    translate}}</post-tab-header>

  <!-- MP24-Fächer -->
  <post-tab-panel name="mp24-boxes" [hidden]="!poi.hasMp24Boxes">
    <app-mp24boxes [poi]="poi"></app-mp24boxes>
  </post-tab-panel>

  <!-- Adressierung PickPost & Postlagernd -->
  <post-tab-panel name="addresses" [hidden]="!poi.hasAddresses">
    <app-addressing [poi]="poi"></app-addressing>
  </post-tab-panel>

  <!-- Öffungszeiten -->
  <post-tab-panel name="opening-hours" [hidden]="!poi.hasOpeningHours">
    <app-openinghours [poi]="poi"></app-openinghours>
  </post-tab-panel>

  <!-- Annahmeschluss -->
  <post-tab-panel name="deadlines" [hidden]="!poi.hasDeadlines">
    <app-deadlines [poi]="poi"></app-deadlines>
  </post-tab-panel>

  <!-- Services -->
  <post-tab-panel name="services" [hidden]="!poi.hasServices">
    <app-services [poi]="poi"></app-services>
  </post-tab-panel>

  <!-- Partnerangebote -->
  <post-tab-panel name="partners" [hidden]="!poi.hasPartners">
    <app-partners [poi]="poi"></app-partners>
  </post-tab-panel>
</post-tabs>