<p *ngIf="isOpen !== undefined" class="result-list-deadline font-size-15">
  <span *ngIf="isOpen === true">
    {{'common.open' | translate}}:&nbsp;<span
      [ngClass]="'fw-bold deadline-' + textColorClassOpeningHours">{{nextOpenClosedEventText}}</span>
  </span>

  <span *ngIf="isOpen === false">
    {{'common.closed' | translate}}:&nbsp;<span
      [ngClass]="'fw-bold deadline-' + textColorClassOpeningHours">{{nextOpenClosedEventText}}</span>
  </span>
</p>

<p *ngIf="deadLineText" class="result-list-deadline font-size-15">
  <span>{{deadLineText}}&nbsp;</span>
  <span class="fw-bold" [class.deadline-danger]="deadlineStillOk === false"
    [class.deadline-success]="deadlineStillOk === true">{{deadLineTime}}</span>
  <button class="info-button ms-1" [ngbPopover]="'common.deadlineinfo' | translate" (click)="onInfoClick($event)">
    <post-icon name="2106"></post-icon>
  </button>
</p>