<div class="search-info" [ngClass]="{'hidden': !showSearchInfo}">
  <div class="search-info-text me-3">
    <p class="font-size-15 fw-normal">{{ need | async }}{{ ' · ' + (time | async | translate) }}</p>
    <p class="mb-0 font-size-15 fw-normal" *ngIf="useCurrentLocation | async" translate>
      search.currentlocation
    </p>
    <p class="mb-0 font-size-15 fw-normal" *ngIf="(useCurrentLocation | async) === false">
      <span class="visually-hidden">{{ 'search.location' | translate}}: </span>
      <span>{{ query | async | translate }}</span>
    </p>
  </div>
  <button class="search-info-button btn btn-primary btn-icon" (click)="scrollToSearch()">
    <post-icon name="2069"></post-icon>
    <span class="visually-hidden" translate>map.backtosearch</span>
  </button>
</div>