import { Component, Input } from '@angular/core';
import { ToposConfiguration } from '@app/app/common/model/toposconfiguration';
import { TagManagerService } from '@app/app/common/tag.service';
import { ConfigurationService } from '@app/app/config/configuration.service';
import { CompartmentDimension } from '@app/app/gis/model/mp24/compartment-dimension';
import { Terminal } from '@app/app/gis/model/mp24/terminal';
import { Mp24Service } from '@app/app/gis/services/mp24.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';

@Component({
  selector: 'app-mp24boxes',
  templateUrl: './mp24boxes.component.html',
  styleUrls: ['./mp24boxes.component.scss'],
})
export class Mp24BoxesComponent {
  terminal: Terminal;
  sizes: string[] = ['XS', 'S', 'M', 'L', 'XL'];
  selectedSize = 'XS';
  dimensions: { size: string; width: number; height: number; depth: number }[] = [
    {
      size: 'XS',
      width: 397,
      height: 85,
      depth: 550,
    },
    {
      size: 'S',
      width: 397,
      height: 85,
      depth: 550,
    },
    {
      size: 'M',
      width: 397,
      height: 190,
      depth: 550,
    },
    {
      size: 'L',
      width: 397,
      height: 400,
      depth: 550,
    },
    {
      size: 'XL',
      width: 397,
      height: 820,
      depth: 550,
    },
  ];

  private _poi: PoiDetail;
  private config: ToposConfiguration;

  constructor(
    private readonly tagManagerService: TagManagerService,
    private mp24Service: Mp24Service,
    private toastr: ToastrService,
    private translate: TranslateService,
    private configurationService: ConfigurationService
  ) {
    this.config = configurationService.getConfiguration();
  }

  public get poi() {
    return this._poi;
  }

  get selectedCompartmentDimension(): CompartmentDimension {
    // const dimension = this.terminal.compartments.filter((c) => c.size === this.selectedSize)[0].dimension;
    const dimension = this.dimensions.filter((c) => c.size === this.selectedSize)[0];

    // divide by 10 to get cm's
    return {
      depth: dimension.depth / 10,
      height: dimension.height / 10,
      width: dimension.width / 10,
    };
  }

  get orderedCompartment(): { size: string; free: number }[] {
    return this.sizes ? this.sizes.map((s) => ({ size: s, free: this.amountOfFreeCompartmentsWithSize(s) })) : [];
  }

  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
    this.initTerminal();
  }

  amountOfFreeCompartmentsWithSize(size: string): number {
    return this.terminal.compartments.filter((c) => c.size === size && c.empty).length;
  }

  initTerminal() {
    // prevent loading terminals without a mp24
    if (!this.poi.hasMp24Boxes) {
      return;
    }

    const technicalZip6: number = this.config.env === 'local' || this.config.env === 'int' ? 301800 : Number(this.poi.technicalZip6);

    this.mp24Service.getTerminalsByTechnicalZip(technicalZip6).subscribe({
      next: (t) => {
        this.terminal = t;
        this.sizes = this.sizes.filter((size) => this.terminal.compartments.some((compartment) => compartment.size === size));
        this.selectedSize = this.sizes[0];
      },
      error: (e) => {
        // should not be happen
        console.error(e);
        this.toastr.error(this.translate.instant('detail.mp24.terminal.notfound'));
      },
    });
  }

  onSizeClick(size: string) {
    this.selectedSize = size;

    // todo: add click
    // this.tagManagerService.click({
    //   event: eventName,
    //   type: 'accordion',
    //   label: panelTitleGer.toLowerCase().replace(/\s/g, '-'),
    //   text: panelTitle.toLowerCase(),
    //   additional_info: tab.toLowerCase(),
    // });
  }
}
