import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

/**
 * Handles unhandled errors globally.
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private logger: NGXLogger,
    @Inject(PLATFORM_ID) private readonly platformId: any) {}

  handleError(error: any): void {
    const translateService = this.injector.get(TranslateService);
    const toastr = this.injector.get(ToastrService);

    this.logger.error('An error occurred:', error.message);
    this.logger.debug(error);

    if (toastr && isPlatformBrowser(this.platformId)) {
      translateService.get('common.error.generic').subscribe((errorMessage) => {
        toastr.error(errorMessage);
      });
    }
  }
}
