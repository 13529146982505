import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundComponent } from './error/not-found/not-found.component';
import { configuraton } from './config/configuration';
import { HomeComponent } from './home/home.component';
import { DetailPageComponent } from './detail/components/detail-page/detail-page.component';
import { PoiDetailResolver } from './detail/components/detail/poi-detail.resolver';

const { homeRoutes } = configuraton;

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'ui', redirectTo: '/' }, // For compatibility with old routing at /ui/home
  {
    path: 'de/:id',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'de/:id/:title',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'it/:id',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'it/:id/:title',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'fr/:id',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'fr/:id/:title',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'en/:id',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  {
    path: 'en/:id/:title',
    component: DetailPageComponent,
    resolve: { poi: PoiDetailResolver },
  },
  { path: homeRoutes.de, component: HomeComponent },
  { path: homeRoutes.fr, component: HomeComponent },
  { path: homeRoutes.it, component: HomeComponent },
  { path: homeRoutes.en, component: HomeComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
