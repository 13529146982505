import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { NeedConfig } from '../model/needconfig';
import { SecondLevelNeed } from '../model/secondlevelneed';
import { SearchParameters } from '../model/searchparameters';
import { TypesCacheService } from './types-cache.service';
import { fromDateToXmlDateString, fromXmlTimeStringToToposTime, toDisplayTimeString } from '../util/dateutil';
import { NGXLogger } from 'ngx-logger';
import { isTechPlz } from '../util/isTechPlz';

@Injectable({
  providedIn: 'root',
})
export class QueryTransformerService {
  constructor(private configurationService: ConfigurationService, private typesCache: TypesCacheService, private logger: NGXLogger) {}

  public addAdditionalInfoToQuery(needConfig: NeedConfig, searchParameters: SearchParameters): Observable<string> {
    const secondlevelneed = needConfig.secondlevelneeds.find((s) => s.id === searchParameters.secondLevelNeedId);
    if (!needConfig.query && !secondlevelneed) {
      return of('');
    }

    let baseQuery = of(needConfig.query);

    // TechPlz filter
    if (isTechPlz(searchParameters.query)) {
      return of(`Z6:${searchParameters.query}`);
    }

    // secondlevelneed filter
    if (secondlevelneed && secondlevelneed.queryFilter && secondlevelneed.queryFilter.length > 0) {
      baseQuery = baseQuery.pipe(switchMap((queryToEdit: string) => this.multiplyOut(queryToEdit, secondlevelneed.queryFilter)));
    }

    // accessible by wheelchair
    if (searchParameters.accessibleByWheelChair) {
      const idsToTransformWheelchair = this.configurationService.getConfiguration().accessibleByWheelchairServiceIds;
      baseQuery = baseQuery.pipe(switchMap((queryToEdit: string) => this.multiplyOut(queryToEdit, idsToTransformWheelchair)));
    }

    // Poi type filter
    if (searchParameters.serviceTypeFilter) {
      const idsToTransformPoiTypeFilter = searchParameters.serviceTypeFilter;
      baseQuery = baseQuery.pipe(switchMap((queryToEdit: string) => this.multiplyOut(queryToEdit, idsToTransformPoiTypeFilter)));
    }

    return baseQuery;
  }

  /**
   * Creates the value for url parameter 'openat' for StaoCache backend.
   *
   * @param secondLevelNeeds
   * @param searchParameters
   */
  public openAtString(secondLevelNeeds: SecondLevelNeed[], searchParameters: SearchParameters): Observable<string> {
    let openAt;
    const secondLevelNeed = secondLevelNeeds.find((entry) => entry.id === searchParameters.secondLevelNeedId);

    if (!searchParameters.openNow || secondLevelNeed.counterTypesOpenAt.length === 0) {
      return of('');
    } else {
      const toposTime = fromXmlTimeStringToToposTime(searchParameters.time);
      openAt = new Date(
        searchParameters.date.year,
        searchParameters.date.month - 1,
        searchParameters.date.day,
        toposTime.hour,
        toposTime.minute
      );
    }

    const dateString = fromDateToXmlDateString(openAt);
    const timeString = toDisplayTimeString(openAt.getHours(), openAt.getMinutes(), ':');
    const result = `${secondLevelNeed.counterTypesOpenAt},${dateString}T${timeString}`;
    return of(result);
  }

  /**
   * Creates the value for url parameter 'timely' for StaoCache backend.
   *
   * @param secondLevelNeeds
   * @param searchParameters
   */
  public timelyString(secondLevelNeeds: SecondLevelNeed[], searchParameters: SearchParameters): Observable<string> {
    let timely;
    const secondLevelNeed = secondLevelNeeds.find((entry) => entry.id === searchParameters.secondLevelNeedId);

    if (!searchParameters.openNow || secondLevelNeed.productTypeDeadlines.length === 0) {
      return of('');
    } else {
      const toposTime = fromXmlTimeStringToToposTime(searchParameters.time);
      timely = new Date(
        searchParameters.date.year,
        searchParameters.date.month - 1,
        searchParameters.date.day,
        toposTime.hour,
        toposTime.minute
      );
    }

    const timeString = toDisplayTimeString(timely.getHours(), timely.getMinutes(), ':');
    const result = `${secondLevelNeed.productTypeDeadlines},${timely.toLocaleString('en-GB', { weekday: 'short' })}:${timeString}`;
    return of(result);
  }

  /**
   * Multiply out the two inputs.
   * Example: P1,P2,P3 as query and ['IDS1','IDS2'] as orIds.
   * This will result in P1.S1,P2.S1,P3.S1,P1.S2,P2.S2,P3.S12
   *
   * @param query: The query, for example P1,P2
   * @param orIds: For example ['001134109']
   */
  public multiplyOut(query: string, orIds: string[]): Observable<string> {
    // nothing to add
    if (!orIds || orIds.length === 0) {
      return of(query);
    }

    return this.typesCache.getTypes().pipe(
      map((types) => {
        const orParts: string[] = [];
        const queryWithAndTags = query.split(',');

        // do cartesian product.
        orIds.forEach((x) => {
          if (types.typesById[x]) {
            const tag = types.typesById[x].tag;
            queryWithAndTags.forEach((y) => {
              const orPart = `${tag}.${y}`;
              orParts.push(orPart);
            });
          } else {
            this.logger.warn(`Type "${x}" not present in configuration.`);
          }
        });

        return orParts.join(',');
      })
    );
  }
}
