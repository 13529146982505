import { AbstractControl } from '@angular/forms';

export const validateRequiredIf = (doValidateFn: () => any) => (control: AbstractControl) => {
    if (doValidateFn) {
        if (!doValidateFn()) {
            return null;
        }
    }

    // for datepickers
    const value = control.value;
    if(value == null) {
        return { required: true };
    }

    const stringVal = '' + value;
    if (stringVal === '') {
        return { required: true };
    }
    return null;
};
