import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PoiDetailService } from 'src/app/gis/services/poidetail.service';

@Injectable()
export class PoiDetailResolver {
  constructor(private poiDetailService: PoiDetailService, private router: Router, private location: Location) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.poiDetailService.getPoiDetail(route.paramMap.get('id')).pipe(
      catchError(_err => {
        this.router.navigate(['404']).then(() => {
          this.location.replaceState(state.url);
        });
        return EMPTY;
      })
    );
  }
}
