import { ServiceType } from './servicetype';
import { SimpleCoordinates } from './simplecoordinates';
import { ToposTime } from './topostime';

class PoiBase {
  id: string;
  name: string;
  coordinates: SimpleCoordinates;
  type: PoiListItemType;
}

export class PoiClusterListItem {
  coordinates: SimpleCoordinates;
  pois: PoiListItem[];
}

export class PoiListItem extends PoiBase {
  serviceType: ServiceType;
  typeAndAddition: string;
  street: string;
  zip: string;
  city: string;
  distance: number | null;
  accessibleByWheelchair: boolean;
  deadlinesProduct: { [key: string]: { time: ToposTime; title: string } };
  additionalDescription: string;

  hasHints: boolean;
  coincident: boolean;

  openUntil: Date;
  openAgain: Date;
}

export type PoiOrAggregate = PoiListItem | PoiListAggregate;

export class PoiListItemCounter {
  tag: string;
  openUntil: null | string;
  openAgain: null | string;
}

export class PoiListItemProduct {
  tag: string;
  deadline: null | string;
}

export class PoiListAggregate extends PoiBase {
  count: number;
}

export enum PoiListItemType {
  unknown = 0,
  poi = 1,
  cluster = 2,
  aggregate = 3,
}
