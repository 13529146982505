import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription, zip } from 'rxjs';

import { PoiListItem } from '@app/app/gis/model/poibase';
import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { ControllerService } from 'src/app/controller.service';
import { SearchParameters } from 'src/app/gis/model/searchparameters';
import { NeedsCacheService } from 'src/app/gis/services/needs-cache.service';
import { OpeningEventService } from 'src/app/gis/services/opening-event.service';
import { fromNgbDateStructToDate, getToposTimeAsDate } from 'src/app/gis/util/dateutil';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseResultListDeadlineComponent implements OnInit, OnDestroy {
  public searchParmeters: SearchParameters;
  public showOpeningHours = true;
  public isOpen: boolean = undefined;
  public deadlineStillOk: boolean = undefined;
  public nextOpenClosedEventText: string;
  public deadlineALetter: string;
  public deadLineText: string;
  public deadLineTime: string;
  public textColorClassOpeningHours: string;
  public textColorClassDeadlines: string;

  private intervalSubscripton: Subscription;
  private _poi: PoiListItem;

  constructor(
    protected openingEventService: OpeningEventService,
    protected currentDateTimeService: CurrentDateTimeService,
    protected controllerService: ControllerService,
    protected needsCacheService: NeedsCacheService,
    protected translateService: TranslateService
  ) { }

  get poi(): PoiListItem {
    return this._poi;
  }
  @Input()
  set poi(poilistItem: PoiListItem) {
    this._poi = poilistItem;

    // Hide Opening Hours if the user searches for a specific date and time.
    this.searchParmeters = this.controllerService.getSearchParameters().value;
    this.showOpeningHours = this.searchParmeters && this.searchParmeters.date == null ? true : false;
    this.updateFields();
  }

  ngOnDestroy(): void {
    if (this.intervalSubscripton) {
      this.intervalSubscripton.unsubscribe();
    }
  }

  ngOnInit() {
    this.intervalSubscripton = this.controllerService.getUpdateDataInterval().subscribe(() => this.updateFields());
  }

  public onInfoClick(event: MouseEvent) {
    event.stopPropagation();
  }

  public updateFields() {
    this.isOpen = undefined;

    if (!this.poi) {
      return;
    }

    if (this.searchParmeters && this.searchParmeters.date && this.searchParmeters.time) {
      // if we search by time, all opening hours and deadlines
      // are calculated based on the chosen reference datetime
      const referenceDate = fromNgbDateStructToDate(this.searchParmeters.date);

      // openUntil is not delivered by StaoCache with a date
      // and has to be added manually
      if (this.poi.openUntil) {
        this.poi.openUntil.setFullYear(referenceDate.getFullYear());
        this.poi.openUntil.setMonth(referenceDate.getMonth());
        this.poi.openUntil.setDate(referenceDate.getDate());
      }

      const hours = this.searchParmeters.time.substring(0, 2);
      const minutes = this.searchParmeters.time.substring(3, 5);
      referenceDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));

      this.updateOpeningHoursFields(referenceDate, true);
      this.productTypeDeadlines().subscribe(ptd => {
        this.setDeadlines(ptd, referenceDate, true);
      });
    } else {
      const now = this.currentDateTimeService.getCurrentDateTime();
      this.updateOpeningHoursFields(now, false);
      this.productTypeDeadlines().subscribe(ptd => {
        this.setDeadlines(ptd, now, false);
      });
    }
  }

  /**
   * Update info about openinghours
   *
   * @param now Current time
   */
  private updateOpeningHoursFields(referenceDate: Date, isFutureDate: boolean) {
    // do nothing is this value is missing
    if (!this.poi.openAgain) {
      return;
    }

    if (this.poi.openUntil && referenceDate <= this.poi.openUntil) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }

    // get text for open closed events
    this.openingEventService
      .getOpeningEvent(this.isOpen, referenceDate, this.poi.openUntil, this.poi.openAgain, isFutureDate)
      .subscribe((translation) => {
        this.nextOpenClosedEventText = translation;
      });

    // set text color class
    this.textColorClassOpeningHours = this.getTextColorClassOpeningHours();
  }

  private setDeadlines(productTypeDeadlines: string[], referenceDate: Date, isFutureDate: boolean) {
    for (const productDeadline of productTypeDeadlines) {
      const deadline = this.poi.deadlinesProduct[productDeadline];
      if (deadline) {
        const dateDeadline = getToposTimeAsDate(referenceDate, deadline.time);
        this.deadlineStillOk = dateDeadline >= referenceDate;

        const deadlineEventTextObservable = this.openingEventService.getDeadlineEvent(referenceDate, dateDeadline, true, isFutureDate);
        const deadlineTextObservable = this.translateService.get('detail.hours.deadline');

        zip(deadlineEventTextObservable, deadlineTextObservable).subscribe(([deadlineText, wordDeadline]) => {
          const showWordDeadline = productDeadline !== '003BE_LZ';

          if (showWordDeadline) {
            this.deadLineTime = deadlineText;
            this.deadLineText = `${wordDeadline} ${deadline.title}:`;
          } else {
            this.deadLineTime = deadlineText;
            this.deadLineText = `${deadline.title}:`;
          }
        });

        this.textColorClassDeadlines = this.deadlineStillOk ? 'success' : 'danger';
        break;
      }
    }
  }

  private getTextColorClassOpeningHours(): string {
    const params = this.controllerService.getSearchParameters().value;
    let color = 'neutral';
    if (this.isOpen) {
      color = 'success';
    } else {
      color = 'danger';
    }
    return color;
  }

  protected abstract productTypeDeadlines(): Observable<string[]>;
}
