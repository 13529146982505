import { Component, Input } from '@angular/core';
import { PoiListItem } from '@app/app/gis/model/poibase';

@Component({
  selector: 'app-poi-info-window',
  templateUrl: './poi-info-window.component.html',
  styleUrls: ['./poi-info-window.component.scss'],
})
export class PoiInfoWindowComponent {
  @Input() poi: PoiListItem = null;
}
