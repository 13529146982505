import { DayOpeningHour } from '../model/poidetail/dayopeninghour';

export const mergeOpeningDays = (input: DayOpeningHour[]): DayOpeningHour[] => {
  const result = [];

  // no input
  if (input.length === 0) {
    return result;
  }

  const iteratorState = {
    currentTimeString: null,
    currentTimeStrings: null,
    dayFrom: null,
    dayTo: null,
    sort: null,
  };

  input.forEach((currentElem: DayOpeningHour, index: number, arr: DayOpeningHour[]) => {
    if (index === 0) {
      iteratorState.currentTimeString = currentElem.timeString;
      iteratorState.currentTimeStrings = currentElem.timeStrings;
      iteratorState.dayFrom = currentElem.daysText;
      iteratorState.dayTo = null;
      iteratorState.sort = currentElem.sort;
    } else {
      iteratorState.sort += 1;
    }

    if (iteratorState.currentTimeString === currentElem.timeString && iteratorState.sort === currentElem.sort) {
      iteratorState.dayTo = currentElem.daysText;
    } else {
      result.push(createElem(iteratorState));

      iteratorState.currentTimeString = currentElem.timeString;
      iteratorState.currentTimeStrings = currentElem.timeStrings;
      iteratorState.dayFrom = currentElem.daysText;
      iteratorState.dayTo = null;
      iteratorState.sort = currentElem.sort;
    }

    if (index === arr.length - 1) {
      iteratorState.dayTo = currentElem.daysText;
      result.push(createElem(iteratorState));
    }
  });

  return result;
};

const createElem = (obj: { currentTimeString: string; currentTimeStrings: string[]; dayFrom: string; dayTo: string }): DayOpeningHour => {
  const mergedElem: DayOpeningHour = new DayOpeningHour();
  mergedElem.timeString = obj.currentTimeString;
  mergedElem.timeStrings = obj.currentTimeStrings;
  if (obj.dayTo != null && obj.dayTo !== obj.dayFrom) {
    mergedElem.daysText = `${obj.dayFrom} - ${obj.dayTo}`;
  } else {
    mergedElem.daysText = `${obj.dayFrom}`;
  }
  return mergedElem;
};
