import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NeedConfig } from '../gis/model/needconfig';
import { SecondLevelNeed } from '../gis/model/secondlevelneed';

@Injectable()
export class TranslateExtendedService extends TranslateService {
  public getGermanTranslation(key: string): Observable<string> {
    return this.getTranslation('de').pipe(
      map(translations => translations[key])
    );
  }

  public getTranslationForKey(key: string, lang: string): Observable<any> {
    return this.getTranslation(lang).pipe(
      map(translations => translations[key])
    );
  }

  public getTranslatedNeedText(needconfig: NeedConfig, language: string): string {
    const propertyName = this.getNeedTranslationPropertyName(language);
    return needconfig[propertyName];
  }

  public getTranslatedSecondlevelneedText(secondLevelNeed: SecondLevelNeed, language: string) {
    const propertyName = this.getNeedTranslationPropertyName(language);
    return secondLevelNeed[propertyName];
  }

  private getNeedTranslationPropertyName(language: string): string {
    switch (language) {
      case 'fr':
        return 'nameFr';
      case 'it':
        return 'nameIt';
      case 'en':
        return 'nameEn';
      default:
        return 'nameDe';
    }
  }
}
