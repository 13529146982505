<header class="result-filter-header container">
  <h2 class="font-curve-bigger-regular fw-bold" translate>resultfilter.title</h2>
  <hr>
</header>

<div class="result-filter container">
  <div class="result-filter-item result-filter-info">
    <p class="result-filter__poi-count text-gray-600 fw-normal" *ngIf="hasResults">{{ 'resultfilter.poicount' |
      translate : { num: resultCount } }}</p>
  </div>

  <!-- Only for screenreaders -->
  <span id="sr-only-text" class="visually-hidden" aria-live="polite">{{ srText }}</span>

  <div class="result-poitype-filter result-filter-item">
    <label for="result-filter-facility-type" class="form-label visually-hidden" translate>resultfilter.poitype</label>
    <select id="result-filter-facility-type" class="form-select form-select-lg" (change)="filterChanged($event)">
      <option value translate>
        resultfilter.all
      </option>
      <option *ngFor="let type of poiTypes" [value]="type.id">
        {{type.desc}}
      </option>
    </select>
  </div>
</div>