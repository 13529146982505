import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }
  /**
   * Set the language specific alternate links for the SwissPost Internet Header
   *
   * @param languageUrls [{ lang: 'de', url: 'https://places.post.ch/standortsuche' }, { lang: 'fr', url: 'https...
   */
  public writeHeaderConfiguration(languageUrls) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const header: HTMLSwisspostInternetHeaderElement = document.querySelector('swisspost-internet-header');
    if (header) {
      header.languageSwitchOverrides = languageUrls;
    }
  }
}
