import { Injectable } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { GeocodedArea } from '../model/geocodedArea';
import { GeoCodedAreaType } from '../model/geocodedAreaType';
import { PoiClusterListItem, PoiListAggregate, PoiListItem, PoiListItemType } from '../model/poibase';
import { PoiList } from '../model/poilist';
import { FindResponse, FindResponseAggregate, FindResponseCounter, FindResponsePoi } from '../model/stao-cache/find.response.type';
import { GeocodeResponse } from '../model/stao-cache/geocode.response.type';
import { ServiceTypesCache } from '../model/types-cache.type';
import { fromXmlDateStringToDate, fromXmlTimeStringToToposTime, getToposTimeAsDate } from '../util/dateutil';
import { ToposTime } from '../model/topostime';
import { TypesCacheService } from './types-cache.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class PoiConverterService {
  constructor(
    private typeService: TypesCacheService,
    private configurationService: ConfigurationService,
    private currentDateTimeService: CurrentDateTimeService,
    private logger: NGXLogger
  ) { }

  /**
   * Transforms geocode response to usable location objects
   *
   * @param res ESRI Api Geocode response object
   */
  public convertToGeocodedArea(res: GeocodeResponse): GeocodedArea[] {
    if (!res.ok || res.locations === null || res.locations.length === 0) {
      return [];
    }

    const locations = res.locations.filter(
      (x) => x.type != null && x.pt !== null && x.pt.length === 2
    );

    return locations.map((x) => {
      let geocodedAreaType: any = GeoCodedAreaType[x.type];
      if (geocodedAreaType === undefined) {
        geocodedAreaType = GeoCodedAreaType.unknown;
      }
      let longSouthWest = 0;
      let latSouthWest = 0;
      let longNorthEast = 0;
      let latNorthEast = 0;

      if (x.bbox?.length === 4) {
        longSouthWest = x.bbox[0];
        latSouthWest = x.bbox[1];
        longNorthEast = x.bbox[2];
        latNorthEast = x.bbox[3];
      } else {
        const BBOX_OFFSET = 0.0005; //50m, roughly a square of 100 by 100 meters
        longSouthWest = x.pt[0] - BBOX_OFFSET;
        latSouthWest = x.pt[1] - BBOX_OFFSET;
        longNorthEast = x.pt[0] + BBOX_OFFSET;
        latNorthEast = x.pt[1] + BBOX_OFFSET;
      }
      const longCenter: number = x.pt[0];
      const latCenter: number = x.pt[1];
      const geocodedArea: GeocodedArea = {
        boundingBox: {
          northEast: { latitude: parseFloat(latNorthEast.toFixed(5)), longitude: parseFloat(longNorthEast.toFixed(5)) },
          southWest: { latitude: parseFloat(latSouthWest.toFixed(5)), longitude: parseFloat(longSouthWest.toFixed(5)) },
        },
        name: x.name,
        geocodedAreaType,
        center: { latitude: latCenter, longitude: longCenter },
      };

      if (x.id) {
        geocodedArea.id = x.id;
      }
      if (x.subtype) {
        geocodedArea.tag = x.subtype;
      }

      return geocodedArea;
    });
  }

  /**
   * Transform and enrich the find response with custom properties
   *
   * @param res A find response object from ESRI API
   * @returns An observable emitting the transformed result list
   */
  public convertToResultList(res: FindResponse): Observable<PoiList> {
    const list = new PoiList();
    list.count = res.count;
    list.extent = {
      southWest: {
        latitude: res.extent[1],
        longitude: res.extent[0],
      },
      northEast: {
        latitude: res.extent[3],
        longitude: res.extent[2],
      },
    };
    list.aggregates = [];
    list.pois = [];

    if (res.ok === false) {
      this.logger.warn(res.info);
      return of(list);
    }

    if (res.aggregates) {
      list.aggregates = res.aggregates.map((agg) => this.convertToPoiListAggregate(agg));
    }

    if (res.pois) {
      const pois = res.pois;

      // Convert FindResponsePoi(s) to PoiListItem(s) list items
      return zip(this.typeService.getTypes(), this.getServiceTypeIdsThatAreHints()).pipe(
        map(([typesCache, serviceTypesHintsTags]) => {
          list.pois = pois.map((poi) => {
            if ('pois' in poi) {
              // This is a cluster
              const cluster = new PoiClusterListItem();
              cluster.pois = poi.pois.map((clusteredPoi) => this.convertToPoiListItem(clusteredPoi, typesCache, serviceTypesHintsTags));
              cluster.coordinates = { latitude: poi.y, longitude: poi.x };
              return cluster;
            } else {
              // This is a poi, not a cluster
              return this.convertToPoiListItem(poi, typesCache, serviceTypesHintsTags);
            }
          });
          return list;
        })
      );
    } else {
      return of(list);
    }
  }

  /**
   * Transform a single aggregate
   *
   * @param aggregate A single aggregate from ESRI find/near
   */
  public convertToPoiListAggregate(aggregate: FindResponseAggregate): PoiListAggregate {
    const result = new PoiListAggregate();
    result.id = aggregate.key;
    result.name = aggregate.name;
    result.count = aggregate.count;
    result.type = PoiListItemType.aggregate;
    result.coordinates = {
      latitude: aggregate.y,
      longitude: aggregate.x,
    };

    return result;
  }

  /**
   * Transform and enrich one POI
   *
   * @param poi A single POI from ESRI find
   * @returns An observable emitting a transformed POI
   */
  public convertToPoiListItem(poi: FindResponsePoi, serviceTypesCache: ServiceTypesCache, serviceTypesTagHints: string[]): PoiListItem {
    const poiListItem = new PoiListItem();

    poiListItem.id = poi.id;
    poiListItem.type = PoiListItemType.poi;
    poiListItem.name = poi.name;
    poiListItem.street = poi.info.Street;
    poiListItem.zip = poi.info.Zip;
    poiListItem.city = poi.info.City;
    poiListItem.additionalDescription = poi.info.AdditionalDescription;
    poiListItem.coordinates = {
      latitude: poi.y,
      longitude: poi.x,
    };

    poiListItem.coincident = !!(poi as any).coincident;
    poiListItem.serviceType = serviceTypesCache.typesByTag[poi.type];

    // Hints (hints are checked later on)
    poiListItem.hasHints = !!poi.info.hasHints;

    // opening hours
    if (poi.info.counters && poi.info.counters.length > 0) {
      this.determineCounterTimes(serviceTypesCache, poi, poiListItem);

      // Check if there are any servicetypes that are hints.
      if (!poiListItem.hasHints && poi.info.services) {
        poiListItem.hasHints = poi.info.services.some((serviceTag: string) => serviceTypesTagHints.includes(serviceTag));
      }
    }

    // Accessible
    const accessibleTags = this.configurationService
      .getConfiguration()
      .accessibleByWheelchairServiceIds.map((x) => serviceTypesCache.typesById[x].tag);
    poiListItem.accessibleByWheelchair = poi.info.services.some((poiTag: string) =>
      accessibleTags.some((accessibleTag) => accessibleTag === poiTag)
    );

    // Deadlines
    poiListItem.deadlinesProduct = {};
    if (poi.info.products && poi.info.products.length > 0) {
      for (const deadline of poi.info.products.filter((p) => p.deadline)) {
        const serviceType = serviceTypesCache.typesByTag[deadline.tag];
        const productId = serviceType.id;
        const title = serviceType.desc;
        const toposTime = fromXmlTimeStringToToposTime(deadline.deadline);
        poiListItem.deadlinesProduct[productId] = {
          time: toposTime,
          title,
        };
      }
    }

    return poiListItem;
  }

  /**
   * Gets the tags of all servicetypes that are hints.
   */
  private getServiceTypeIdsThatAreHints(): Observable<string[]> {
    return this.typeService.getTypes().pipe(
      map((serviceTypesCache) => {
        const serviceGroupHintNames: string[] = this.configurationService.getConfiguration().serviceTypeHintsGroupName;
        return Object.keys(serviceTypesCache.typesByTag)
          .map((tag) => serviceTypesCache.typesByTag[tag])
          .filter((entry) => entry.group && serviceGroupHintNames.includes(entry.group))
          .map((entry) => entry.tag);
      })
    );
  }

  private determineCounterTimes(
    serviceTypesCache: ServiceTypesCache,
    poi: FindResponsePoi,
    poiListItem: PoiListItem
  ) {
    let defaultCounterTag;

    const pickpostDrittstelleTag = serviceTypesCache.typesById['001MP24'].tag;
    const myPost24Tag = serviceTypesCache.typesById['001AG-PICK'].tag;

    // pickpost and myPost24: from Zugang instead of Normalschalter
    if (poi.type === pickpostDrittstelleTag || poi.type === myPost24Tag) {
      // Zugang
      defaultCounterTag = serviceTypesCache.typesById['00199'].tag;
    } else {
      // Normalschalter
      defaultCounterTag = serviceTypesCache.typesById['0011'].tag;
    }

    let counter: FindResponseCounter = poi.info.counters.find((x) => x.tag === defaultCounterTag);
    if (counter == null) {
      counter = poi.info.counters[0];
    }

    if (counter.openUntil) {
      const openUtil: ToposTime = fromXmlTimeStringToToposTime(counter.openUntil);
      const currentTime: Date = this.currentDateTimeService.getCurrentDateTime();
      poiListItem.openUntil = getToposTimeAsDate(currentTime, openUtil);
    }

    if (counter.openAgain) {
      const openAgainDayDateString = counter.openAgain.substring(0, 10);
      const openAgainDate = fromXmlDateStringToDate(openAgainDayDateString);

      const openAgainTimeString = counter.openAgain.substring(11);
      const openAgainTime = fromXmlTimeStringToToposTime(openAgainTimeString);
      poiListItem.openAgain = getToposTimeAsDate(openAgainDate, openAgainTime);
    }
  }
}
