import { HttpHeaders } from '@angular/common/http';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

import { zip } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { HeaderObserverService } from './common/header-observer.service';
import { NeedsCacheService } from './gis/services/needs-cache.service';
import { TypesCacheService } from './gis/services/types-cache.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  providers: [HeaderObserverService],
})
export class AppComponent implements OnInit, OnDestroy {
  openedMenu = false;

  constructor(
    @Inject(LOCALE_ID) public language: string,
    private needsService: NeedsCacheService,
    private typesService: TypesCacheService,
    private translateService: TranslateService,
    private headerObserver: HeaderObserverService,
    private router: Router,
    private logger: NGXLogger
  ) {}

  public ngOnInit() {
    const traceId = uuid.v4().replace('-', '');
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.logger.setCustomHttpHeaders(new HttpHeaders({'x-trace-id': traceId}));
    this.logger.updateConfig({ level: environment.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.DEBUG });
    this.logger.debug(`Starting new trace with id ${traceId}`);

    this.router.events.subscribe(() => {
      this.openedMenu = false;
    });

    this.translateService.use(this.language);

    // Precache types and needs
    zip(this.needsService.getNeeds(), this.typesService.getTypes()).subscribe().unsubscribe();

    // Start tracking header visibility
    this.headerObserver.observe();
  }

  ngOnDestroy() {
    this.headerObserver.disconnect();
  }

  public toggleMenu() {
    this.openedMenu = !this.openedMenu;
  }
}
