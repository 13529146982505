export class TopicConversionService {
  /**
   * Gets the topicIds that are convertable to needIds
   */
  public static getConvertableTopicIds(): Array<string> {
    return this.getConversionItems().map((ci) => ci.topicId);
  }

  /**
   * Gets all the topicIds and their corresponding needId
   */
  public static getConversionItems(): Array<TopicConversionItem> {
    const topicConversionItems: Array<TopicConversionItem> = [];

    // topicConversionItems.push({ topicId: '57', needId: '110.2' });
    // topicConversionItems.push({ topicId: '1', needId: '110.2' });
    // topicConversionItems.push({ topicId: '11', needId: '100.1' });
    // topicConversionItems.push({ topicId: '23', needId: '10.1' });
    // topicConversionItems.push({ topicId: '34', needId: '100.1' });
    // topicConversionItems.push({ topicId: '2', needId: '35.3' });
    // topicConversionItems.push({ topicId: '70', needId: '110.3' });
    // topicConversionItems.push({ topicId: '15', needId: '70.1' });
    // topicConversionItems.push({ topicId: '12', needId: '50.1' });
    // topicConversionItems.push({ topicId: '81', needId: '110.8' });
    // topicConversionItems.push({ topicId: '74', needId: '35.3' });
    // topicConversionItems.push({ topicId: '35', needId: '100.1' });
    // topicConversionItems.push({ topicId: '14', needId: '70.4' });
    // topicConversionItems.push({ topicId: '61', needId: '110.10' });
    // topicConversionItems.push({ topicId: '73', needId: '110.3' });
    // topicConversionItems.push({ topicId: '8', needId: '80.3' });
    // topicConversionItems.push({ topicId: '39', needId: '110.10' });
    // topicConversionItems.push({ topicId: '9', needId: '80.2' });
    // topicConversionItems.push({ topicId: '31', needId: '110.9' });

    return topicConversionItems;
  }

  /**
   * Gets the needId based on the corresponding topicId
   */
  public static getNeedIdByTopicId(topicId: string): string {
    return this.getConversionItems().filter((ci) => ci.topicId === topicId)[0].needId;
  }
}

export class TopicConversionItem {
  topicId: string;
  needId: string;
}
