/* eslint-disable @typescript-eslint/member-ordering */
import { SimpleCoordinates } from '../simplecoordinates';
import { Address } from './address';
import { CounterState } from './counterstate';
import { DeadlinesForProduct } from './deadlinesforproduct';
import { DeadlineToday } from './deadlinetoday';
import { OpeningHoursNextDays } from './openinghoursnextdays';
import { Period } from './period';
import { ServiceGroup } from './servicegroup';

export class PoiDetail {
  // Header
  public id = '';
  public typeId = '';
  public description = '';
  public descriptionDe = '';
  public additionalDescription = '';
  public additionalDescriptionDe = '';
  public street = '';
  public zip = '';
  public technicalZip6 = '';
  public city = '';
  public municipalityId = -1;
  public cantonCode = '';
  public countryCode = '';
  public phone = '';
  public fax = '';
  public accessibleByWheelchair = false;
  public postAppointmentLink = '';
  public postFinanceAppointmentLink = '';
  public servicePointBusinessOfferLink = '';
  public mp24WithoutDisplayLink = '';
  public postfachanlageOfferLink = '';
  public isMp24V2 = false;

  // Tab MP24-Fächer
  get hasMp24Boxes(): boolean {
    return this.myPost24Address !== undefined && this.myPost24Address.technicalZip !== undefined && this.isMp24V2;
  }

  // Tab Öffnungzeiten
  public counterStates: CounterState[] = [];
  public openingHoursNextDays: OpeningHoursNextDays = {
    counterTypes: [],
    nextDays: [],
  };
  public hints: string[] = [];
  get hasOpeningHours(): boolean {
    return this.counterStates.length > 0;
  }

  // Tab Annameschlusszeiten
  public deadlinesToday: DeadlineToday[] = [];
  public deadlinesForProduct: DeadlinesForProduct[] = [];
  get hasDeadlines(): boolean {
    return this.deadlinesToday.length > 0;
  }

  // Tab Services
  public serviceGroups: ServiceGroup[] = [];
  public serviceHints: string[] = [];
  get hasServices(): boolean {
    return this.serviceGroups.length > 0;
  }

  // Tab Adressierung
  public postlagerndAddress: Address;
  public pickpostAddress: Address;
  public myPost24Address: Address;
  get hasAddresses(): boolean {
    return !!(this.postlagerndAddress || this.pickpostAddress || this.myPost24Address);
  }

  // Tab Partners
  public partnerServiceGroups: ServiceGroup[] = [];
  get hasPartners(): boolean {
    return this.partnerServiceGroups.length > 0;
  }

  // Koordinaten
  public coordinates: SimpleCoordinates = {
    latitude: 0.0,
    longitude: 0.0,
  };

  public closedPeriods: Period[] = [];

  public get title(): string {
    return this.additionalDescription ? `${this.description} ${this.additionalDescription}` : this.description;
  }

  public get titleDe(): string {
    return this.additionalDescriptionDe ? `${this.descriptionDe} ${this.additionalDescriptionDe}` : this.descriptionDe;
  }
}
