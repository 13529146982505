<div>
  <div class="py-4">
    <h3 class="fw-bold mb-3 services-header" translate>detail.services.service</h3>

    <app-hint [hints]="poi?.serviceHints"></app-hint>

    <div class="row">
      <div class="col-12 masonry-container">
        <div class="masonry-item" *ngFor="let serviceGroup of poi?.serviceGroups">
          <div class="time-item font-curve-small">
            <h4 class="fw-bold font-curve-small">{{serviceGroup.groupName}}</h4>
            <ul class="services-list">
              <li *ngFor="let service of serviceGroup.services">
                <span *ngIf="!service.url">{{service.serviceName}}</span>
                <a *ngIf="service.url" [href]="service.url" target="_blank"
                  (click)="trackLinkClick($event, service)">{{service.serviceName}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>