import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Wait for the post header to arrive in dom, then start listening to class mutations.
 * This actually just copies the .h-visible class to the document body (alias header-visible)
 * so page styles can react to the header being visible, e.g. position sticky elements otherwise
 * hidden beneath the position fixed header.
 */
@Injectable()
export class HeaderObserverService {
  private observer: MutationObserver;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) { }

  getHeaderElement(): Observable<Element> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    return new Observable((observer) => {
      const header = this.document.getElementsByClassName(
        'ppm-main-navigation'
      )[0];

      if (header) {
        observer.next(header);
        observer.complete();
      } else {
        const mutationObserver = new MutationObserver(() => {
          const h = this.document.getElementsByClassName(
            'ppm-main-navigation'
          )[0];
          if (h) {
            observer.next(h);
          }
        });
        mutationObserver.observe(this.document.documentElement, {
          childList: true,
          attributes: false,
          subtree: false,
        });

        return () => mutationObserver.disconnect();
      }
    });
  }

  observe() {
    if (
      isPlatformServer(this.platformId) ||
      !this.document ||
      !('MutationObserver' in window)
    ) {
      return;
    }

    this.getHeaderElement()
      .pipe(take(1))
      .subscribe((header) => {
        this.observer = new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            if (mutation.type === 'attributes') {
              this.document.documentElement.classList.toggle(
                'header-visible',
                header.classList.contains('h-visible')
              );
            }
          }
        });

        this.observer.observe(header, {
          attributes: true,
          subtree: false,
          characterData: false,
          childList: false,
        });
      });
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
