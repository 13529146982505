import { Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SimpleCoordinates } from '../model/simplecoordinates';
import { BaseLocationService } from './base.location.service';

@Injectable()
export class BrowserLocationService extends BaseLocationService implements OnDestroy {
  private locationWatcherId: number;
  private currentCoordinates: SimpleCoordinates = null;
  private locationPermissionGranted = false;

  constructor(private readonly ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  ngOnDestroy(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (window.navigator.geolocation && this.locationWatcherId) {
      window.navigator.geolocation.clearWatch(this.locationWatcherId);
      this.locationWatcherId = null;
    }
  }

  /**
   * Returns true if location service is enabled
   * Could be made the sync way now. I leave it as it is.
   */
  public isLocationServiceEnabled(): Observable<boolean> {
    return of(this.currentCoordinates != null);
  }

  /**
   * Tries to get the current coordinates. The browser will ask the user for permission
   * if not granted already.
   */
  public askForPermission(): Observable<boolean> {
    // Check if permission has already been granted
    if (this.locationPermissionGranted) {
      return of(true);
    }

    return this.registerLocationListener().pipe(
      map(() => {
        this.locationPermissionGranted = true;
        return true;
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Gets the last position; reads it form cache.
   * Check if it is enabled first with isLocationServiceEnabled.
   * Could be made the sync way now. I leave it as it is.
   */
  public getLocation(): Observable<SimpleCoordinates> {
    return of(this.currentCoordinates);
  }

  /**
   * Gets the position from the browser und updates the cache. This is called
   * periodically.
   */
  private registerLocationListener(): Observable<SimpleCoordinates> {
    let observable = new Observable<SimpleCoordinates>((subscriber) => {

      if (!isPlatformBrowser(this.platformId)) {
        return;
      }
      if (!window.navigator.geolocation) {
        this.currentCoordinates = null;
        subscriber.error('window.navigator.geolocation is null');
      } else {
        // clear watch if there is already a watch.
        if (this.locationWatcherId) {
          window.navigator.geolocation.clearWatch(this.locationWatcherId);
          this.locationWatcherId = null;
        }

        this.locationWatcherId = window.navigator.geolocation.watchPosition(
          (result: any) => {
            this.ngZone.run(() => {
              const coords: SimpleCoordinates = {
                longitude: result.coords.longitude,
                latitude: result.coords.latitude,
                accuracy: result.coords.accuracy,
              };

              this.currentCoordinates = coords;

              // this code here is called multiple times,
              // each time a new position is reported by the browser.
              // But the caller of registerLocationListener only wants the first result.
              if (observable != null) {
                subscriber.next(coords);
                subscriber.complete();
                observable = null;
              }
            });
          },
          (error) => {
            this.ngZone.run(() => {
              subscriber.error(error);
            });
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 100000,
          }
        );
      }
    });
    return observable;
  }
}
