import { isPlatformServer } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { SeoService } from '@app/app/common/seo.service';
import { ConfigurationService } from '@app/app/config/configuration.service';
import { HeaderConfigService } from '@app/app/config/header-config.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { homeRoutes } from 'src/app/home-routes.static';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public webBaseUrl: string;

  constructor( // NOSONAR
    private logger: NGXLogger,
    private router: Router,
    private readonly seoService: SeoService,
    private readonly configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly headerConfigService: HeaderConfigService,
    @Inject(LOCALE_ID) public readonly currentLanguage: string,
    @Inject(PLATFORM_ID) private readonly platformId: any,
  ) {
    this.webBaseUrl = this.configurationService.getConfiguration().webBaseUrl;
  }

  ngOnInit(): void {
    const languageUrls = Object.keys(homeRoutes).map((key) => ({lang: key, url: `${this.webBaseUrl}/${homeRoutes[key]}`}));

    if (isPlatformServer(this.platformId)) {
      this.seoService.setLangLinks(languageUrls, this.currentLanguage);

      this.translateService.get('notfound.title').subscribe((x) => {
        this.seoService.setTitle(x);
      }).unsubscribe();
    }

    this.headerConfigService.writeHeaderConfiguration(languageUrls);

    this.logger.info(`URL not found: ${this.router.url}`);
  }
}
