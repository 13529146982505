import { PoiListItem, PoiClusterListItem } from '../model/poibase';

/**
 * Flatten ESRI clusters into a poi list
 *
 * @param pois List of converted ESRI pois or clusters
 * @returns PoiListItem[]
 */
// eslint-disable-next-line arrow-body-style
export const flattenPois = (pois: Array<PoiListItem | PoiClusterListItem>): PoiListItem[] => {
  return pois.reduce((map, poi) => {
    if ('pois' in poi) {
      map.push(...poi.pois);
      return map;
    } else {
      map.push(poi);
      return map;
    }
  }, [] as PoiListItem[]);
};
