import { Component, Input } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';
import { Service } from '@app/app/gis/model/poidetail/service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { ConfigurationService } from '@app/app/config/configuration.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
  private _poi: PoiDetail;
  private partnerLogos = this.configurationService.getConfiguration().partnerLogos;

  constructor(private readonly tagManagerService: TagManagerService,
    private readonly configurationService: ConfigurationService) {}

  public get poi() {
    return this._poi;
  }
  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
  }

  public getImgSrc(name: string) {
    const imgUrl = this.partnerLogos.find(pl => pl.name === name).imgUrl;
    return `${this.configurationService.getConfiguration().webBaseUrl}${imgUrl}`;
  }

  public getImgHeight(name: string) {
    return this.partnerLogos.find(pl => pl.name === name).imgHeight;
  }

  public trackLinkClick(event: Event, service: Service) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    /* eslint-disable @typescript-eslint/naming-convention */
    this.tagManagerService.click({
      event: 'link_click',
      link_text: service.serviceName.toLowerCase(),
      link_url: target,
      label: service.serviceNameDe.toLowerCase().replace(/\s/g, '-'),
      type: 'services_list',
      additional_info: `tab-services`
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
