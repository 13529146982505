import { TagManagerService } from '@app/app/common/tag.service';
import { forkJoin } from 'rxjs';
import { TranslateExtendedService } from '../../common/translate-extended.service';

export abstract class AccordionTrackerComponent {
  constructor(
    protected readonly translateExtendedService: TranslateExtendedService,
    protected readonly tagManagerService: TagManagerService
  ) {}

  protected internalTrackPanelChange(tab: string, isOpen: boolean, panelTitleKey: string) {
    forkJoin([this.translateExtendedService.get(panelTitleKey),
      this.translateExtendedService.getGermanTranslation(panelTitleKey)])
      .subscribe(([panelTitle, panelTitleGer]) => {
        const eventName = isOpen ? 'select_content' : 'deselect_content';
        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: eventName,
          type: 'accordion',
          label: panelTitleGer.toLowerCase().replace(/\s/g, '-'),
          text: panelTitle.toLowerCase(),
          additional_info: tab.toLowerCase()
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    );
  }
}
