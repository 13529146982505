import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotFoundComponent } from './not-found/not-found.component';
import { FieldFilter } from './pipes/field-filter.pipe';
import { GlobalErrorHandler } from './services/global-error-handler.service';

@NgModule({
  declarations: [FieldFilter, NotFoundComponent],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
  imports: [TranslateModule],
  exports: [FieldFilter, NotFoundComponent],
})
export class ErrorModule {}
