import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CommonToposModule } from '../common/common.module';
import { AddressingComponent } from './components/addressing/addressing.component';
import { DeadlinesComponent } from './components/deadlines/deadlines.component';
import { DetailPageComponent } from './components/detail-page/detail-page.component';
import { DetailTabsComponent } from './components/detail-tabs/detail-tabs.component';
import { DetailComponent } from './components/detail/detail.component';
import { PoiDetailResolver } from './components/detail/poi-detail.resolver';
import { HintComponent } from './components/hint/hint.component';
import { OpeninghoursComponent } from './components/openinghours/openinghours.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ServicesComponent } from './components/services/services.component';
import { StaticMapComponent } from './components/static-map/static-map.component';
import { Mp24BoxesComponent } from './components/mp24boxes/mp24boxes.component';

@NgModule({
  declarations: [
    AddressingComponent,
    DeadlinesComponent,
    DetailComponent,
    DetailTabsComponent,
    DetailPageComponent,
    HintComponent,
    OpeninghoursComponent,
    ServicesComponent,
    PartnersComponent,
    StaticMapComponent,
    Mp24BoxesComponent,
  ],
  imports: [CommonModule, CommonToposModule, FormsModule, ReactiveFormsModule, TranslateModule, NgbModule],
  providers: [PoiDetailResolver],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    AddressingComponent,
    DeadlinesComponent,
    DetailComponent,
    DetailTabsComponent,
    DetailPageComponent,
    HintComponent,
    OpeninghoursComponent,
    ServicesComponent,
    PartnersComponent,
    StaticMapComponent,
    Mp24BoxesComponent,
  ],
})
export class DetailModule {}
