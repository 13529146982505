import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Observable, zip } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { serialize } from 'src/app/common/serialize';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { BoundingBox } from '../model/boundingbox';
import FindQueryParameters from '../model/FindQueryParameters';
import { GeocodedArea } from '../model/geocodedArea';
import { PoiList } from '../model/poilist';
import { SearchParameters } from '../model/searchparameters';
import { SimpleCoordinates } from '../model/simplecoordinates';
import { FindResponse } from '../model/stao-cache/find.response.type';
import { GeocodeResponse } from '../model/stao-cache/geocode.response.type';
import { isSwitzerlandVisible } from '../util/coordinatesutil';
import { toShortLang } from '../util/toShortLang';
import { viewportClamp } from '../util/viewport-clamp';
import { NeedsCacheService } from './needs-cache.service';
import { PoiConverterService } from './poi-converter.service';
import { QueryTransformerService } from './query-transformer.service';

@Injectable({
  providedIn: 'root',
})
export class GisService {
  private limitEsri = 30;
  private gisApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private language: string,
    private needService: NeedsCacheService,
    private configurationService: ConfigurationService,
    private converter: PoiConverterService,
    private queryTransformer: QueryTransformerService
  ) {
    const configuration = this.configurationService.getConfiguration();
    this.gisApiUrl = configuration.gisApiUrl;
  }

  /**
   * Geocode locations based on need and query
   *
   * @param needId User selected need id
   * @param query User input search query (zip, city, street, etc.)
   * @param lang Current language
   */
  public geocodeByNeed(needId: number, query?: string, lang?: string): Observable<GeocodedArea[]> {
    return this.needService.getNeedById(needId).pipe(switchMap((needConfig) => this.geocode(query, needConfig.query, lang)));
  }

  /**
   * Geocode locations based on need, query and WebComponent type filter
   *
   * @param needId User selected need id
   * @param query User input search query (zip, city, street, etc.)
   * @param filter Query filter for WebComponent
   * @param lang Current language
   */
  public geocodeByNeedAndFilter(needId: number, query: string, filter: string[], lang?: string): Observable<GeocodedArea[]> {
    // eslint-disable-next-line arrow-body-style
    return this.needService.getNeedById(needId).pipe(switchMap(needConfig => {
      // eslint-disable-next-line arrow-body-style
      return this.queryTransformer.multiplyOut(needConfig.query, filter).pipe(switchMap(multipliedQuery => {
        return this.geocode(query, multipliedQuery, lang);
      }));
    }));
  }

  /**
   * Perform a find request by providing bounding box and need.
   *
   * @param search Search options.
   * @param boundingBox Area to search.
   */
  public findByNeed(searchParameters: SearchParameters, boundingBox: BoundingBox): Observable<PoiList> {
    return this.needService.getNeedById(searchParameters.needId).pipe(
      switchMap((needConfig) =>
        zip(
          this.queryTransformer.addAdditionalInfoToQuery(needConfig, searchParameters),
          this.queryTransformer.openAtString(needConfig.secondlevelneeds, searchParameters),
          this.queryTransformer.timelyString(needConfig.secondlevelneeds, searchParameters)
        )
      ),
      switchMap(([query, openAt, timely]) =>
        this.find({
          tags: query,
          boundingBox,
          openAt,
          timely,
          language: searchParameters.language ? searchParameters.language : this.language,
          maxPois: searchParameters.maxPois ?? viewportClamp([320, 1440], [50, 150], searchParameters.viewportWidth),
          clusterDistance: searchParameters.clusterDistance ?? viewportClamp([320, 1440], [30, 20], searchParameters.viewportWidth),
          aggregationLevel: searchParameters.aggregationLevel ?? isSwitzerlandVisible(boundingBox, searchParameters.zoomLevel) ? 1 : 0,
          autoexpand: searchParameters.autoexpand ?? false,
        })
      )
    );
  }

  /**
   * Perform a near request when location is provided by the location service
   * Gis searches inside a rectangle. The size of the rectangle is fixed.
   *
   * @param needId User selected need id
   * @param location Center point
   * @param andIds Each poi must have each id assigned. For example access by wheelchair.
   */
  public getPoisNearbyByNeedAndLocation(searchParameters: SearchParameters, location: SimpleCoordinates): Observable<PoiList> {
    // Gather all parameters needed for the search
    return this.needService.getNeedById(searchParameters.needId).pipe(
      switchMap((needConfig) =>
        zip(
          this.queryTransformer.addAdditionalInfoToQuery(needConfig, searchParameters),
          this.queryTransformer.openAtString(needConfig.secondlevelneeds, searchParameters),
          this.queryTransformer.timelyString(needConfig.secondlevelneeds, searchParameters)
        )
      ),
      switchMap(([query, openAt, timely]) => this.near(query, location, undefined, 50, openAt, timely))
    );
  }

  /**
   * Find POIs by tag in an area
   *
   * @param parameters Set of query parameters for the find request
   * @returns Observable emitting a formatted list of pois
   */
   public find(parameters: FindQueryParameters): Observable<PoiList> {

    const defaults = {
      aggregationLevel: 0,
      levelOfDetail: 2,
      clusterDistance: 0,
      maxPois: 50,
      encoding: 'UTF-8',
      autoexpand: false,
    } as FindQueryParameters;

    const mergedParameters = Object.assign({}, defaults, parameters);

    const lon1 = mergedParameters.boundingBox.southWest.longitude;
    const lon2 = mergedParameters.boundingBox.northEast.longitude;
    const lat1 = mergedParameters.boundingBox.southWest.latitude;
    const lat2 = mergedParameters.boundingBox.northEast.latitude;
    const openAtString = mergedParameters.openAt.length > 0 ? `NOP,${mergedParameters.openAt}` : '';
    const queryParameters = serialize({
      extent: `${lon1},${lat1},${lon2},${lat2}`,
      clusterdist: mergedParameters.clusterDistance,
      query: mergedParameters.tags,
      agglevel: mergedParameters.aggregationLevel,
      lod: mergedParameters.levelOfDetail,
      lang: mergedParameters.language,
      autoexpand: mergedParameters.autoexpand,
      encoding: mergedParameters.encoding,
      openat: openAtString,
      timely: mergedParameters.timely,
      maxpois: mergedParameters.maxPois,
    });

    const url = `${this.gisApiUrl}/Find?${queryParameters}`;

    return this.http.get<FindResponse>(url).pipe(
      map((res) => {
        if (!res.ok) {
          throw new Error(res.info);
        }

        return res;
      }),
      mergeMap((x) => this.converter.convertToResultList(x))
    );
  }

  /**
   * Get center and bounding box data for a query, optionally also list of
   * limited POIs (only Post Branches, no Letterboxes, myPost24, etc.)
   *
   * @param query User defined search query (zip, city, location)
   * @param tags Tags based on user defined need
   * @param limit Limit number of results
   * @param lang Language string, will be converted to ESRI short lang
   */
  public geocode(
    query: string,
    // "false" prevents esri from delivering any pois
    tags: string = 'false',
    lang: string = this.language
  ): Observable<GeocodedArea[]> {
    const tagsEncoded = encodeURIComponent(tags);
    const queryEncoded = encodeURIComponent(query);
    const esriLang = toShortLang(lang);
    const url =
    `${this.gisApiUrl}/Geocode?query=${queryEncoded}&pois=${tagsEncoded}&lang=${esriLang}&limit=${this.limitEsri}`;
    const observable = this.http.get<GeocodeResponse>(url);

    return observable.pipe(map((x) => this.converter.convertToGeocodedArea(x)));
  }

  /**
   * Get POIs near a center point
   *
   * @param tags Query of tags
   * @param center Center point
   * @param lang ESRI short lang
   * @param openAt
   * @param timely
   * @returns Observable emitting a formatted list of pois
   */
  private near(
    tags: string,
    center: SimpleCoordinates,
    lang: string = this.language,
    maxDistance?: number,
    openAt: string = '',
    timely: string = ''
  ): Observable<PoiList> {
    const tagsEncoded = encodeURIComponent(tags);
    const clusterdist = `&clusterdist=6`;
    const factorToKm = 0.013201704823;
    const maxdist = `&maxdist=${maxDistance * factorToKm || 30 * factorToKm}`;
    const maxpois = `&maxpois=20`;
    const coordinatesParam = `${center.longitude},${center.latitude}`;
    const centerString = `&center=${encodeURIComponent(coordinatesParam)}`;
    const lod = `&lod=2`;
    const esriLang = `&lang=${toShortLang(lang || this.language)}`;
    const openAtValue = `NOP,${openAt}`;
    const openAtString = openAt.length > 0 ? `&openat=${encodeURIComponent(openAtValue)}` : '';
    const timelyString = timely.length > 0 ? `&timely=${encodeURIComponent(timely)}` : '';

    const url = [
      this.gisApiUrl,
      '/Near?query=',
      tagsEncoded,
      centerString,
      esriLang,
      clusterdist,
      maxdist,
      maxpois,
      lod,
      openAtString,
      timelyString,
    ].join('');

    const observable = this.http.get<FindResponse>(url);

    return observable.pipe(mergeMap((x) => this.converter.convertToResultList(x)));
  }
}
