import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CurrentDateTimeService {
  private dateParam: Date;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params['timetravel']) {
        this.dateParam = new Date(params['timetravel']);
        console.log('Time traveling to ' + this.dateParam);
      }
    });
  }

  /**
   * Gets the current time in Switzerland.
   */
  public getCurrentDateTime(): Date {
    if (this.dateParam) {
      return this.dateParam;
    }

    // Swiss Time
    return moment().tz('Europe/Berlin').toDate();
  }

}
