import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { TopicConversionService } from './topic-conversion.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private activatedRoute: ActivatedRoute, private logger: NGXLogger) {}

  public getQueryParam(queryParamKey: string): string {
    let paramValue;

    // get query parameter case sensitive
    this.activatedRoute.queryParamMap.subscribe((params) => {
      params.keys.forEach((key) => {
        if (key.toLowerCase() === queryParamKey) {
          paramValue = params.get(key);
          this.logger.debug(`Handled param ${key}: ${paramValue}`);
        }
      });
    });

    return paramValue;
  }

  public getNeedIdParam(): string {
    let needId = this.getQueryParam('needid');

    if (!needId) {
      const topicId = this.getQueryParam('topic');

      if (topicId) {
        needId = TopicConversionService.getNeedIdByTopicId(topicId);
      }
    }

    return needId;
  }
}
