import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { BaseLocationService } from '../gis/location/base.location.service';
import { BrowserLocationService } from '../gis/location/browser.location.service';

import { SeoService } from './seo.service';
import { TranslateExtendedService } from './translate-extended.service';
import { AcceptLanguageInterceptor } from './accept-language-interceptor';
import { SwissPostDateParserFormatter, SwissPostDatepickerI18n } from './datepicker-configuration';
import { ClientAndBotOnlyRenderDirective } from './ssr/client-and-bot-only-render.directive';
import { ClientOnlyRenderDirective } from './ssr/client-only-render.directive';
import { ServerOnlyRenderDirective } from './ssr/server-only-render.directive';
import { BrowserLanguageService } from './language/browser-language.service';
import { BaseLanguageService } from './language/base-language.service';
import { WINDOW_PROVIDERS } from './window.service';
import { TagManagerService } from './tag.service';

@NgModule({
  declarations: [
      ClientAndBotOnlyRenderDirective,
      ClientOnlyRenderDirective,
      ServerOnlyRenderDirective
  ],
  imports: [
    CommonModule
  ],
  providers: [
    SeoService,
    { provide: BaseLanguageService, useClass: BrowserLanguageService },
    { provide: BaseLocationService, useClass: BrowserLocationService },
    { provide: NgbDatepickerI18n, useClass: SwissPostDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: SwissPostDateParserFormatter },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true,
    },
    TagManagerService,
    TranslateExtendedService,
    WINDOW_PROVIDERS,
  ],
  exports: [
      ClientAndBotOnlyRenderDirective,
      ClientOnlyRenderDirective,
      ServerOnlyRenderDirective
  ]
})
export class CommonToposModule { }
