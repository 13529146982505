export enum GeoCodedAreaType {
  unknown = 0,
  zip = 1,
  region = 2,
  locality = 3,
  poi = 4,
  city = 5,
  address = 6,
  currentLocation = 7,
}
