<div>
  <!-- deadlines today -->
  <div *ngIf="showDeadlinesToday === true" class="py-4">
    <h3 class="font-curve-regular fw-bold mb-4" translate>
      <span translate>detail.hours.deadlineToday</span>
      <button class="info-button ms-1" [ngbPopover]="'common.deadlineinfo' | translate">
        <post-icon name="2106" scale="0.7"></post-icon>
      </button>
    </h3>
    <p class="bg-info p-3 hint" *ngIf="infoText" translate>
      {{ infoText }}
    </p>
    <div class="row" *ngIf="!infoText">
      <div class="col-rg-6" *ngFor="let deadlineToday of poi?.deadlinesToday">
        <div class="time-item">
          <h4 class="font-curve-small fw-bold">{{ deadlineToday.productTypeName }}</h4>

          <p
            class="font-curve-small"
            [class.text-confirmation]="deadlineToday.deadLineStillOk === true"
            [class.text-danger]="deadlineToday.deadLineStillOk === false"
          >
            <span class="fw-bold">
              {{ deadlineToday.timeString }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- deadlines at a specific date -->
  <div *ngIf="showDeadlinesAtDate === true" class="py-4">
    <h3 class="fw-bold font-curve-regular mb-4">
      <span>{{ deadlinesAtTitle }}</span>
      <button class="info-button ms-1" [ngbPopover]="'common.deadlineinfo' | translate">
        <post-icon name="2106" scale="0.7"></post-icon>
      </button>
    </h3>
    <p class="bg-info p-3 hint" *ngIf="infoText" translate>
      {{ infoText }}
    </p>
    <div class="row" *ngIf="!infoText">
      <div class="col-rg-6" *ngFor="let deadlineProductAtDate of deadlinesProductAtDate">
        <div class="time-item">
          <h4 class="font-curve-small fw-bold">{{ deadlineProductAtDate.productTypeName }}</h4>

          <p class="font-curve-small">
            <span *ngFor="let timeString of deadlineProductAtDate.timeStrings">
              {{ timeString }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- deadlines in the next seven days -->
  <post-accordion #acc (postToggle)="trackPanelChange($event)">
    <post-accordion-item id="deadlinePanel">
      <h3 slot="header" class="fw-bold font-curve-regular mb-0">{{ 'detail.hours.deadlines' | translate }}</h3>
      <div class="row">
        <div class="col-rg-6" *ngFor="let deadlineForProduct of poi?.deadlinesForProduct">
          <div class="time-item font-curve-small">
            <h4 class="font-curve-small fw-bold">{{ deadlineForProduct.productTypeName }}</h4>
            <div *ngFor="let dayOpeningHour of deadlineForProduct.daysOpeningHours" class="row">
              <div class="col-6">
                {{ dayOpeningHour.daysText }}
              </div>
              <div class="col-6">
                {{ dayOpeningHour.timeString }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </post-accordion-item>
  </post-accordion>
</div>
