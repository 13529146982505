<div class="container mb-4">
  <h1 class="fw-bold mb-4" translate>common.404.title</h1>
</div>
<div class="container-fluid giant bg-light">
  <div class="row">
    <div class="col-xs-12 col-md-10 col-lg-7 offset-md-1 offset-lg-3">
      <div class="content">
        <p translate>common.404.copy1</p>
        <p translate>common.404.copy2</p>
        <p translate>common.404.copy3</p>
        <p><a href="{{ webBaseUrl }}" translate>common.404.link1</a></p>
      </div>
    </div>
  </div>
</div>
