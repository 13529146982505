import { BoundingBox } from '../model/boundingbox';
import { SimpleCoordinates } from '../model/simplecoordinates';

export const distInKm = (point1: SimpleCoordinates, point2: SimpleCoordinates): number => {
  if (point1.latitude === point2.latitude && point1.longitude === point2.longitude) {
    return 0;
  } else {
    const radlat1 = (Math.PI * point1.latitude) / 180;
    const radlat2 = (Math.PI * point2.latitude) / 180;
    const theta = point1.longitude - point2.longitude;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
  }
};

/**
 * Figure out if the whole of switzerland is visible based on zoomlevel and current boundingbox
 * https://gist.github.com/graydon/11198540
 * 'CH': ('Switzerland', (6.02260949059, 45.7769477403, 10.4427014502, 47.8308275417))
 *
 * @param boundingBox Current bounding box
 * @param zoomLevel Current zoom level from google maps
 * @returns boolean
 */
export const isSwitzerlandVisible = (_boundingBox: BoundingBox, zoomLevel: number): boolean => zoomLevel <= 7;

export const swissBoundaries: BoundingBox = {
  northEast: {
    latitude: 47.9,
    longitude: 10.5,
  },
  southWest: {
    latitude: 45.7,
    longitude: 6,
  },
};

/**
 * Get the coordinate accuracy in pixels, depending on the current zoom level
 *
 * @param coords Current location coordinates
 * @param zoom zoom level
 */
export const getAccuracyInPixels = (coords: SimpleCoordinates, zoom: number): number => {
  const metersPerPx = (156543.03392 * Math.cos((coords.latitude * Math.PI) / 180)) / Math.pow(2, zoom);
  return coords.accuracy / metersPerPx;
};
