import { isPlatformServer } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { homeRoutes } from 'src/app/home-routes.static';
import { SeoService } from '../common/seo.service';
import { TagManagerService } from '../common/tag.service';
import { TranslateExtendedService } from '../common/translate-extended.service';
import { ConfigurationService } from '../config/configuration.service';
import { HeaderConfigService } from '../config/header-config.service';
import { ControllerService } from '../controller.service';
import { PoiList } from '../gis/model/poilist';

@Component({
  // eslint-disable-next-line
  selector: 'topos-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('resultFilter', {read: ElementRef}) resultFilter: ElementRef;

  public poiList: PoiList;

  // There is a map and a list of pois on the screen (desktop).
  // The list is not always shown to the user.
  // Intent:
  //  If pois are found, the list will be shown and will stay visible till
  //  the user zooms out to a certain zoom level.

  public poisFoundWhileMapHasBeenActive = false;
  public poisOnMapNow = false;
  public queryNoResult = false;
  public listViewVisible = false;
  public poiFilterVisible = false;

  private webBaseUrl: string;
  private searchInitSub$: Subscription;
  private zoomLevelSub$: Subscription;

  constructor( // NOSONAR
    private logger: NGXLogger,
    private controllerService: ControllerService,
    private readonly seoService: SeoService,
    private configurationService: ConfigurationService,
    private readonly translateService: TranslateService,
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly headerConfigService: HeaderConfigService,
    private readonly tagManagerService: TagManagerService,
    @Inject(LOCALE_ID) private readonly currentLanguage: string,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.webBaseUrl = this.configurationService.getConfiguration().webBaseUrl;
  }

  @HostListener('window:scroll') onScroll() {
    if (!this.resultFilter?.nativeElement?.parentElement) {
      return;
    }
    const isSticky = this.resultFilter.nativeElement.getBoundingClientRect().top <= 72;
    this.resultFilter.nativeElement.parentElement.classList.toggle('is-sticky', isSticky);
  }

  ngOnInit() {
    this.logger.debug(`home.ngOnInit for ${this.platformId}.`);

    const languageUrls = Object.keys(homeRoutes).map((key) => {
      let url = '';

      // Check for queryParams, if present add them to url
      if (window.location.href.split('?')[1]) {
        url = `${this.webBaseUrl}/${homeRoutes[key]}?${window.location.href.split('?')[1]}`;
      } else {
        url = `${this.webBaseUrl}/${homeRoutes[key]}`;
      }

      return {lang: key, url};
    });

    if (isPlatformServer(this.platformId)) {
      this.logger.debug(`home.ngOnInit isPlatformServer(${this.platformId}) === true`);
      this.seoService.setLangLinks(languageUrls, this.currentLanguage);

      this.translateService.get('home.title').subscribe((x) => {
        this.seoService.setTitle(x);
      }).unsubscribe();
      this.translateService.get('home.meta.description').subscribe((x) => {
        this.seoService.setDescription(x);
      }).unsubscribe();
      this.translateService.get('home.meta.og.title').subscribe((x) => {
        this.seoService.setOgTitle(x);
      }).unsubscribe();
      this.translateService.get('home.meta.og.description').subscribe((x) => {
        this.seoService.setOgDescription(x);
      }).unsubscribe();

      this.seoService.setRobots();
    }

    // Unnecessary complicated workaround for funky translation services
    this.translateExtendedService.getGermanTranslation('common.application.title')
    .subscribe(titleGer => {
        this.translateService.get('common.application.title')
        .subscribe(title => {
            this.tagManagerService.view({
                event: 'page_context',
                pageId: 'home',
                pageName: title,
                unifiedPageName: titleGer,
                pageType: 'home'
            });
        });
    });

    this.headerConfigService.writeHeaderConfiguration(languageUrls);

    this.handleSearchEvent();
    this.handleZoomEvent();
  }

  ngOnDestroy() {
    if (this.searchInitSub$) {
      this.searchInitSub$.unsubscribe();
    }

    if (this.zoomLevelSub$) {
      this.zoomLevelSub$.unsubscribe();
    }
  }

  private handleSearchEvent() {
    this.searchInitSub$ = this.controllerService.getSearchObservable().subscribe((pois: PoiList) => {
      const searchParams = this.controllerService.getSearchParameters().value;

      this.queryNoResult = false;
      if (pois?.pois.length > 0) {
        this.poisFoundWhileMapHasBeenActive = true;
        this.poisOnMapNow = true;
      } else if (pois?.extent == null) {
        if (searchParams) {
          this.queryNoResult = true;
        } else {
          this.poisFoundWhileMapHasBeenActive = false;
        }
      } else {
        this.poisOnMapNow = false;
        if (pois?.aggregates.length === 0) {
          this.queryNoResult = true;
        }
      }
      this.setListViewVisible();
      this.setPoiFilterVisible();
      this.showResult(pois);

      // Scroll only if either:
      // 1. Specified in searchParams
      // 2. Specific cases on desktop, where the changing result list has an impact on scrolling
      if ((searchParams && searchParams?.scrollToMap) ||
          (window.innerWidth > 1024 && (this.poisFoundWhileMapHasBeenActive || this.queryNoResult))) {
        this.controllerService.scrollMapIntoView();
      }
    });
  }

  private handleZoomEvent() {
    this.zoomLevelSub$ = this.controllerService.getZoomLevelObservable().subscribe((zoomLevel) => {
      // If the user zooms out to a certain level, the list
      // will be hidden again (only if no pois are shown on the map).
      if (zoomLevel < 11 && this.poisOnMapNow === false) {
        this.poisFoundWhileMapHasBeenActive = false;
      }
      this.setListViewVisible();
      this.setPoiFilterVisible();
    });
  }

  private setListViewVisible() {
    this.listViewVisible = this.poisFoundWhileMapHasBeenActive || this.queryNoResult;
  }

  private setPoiFilterVisible() {
    this.poiFilterVisible = this.listViewVisible || this.controllerService.isInitialSearchDone();
  }

  private showResult(pois: PoiList) {
    this.poiList = pois;
  }
}
