import { DOCUMENT, formatDate, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDatepicker, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseSearchComponent } from '@app/app/base/components/base-search.component';
import { RoutingService } from '@app/app/common/routing.service';
import { TagManagerService } from '@app/app/common/tag.service';
import { BoundingBox } from '@app/app/gis/model/boundingbox';
import { GeocodedArea } from '@app/app/gis/model/geocodedArea';
import { SimpleCoordinates } from '@app/app/gis/model/simplecoordinates';
import { NGXLogger } from 'ngx-logger';
import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { validateRequiredIf } from 'src/app/common/validators';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { ControllerService } from 'src/app/controller.service';
import { BaseLocationService } from 'src/app/gis/location/base.location.service';
import { GeoCodedAreaType } from 'src/app/gis/model/geocodedAreaType';
import { NeedConfig } from 'src/app/gis/model/needconfig';
import { SearchParameters } from 'src/app/gis/model/searchparameters';
import { SecondLevelNeed } from 'src/app/gis/model/secondlevelneed';
import { GisService } from 'src/app/gis/services/gis.service';
import { NeedsCacheService } from 'src/app/gis/services/needs-cache.service';
import { addDays, fromDateToNgbDateStruct, toDisplayTimeString } from 'src/app/gis/util/dateutil';
import { DateSliderComponent } from './date-slider/date-slider.component';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Flickity: any;
  }
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseSearchComponent implements OnInit, OnDestroy {
  @ViewChild('datepicker') datepicker: NgbDatepicker;
  @ViewChild('pointintimeDropdown') pointintimeDropdown: NgbDropdown;
  @ViewChild(DateSliderComponent) dateSlider: DateSliderComponent;

  public secondlevelneeds: SecondLevelNeed[] = [];
  public form: UntypedFormGroup;
  public timeForm: UntypedFormGroup;

  public visibleNeeds: NeedConfig[] = [];
  public selectedHiddenNeed: NeedConfig;
  public selectedNeedText: Observable<string> = of('');
  public hiddenNeedMode = false;

  public dates: Date[] = [];
  public selectedDate: Date = null;
  public defaultNeed = false;
  public chosenDate: Date = null;
  public dateAndTimeVisible = false;

  public allTimes = true;
  public pointintimeString = '';
  public selectLocationText = '';
  public selectSecondLevelNeedText = '';
  public searchButtonDisabled = true;
  public currentLocationAreaType: GeoCodedAreaType = GeoCodedAreaType.currentLocation;
  public searchLocationNeedId = '110';

  constructor( // NOSONAR
    private fb: UntypedFormBuilder,
    private timeFb: UntypedFormBuilder,
    @Inject(PLATFORM_ID) platformId: any,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private language: string,
    private currentDateTimeService: CurrentDateTimeService,
    protected routingService: RoutingService,
    private readonly tagManagerService: TagManagerService,
    translateExtendedService: TranslateExtendedService,
    needsService: NeedsCacheService,
    controllerService: ControllerService,
    configurationService: ConfigurationService,
    locationService: BaseLocationService,
    gisService: GisService,
    logger: NGXLogger,
    private cdr: ChangeDetectorRef
  ) {
    super(locationService, translateExtendedService, needsService, gisService, controllerService, configurationService, logger, platformId);
    this.setMinAndMaxDate();
    this.buildForm();
    this.buildTimeForm();
    this.manualUrl = this.configurationService.getConfiguration().locationPermissionManualUrl;
  }

  public get needId() {
    return this.form.get('needId')?.value;
  }

  ngOnInit() {
    super.ngOnInit();

    this.visibleNeeds = this.needs.filter((n) => n.hidden === false);

    this.loadGuiData();

    if (isPlatformBrowser(this.platformId)) {
      this.searchButtonDisabled = false;
    }

    this.form.get('needId').valueChanges.subscribe((needId) => {
      this.defaultNeed = needId === '1';
      this.needIdChanged(needId);

      /**
       * There is a chance the selected need text is too long for the select control. This function sets the translated
       * string. If it is longer than 20 chars, the select will display the full string as html title
       */
      // Emit selected need to other components
      this.controllerService.setSelectedNeedId(needId);

      this.selectedNeedText = this.needsService.getNeedById(needId).pipe(
        map((need) => {
          const needText = this.translateExtendedService.getTranslatedNeedText(need, this.language);
          return (needText.length > 20 ? needText : '');
        })
      );
    });

    this.translateExtendedService
      .getTranslationForKey('search.select-location', this.translateExtendedService.currentLang)
      .subscribe(currLocationText => this.selectLocationText = currLocationText);
    this.translateExtendedService
      .getTranslationForKey('search.secondlevelneed', this.translateExtendedService.currentLang)
      .subscribe(currLocationText => this.selectSecondLevelNeedText = currLocationText);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


  public getTranslatedNeedText(needconfig: NeedConfig): string {
    return this.translateExtendedService.getTranslatedNeedText(needconfig, this.language);
  }

  public getTranslatedSecondlevelneedText(secondLevelNeed: SecondLevelNeed) {
    return this.translateExtendedService.getTranslatedSecondlevelneedText(secondLevelNeed, this.language);
  }

  public submitSearch() {
    Object.values(this.form.controls).forEach((control) => control.markAsDirty());
    if (!this.form.valid) {
      return;
    }

    // If i had current location and i am changing to another
    // remove the useCurrentLocation
    const locationControl: AbstractControl = this.form.get('location');
    if (locationControl.value?.geocodedAreaType !== this.currentLocationAreaType) {
      this.useCurrentLocation = false;
      this.controllerService.setLocationIsActive(false);
    }
    this.search();
  }

  public onDateChange(date: Date) {
    this.selectedDate = date;
  }

  public submitTime() {
    Object.values(this.timeForm.controls).forEach((control) => control.markAsDirty());
    if (!this.timeForm.valid) {
      return;
    }

    this.chosenDate = this.selectedDate;
    this.chosenDate.setHours(this.timeForm.get('time').value.substring(0, 2));
    this.chosenDate.setMinutes(this.timeForm.get('time').value.substring(3, 5));

    this.allTimes = false;
    this.pointintimeString = formatDate(this.chosenDate, 'EEEEEE, dd.MM.yyyy HH:mm', 'de-ch');

    this.pointintimeDropdown.close();
  }

  public initFlickity(event: boolean) {
    if (event === true) {
      this.dateSlider.initFlickity();
      if (this.selectedDate === null) {
        this.selectedDate = this.dates[0];
      }
    } else {
      this.dateSlider.destroyFlickitiy();
    }
  }

  public resetForm() {
    if (this.useCurrentLocation) {
      this.useCurrentLocation = false;
      this.controllerService.setLocationIsActive(false);

      const locationControl: AbstractControl = this.form.get('location');
      locationControl.setValue('');
      locationControl.enable();
    }

    this.hiddenNeedMode = false;
    this.secondlevelneeds = this.visibleNeeds[0].secondlevelneeds;

    this.form.reset({
      needId: this.visibleNeeds[0] ? this.visibleNeeds[0].id : '',
      secondLevelNeed: this.secondlevelneeds[0] ? this.secondlevelneeds[0].id : '',
    });
    this.defaultNeed = true;

    this.buildTimeForm();

    this.controllerService.resetSearch();

    zip(this.needsService.getDefaultNeedId(), this.needsService.getDefaultSecondLevelNeedId())
      .subscribe(([defaultNeedId, defaultSecondLevelNeedId]) => {
        this.controllerService.startDefaultSearch(defaultNeedId, defaultSecondLevelNeedId);
      }
      );

    this.translateExtendedService.get('common.reset').subscribe((name) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      this.tagManagerService.click({
        event: 'button_click',
        type: 'tertiary_resetform',
        label: 'suche-zurücksetzen',
        text: name.toLowerCase(),
        additional_info: 'search'
      });
      /* eslint-enable @typescript-eslint/naming-convention */
    }
    );
  }

  public resetTimeForm() {
    this.buildTimeForm();
    this.pointintimeDropdown.close();
  }

  public resetTime() {
    this.buildTimeForm();
  }

  public getDateValidationErrorMessage(datePicker: AbstractControl): Observable<string> {
    if (datePicker.errors && datePicker.errors.ngbDate) {
      const ngbDate = datePicker.errors.ngbDate;
      if (ngbDate.invalid) {
        return this.translateExtendedService.get('search.validation.dateformat');
      }
      if (ngbDate.requiredBefore) {
        return this.translateExtendedService.get('search.validation.datenotinpast');
      }
      if (ngbDate.requiredAfter) {
        return this.translateExtendedService.get('search.validation.datenottoofarinfuture', {
          year: ngbDate.requiredAfter.year,
          month: ngbDate.requiredAfter.month,
          day: ngbDate.requiredAfter.day,
        });
      }
    }
    return of('');
  }

  // Toggles the location service on/off
  public toggleCurrentLocation() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.useCurrentLocation) {
      this.resetForm();
      return;
    }

    this.locationService.askForPermission().subscribe((locationEnabled: boolean) => {
      if (locationEnabled) {
        this.useCurrentLocation = true;
        this.controllerService.setLocationIsActive(true);

        this.translateExtendedService.get('search.currentlocation').subscribe((currLocationText) => {
          this.form.get('location').setValue(
            {
              name: currLocationText,
              geocodedAreaType: this.currentLocationAreaType
            });
          this.search();
        });
      } else {
        const alert = document.getElementById('search-component-alert');
        alert.classList.remove('collapse');
      }
    });
  }

  protected geocode(term: string): Observable<GeocodedArea[]> {
    // Only show "current location" if the input field is empty
    if (term === '') {
      return this.translateExtendedService.get('search.currentlocation').pipe(
        map((currentLocationText) => [
          {
            name: currentLocationText,
            boundingBox: new BoundingBox(),
            center: new SimpleCoordinates(),
            geocodedAreaType: GeoCodedAreaType.currentLocation,
          },
        ])
      );
    }

    let id = this.needId;

    if (this.hiddenNeedMode) {
      id = this.selectedHiddenNeed.id;
    }

    return this.gisService
      .geocodeByNeed(id, term, this.translateExtendedService.currentLang)
      .pipe(map((items: GeocodedArea[]) => this.controllerService.sortGeocodedAreas(term, items)));
  }

  /**
   * Gather all user input infos from the UI and hand them to the controller
   */
  private search() {
    let needId = this.needId;
    let secondLevelNeedId = this.form.get('secondLevelNeed').value;
    const location = this.form.get('location').value;

    if (this.hiddenNeedMode) {
      needId = this.selectedHiddenNeed.id;
      secondLevelNeedId = this.selectedHiddenNeed.secondlevelneeds[0].id;
    }

    this.needsService.getDefaultNeedId().subscribe((defaultNeedId) => {
      let locSearchStr = '';
      // no need selected -> get default
      if (!needId || needId === null) {
        needId = defaultNeedId;
      }

      const searchParameters: SearchParameters = {
        needId,
        secondLevelNeedId,
        useCurrentLocation: this.useCurrentLocation,
        fitBounds: true,
        date: this.chosenDate ? fromDateToNgbDateStruct(this.chosenDate) : null,
        time: this.chosenDate ? formatDate(this.chosenDate, 'HH:mm', 'de-ch') : null,
        openNow: this.chosenDate != null,
        accessibleByWheelChair: this.form.get('accessibleByWheelChair').value,
        resetFilter: true,
        viewportWidth: this.document.body.clientWidth,
        scrollToMap: true,
      };

      if (typeof location === 'string') {
        searchParameters.query = location;
        locSearchStr = location;
      } else {
        searchParameters.location = location;
        locSearchStr = location?.name;
      }

      this.controllerService.startSearch(searchParameters).subscribe((poiLIst) => {
        const need = this.needs.find((e) => e.id === Number(needId));
        const needNameDe = need?.nameDe;
        const secondLevelNeedNameDe = need?.secondlevelneeds.find((e) => e.id === secondLevelNeedId)?.nameDe;

        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: 'search',
          search_results: poiLIst.count,
          search_term: locSearchStr?.toLowerCase(),
          original_search_term: locSearchStr?.toLowerCase(),
          additional_info: `need=${needNameDe}&secondLevelNeed=${secondLevelNeedNameDe}&future=${!!this.chosenDate}`
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      });
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      needId: [''],
      secondLevelNeed: [''],
      pointInTime: [''],
      location: [''],
      time: ['', [Validators.pattern(/^([0-1][\d]|2[0-3]):[0-5][\d]$/), validateRequiredIf(() => this.dateAndTimeVisible)]],
      locationSelectedGeocodedArea: [null],
      accessibleByWheelChair: false,
    });
  }

  private buildTimeForm() {
    this.selectedDate = this.dates[0];
    this.chosenDate = null;
    this.allTimes = true;

    const now = this.currentDateTimeService.getCurrentDateTime();
    this.timeForm = this.timeFb.group({
      time: [
        `${toDisplayTimeString(now.getHours(), now.getMinutes(), ':')}`,
        [Validators.pattern(/^([0-1][\d]|2[0-3]):[0-5][\d]$/), validateRequiredIf(() => this.dateAndTimeVisible)],
      ],
    });

    this.form.get('time').updateValueAndValidity();
  }

  private needIdChanged(needId: number) {
    if (this.defaultNeed) {
      this.secondlevelneeds = this.visibleNeeds[0].secondlevelneeds;
      this.form.get('secondLevelNeed').setValue(this.secondlevelneeds[0].id);
    } else {
      this.needsService.getNeedById(needId).subscribe((need: NeedConfig) => {
        this.secondlevelneeds = need.secondlevelneeds;
        if (this.secondlevelneeds.length > 0) {
          this.form.get('secondLevelNeed').setValue(this.secondlevelneeds[0].id);
        }
      });
    }
    this.cdr.detectChanges();
  }

  private setMinAndMaxDate() {
    const now = this.currentDateTimeService.getCurrentDateTime();
    const numDaysToAdd = this.configurationService.getConfiguration().numDaysEnabledForOpenAt;
    if (numDaysToAdd < 1) {
      throw new Error(`Configuration value numDaysEnabledForOpenAt must be 1 or greater than 1. Current value is ${numDaysToAdd}`);
    }

    for (let i = 0; i < this.configurationService.getConfiguration().numDaysEnabledForOpenAt; i++) {
      this.dates[i] = addDays(now, i);
    }
  }

  private loadGuiData() {
    const needId = this.routingService.getNeedIdParam();

    // Fill need fields
    if (this.needs.length > 0) {
      const selectedNeed = this.needs.filter((x) => x.id.toString() === needId);
      const selectedProductNeed = this.needs.filter((n) => n.secondlevelneeds.some((s) => s.id === needId));

      if (selectedNeed.length > 0) {
        // Found by needId parameter
        if (selectedNeed[0].hidden) {
          this.hiddenNeedMode = true;
          this.selectedHiddenNeed = selectedNeed[0];
        } else {
          this.hiddenNeedMode = false;
          this.secondlevelneeds = selectedNeed[0].secondlevelneeds;
          this.form.get('needId').setValue(selectedNeed[0].id);
          this.form.get('secondLevelNeed').setValue(this.secondlevelneeds[0].id);
        }
      } else if (selectedProductNeed.length > 0) {
        // Found by secondLevelNeedId parameter
        this.secondlevelneeds = selectedProductNeed[0].secondlevelneeds;
        if (selectedProductNeed[0].hidden) {
          this.hiddenNeedMode = true;
          this.selectedHiddenNeed = selectedProductNeed[0];
        } else {
          this.hiddenNeedMode = false;
          const selectedProduct = selectedProductNeed[0].secondlevelneeds.filter((x) => x.id === needId);
          this.form.get('needId').setValue(selectedProductNeed[0].id);
          this.form.get('secondLevelNeed').setValue(selectedProduct[0].id);
        }
      } else {
        // Default
        this.hiddenNeedMode = false;
        this.defaultNeed = true;
        this.form.get('needId').setValue(this.visibleNeeds[0].id);
        this.secondlevelneeds = this.visibleNeeds[0].secondlevelneeds;
        this.form.get('secondLevelNeed').setValue(this.secondlevelneeds[0].id);
      }
    }

    this.fillTimeField();
    this.fillLocationField();
  }

  private fillTimeField() {
    const time = this.routingService.getQueryParam('time');
    if (time) {
      let date;
      if (time.toLowerCase() === 'now') {
        date = new Date();
      } else if (time.toLowerCase() === 'today16') {
        date = new Date();
        date.setHours(16);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
      } else {
        date = new Date(time);
      }

      if (!isNaN(date)) {
        this.chosenDate = date;
        this.allTimes = false;
        this.pointintimeString = formatDate(date, 'EEEEEE, dd.MM.yyyy HH:mm', 'de-ch');
      }
    }
  }

  private fillLocationField() {
    // Fill location field
    const location = this.routingService.getQueryParam('preselecttext');
    if (location) {
      let id = this.needId;

      if (this.hiddenNeedMode) {
        id = this.selectedHiddenNeed.id;
      }

      this.gisService
        .geocodeByNeed(id, location, this.translateExtendedService.currentLang)
        .pipe(map((items: GeocodedArea[]) => this.controllerService.sortGeocodedAreas(location, items)))
        .subscribe((geocodedAreas) => {
          if (geocodedAreas.length > 0) {
            this.form.get('location').setValue(geocodedAreas[0]);
          } else {
            const area = { name: location };
            this.form.get('location').setValue(area);
          }
        });
    }
  }
}
