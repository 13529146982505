import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { ToposCountdown } from '../model/toposcountdown';
import { ToposTime } from '../model/topostime';

export const fromDateToXmlDateString = (date: Date): string => {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  let isostring = utcDate.toISOString();
  isostring = isostring.substring(0, 10);
  return isostring;
};

export const fromXmlDateStringToDisplayDateString = (dateString: string): string => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  return `${day}.${month}.${year}`;
};

export const fromXmlDateStringToDate = (dateString: string): Date => {
  const yearString = dateString.substring(0, 4);
  const monthString = dateString.substring(5, 7);
  const dayString = dateString.substring(8, 10);
  /* eslint-disable radix */
  const year = parseInt(yearString);
  const month = parseInt(monthString);
  const day = parseInt(dayString);
  return new Date(year, month - 1, day);
};

export const toDisplayTimeString = (hour: number, minute: number, separator?: string): string => {
  let separatorToken = '.';
  if (separator) {
    separatorToken = separator;
  }
  let hourPart = hour.toString();
  if (hour < 10) {
    hourPart = `0${hour}`;
  }
  let minutePart = minute.toString();
  if (minute < 10) {
    minutePart = `0${minute}`;
  }
  return `${hourPart}${separatorToken}${minutePart}`;
};

export const fromToposTimeToDisplayTimeString = (time: ToposTime): string => toDisplayTimeString(time.hour, time.minute);

export const fromXmlTimeStringToDisplayTimeString = (time: string): string => {
  const topostime = fromXmlTimeStringToToposTime(time);
  return fromToposTimeToDisplayTimeString(topostime);
};

export const fromXmlTimeStringToToposTime = (timestring: string): ToposTime => {
  const hourFrom: number = parseInt(timestring.substring(0, 2));
  const minutesFrom: number = parseInt(timestring.substring(3, 6));
  return {
    hour: hourFrom,
    minute: minutesFrom,
  };
};

// Montag ist 1, So ist 7
export const getDayOfWeek = (date: Date): number => {
  const day = date.getDay();
  if (day === 0) {
    return 7;
  }
  return day;
};

export const getToposTimeAsDate = (dayWithoutTime: Date, toposTime: ToposTime): Date => {
  const date = stripTimeFromDate(dayWithoutTime);
  date.setHours(toposTime.hour);
  date.setMinutes(toposTime.minute);
  return date;
};

export const isSameDay = (date1: Date, date2: Date): boolean => {
  if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) {
    return true;
  }
  return false;
};

export const stripTimeFromDate = (currentTime: Date): Date => {
  const year = currentTime.getFullYear();
  const month = currentTime.getMonth();
  const day = currentTime.getDate();
  return new Date(year, month, day);
};

export const addDay = (date: Date): Date => addDays(date, 1);

export const addDays = (date: Date, numDays: number) => {
  const newdate = new Date(date.getTime());
  newdate.setDate(date.getDate() + numDays);
  return newdate;
};

export const getCountDown = (now: Date, till: Date): ToposCountdown => {
  const diff = till.getTime() - now.getTime();
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor(diff / (1000 * 60)) - hours * 60;

  const timeString = toDisplayTimeString(till.getHours(), till.getMinutes());

  return {
    hour: hours,
    minute: minutes,
    time: timeString,
  };
};

export const fromDateToNgbDateStruct = (date: Date): NgbDateStruct => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1,
  day: date.getDate(),
});

export const fromNgbDateStructToDate = (dateStruct: NgbDateStruct): Date => new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
