import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { CommonToposModule } from '../common/common.module';
import { DetailModule } from '../detail/detail.module';

import { DetailcontainerComponent } from './components/detailcontainer/detailcontainer.component';
import { AggregateInfoWindowComponent } from './components/map/aggregate-info-window/aggregate-info-window.component';
import { MapComponent } from './components/map/map.component';
import { PoiInfoWindowComponent } from './components/map/poi-info-window/poi-info-window.component';
import { SearchInfoMobileComponent } from './components/map/search-info-mobile/search-info-mobile.component';
import { ResultFilterComponent } from './components/result-filter/result-filter.component';
import { ResultListDeadlineComponent } from './components/result-list/result-list-deadline/result-list-deadline.component';
import { ResultListItemComponent } from './components/result-list/result-list-item/result-list-item.component';
import { ResultListComponent } from './components/result-list/result-list.component';
import { SearchComponent } from './components/search/search.component';
import { HomeComponent } from './home.component';
import { DateSliderComponent } from './components/search/date-slider/date-slider.component';

@NgModule({
  declarations: [
    HomeComponent,
    DetailcontainerComponent,
    MapComponent,
    ResultFilterComponent,
    ResultListComponent,
    ResultListItemComponent,
    ResultListDeadlineComponent,
    PoiInfoWindowComponent,
    SearchComponent,
    SearchInfoMobileComponent,
    AggregateInfoWindowComponent,
    DateSliderComponent,
  ],
  imports: [CommonModule, CommonToposModule, DetailModule, FormsModule, ReactiveFormsModule, TranslateModule, NgbModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    HomeComponent,
    DetailcontainerComponent,
    MapComponent,
    ResultFilterComponent,
    ResultListComponent,
    ResultListItemComponent,
    ResultListDeadlineComponent,
    PoiInfoWindowComponent,
    SearchComponent,
    SearchInfoMobileComponent,
    AggregateInfoWindowComponent,
  ]
})
export class HomeModule {}
