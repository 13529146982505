import { isPlatformServer } from '@angular/common';
import { Directive, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * An element marked with toposOnlyClient will only be visible (part of the dom; rendered)
 * if the application runs in the browser. In this case the element will be shown.
 */

@Directive({
    selector: '[appToposOnlyClient]'
})
export class ClientOnlyRenderDirective implements OnInit {

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        @Inject(PLATFORM_ID) private platformId: any) {}

    ngOnInit() {
        if (isPlatformServer(this.platformId) ) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
