import { Component, Input } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';
import { Service } from '@app/app/gis/model/poidetail/service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  private _poi: PoiDetail;

  constructor(private readonly tagManagerService: TagManagerService) {}

  public get poi() {
    return this._poi;
  }
  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
  }

  public trackLinkClick(event: Event, service: Service) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    /* eslint-disable @typescript-eslint/naming-convention */
    this.tagManagerService.click({
      event: 'link_click',
      link_text: service.serviceName.toLowerCase(),
      link_url: target,
      label: service.serviceNameDe.toLowerCase().replace(/\s/g, '-'),
      type: 'services_list',
      additional_info: `tab-services`
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}
