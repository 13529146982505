import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BaseLanguageService, LANG_STORAGE_KEY } from './base-language.service';

@Injectable({
    providedIn: 'root'
})
export class BrowserLanguageService extends BaseLanguageService {

    constructor(cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: any) {
        super(cookieService);
    }

    public setLanguage(language: string): string {
        const sanitizedLang = this.sanitizeLanguage(language);

        if (isPlatformBrowser(this.platformId)) {
            if (document) {
                document.documentElement.lang = sanitizedLang;
            }

            if (window && window.localStorage) {
                window.localStorage.setItem(LANG_STORAGE_KEY, sanitizedLang);
            }
        }

        this.saveLanguageInCookie(sanitizedLang);
        return sanitizedLang;
    }

    public getLanguage(): string {
        let language;
        if (isPlatformBrowser(this.platformId)) {
            language = this.getFromUrlPath(window.location.href);
            if (language) {
                return this.setLanguage(language);
            }

            language = this.getFromUrlParameter('lang', window.location.href);
            if (language) {
                return this.setLanguage(language);
            }
        }

        language = this.getFromCookie();
        if (language) {
            return this.setLanguage(language);
        }
        if (isPlatformBrowser(this.platformId)) {
            language = window.localStorage.getItem(LANG_STORAGE_KEY);
            if (language) {
                return this.setLanguage(language);
            }

            language = this.getBrowserLanguage();
        }
        language = language == null ? this.defaultLanguage : language;
        return this.setLanguage(language);
    }

    private getBrowserLanguage(): string {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return null;
        }

        const browserKey = 'browserLanguage';
        const userLanguageKey = 'userLanguage';

        let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
        browserLang = browserLang || window.navigator.language ||
            window.navigator[browserKey] || window.navigator[userLanguageKey];

        if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }

        if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }

        return this.sanitizeLanguage(browserLang);
    }
}
