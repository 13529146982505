import { BoundingBox } from '../model/boundingbox';
import { SimpleCoordinates } from '../model/simplecoordinates';

export const boundingBoxToLatLngBounds = (bbox: BoundingBox): google.maps.LatLngBounds => new google.maps.LatLngBounds(
    { lat: bbox.southWest.latitude, lng: bbox.southWest.longitude },
    { lat: bbox.northEast.latitude, lng: bbox.northEast.longitude }
);

export const toGoogleCoordinates = (coordinates: SimpleCoordinates): google.maps.LatLngLiteral => (
    { lat: coordinates.latitude, lng: coordinates.longitude }
);
