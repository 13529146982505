import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import {
  addDay,
  fromDateToXmlDateString,
  fromXmlDateStringToDisplayDateString,
  getCountDown,
  getDayOfWeek,
  isSameDay,
} from '../util/dateutil';
import { ToposCountdown } from '../model/toposcountdown';
import { DeadlinesProductAtDate } from '../model/poidetail/deadlinesproductatdate';
import { DeadlinesForProduct } from '../model/poidetail/deadlinesforproduct';

@Injectable({
  providedIn: 'root',
})
export class OpeningEventService {
  constructor(private translateService: TranslateService) {}

  public getOpeningEvent(
    isOpen: boolean,
    referenceDate: Date,
    openUntil: Date,
    openAgain: Date,
    isFutureDate: boolean = false
  ): Observable<string> {
    if (isOpen) {
      return this.getCurrentOpeningEvent(referenceDate, openUntil, openAgain, isFutureDate);
    } else {
      return this.getNextOpeningEvent(referenceDate, openAgain, isFutureDate);
    }
  }

  public getNextOpeningEvent(referenceDate: Date, eventDate: Date, isFutureDate: boolean = false): Observable<string> {
    let dateString = fromDateToXmlDateString(eventDate);
    dateString = fromXmlDateStringToDisplayDateString(dateString);
    const tomorrow = addDay(referenceDate);
    const timediff = getCountDown(referenceDate, eventDate);

    // Next opening event is today
    if (isSameDay(referenceDate, eventDate)) {
      if (isFutureDate) {
        // Searching for a future date outputs another text/time format
        return this.translateService.get('openingevent.openfuturedate', { time: timediff.time });
      } else {
        // Next opening event is today within one hour. Hours will not be displayed in this case
        if (timediff.hour === 0) {
          return this.translateService.get('openingevent.opentodaywithingonehour', { min: timediff.minute, time: timediff.time });
        } else {
          // Next opening event is today, more than one hour.
          return this.translateService.get('openingevent.opentoday', { h: timediff.hour, min: timediff.minute, time: timediff.time });
        }
      }
    }

    if (isFutureDate) {
      // Next opening date is after searched future date
      return this.translateService.get('openingevent.datefuturedate', { date: dateString, time: timediff.time });
    } else {
      // Next opening event is tomorrow
      if (isSameDay(tomorrow, eventDate)) {
        return this.translateService.get('openingevent.tomorrow', { date: dateString, time: timediff.time });
      }

      // Next opening event is after tomorrow
      return this.translateService.get('openingevent.date', { date: dateString, time: timediff.time });
    }
  }

  public getCurrentOpeningEvent(referenceDate: Date, openUntil: Date, openAgain: Date, isFutureDate: boolean = false): Observable<string> {
    // Check if poi is always open.
    if (openUntil?.getHours() === 23 && openUntil?.getMinutes() === 59 && openAgain?.getHours() === 0 && openAgain?.getMinutes() === 0) {
      return this.translateService.get('openingevent.openthroughout');
    }

    const timediff = getCountDown(referenceDate, openUntil);

    if (isFutureDate) {
      return this.translateService.get('openingevent.closesfuturedate', { time: timediff.time });
    } else {
      if (timediff.hour === 0) {
        return this.translateService.get('openingevent.closesinwithingonehour', { min: timediff.minute, time: timediff.time });
      } else {
        return this.translateService.get('openingevent.closesin', { h: timediff.hour, min: timediff.minute, time: timediff.time });
      }
    }
  }

  public getDeadlineEvent(referenceDate: Date, nextDeadline: Date, lower: boolean, isFutureDate: boolean = false): Observable<string> {
    const deadlineStillOk = isSameDay(referenceDate, nextDeadline) && referenceDate <= nextDeadline;

    if (!deadlineStillOk) {
      return this.translateService.get('deadlineevent.expired');
    }

    const timediff: ToposCountdown = getCountDown(referenceDate, nextDeadline);
    const keySuffix = lower ? '.lower' : '';

    if (isFutureDate) {
      return this.translateService.get('deadlineevent.expiresfuturedate', { time: timediff.time });
    }

    if (timediff.hour === 0) {
      return this.translateService.get(`deadlineevent.expireswhithinonehour${keySuffix}`, { min: timediff.minute, time: timediff.time });
    } else {
      return this.translateService.get(`deadlineevent.expires${keySuffix}`, {
        h: timediff.hour,
        min: timediff.minute,
        time: timediff.time,
      });
    }
  }

  public getDeadlinesProductAtDate(deadlinesForProduct: DeadlinesForProduct[], date: Date): DeadlinesProductAtDate[] {
    const deadlinesProductAtDate = [];

    deadlinesForProduct.forEach((deadlineForProduct) => {
      const relevantDeadline = deadlineForProduct.daysOpeningHoursSeperated.find((x) => x.dayId === getDayOfWeek(date));
      let timeStrings: string[];

      if (relevantDeadline) {
        timeStrings = relevantDeadline.timeStrings;
      } else {
        timeStrings = ['-'];
      }

      const entry: DeadlinesProductAtDate = {
        productTypeName: deadlineForProduct.productTypeName,
        timeStrings,
      };
      deadlinesProductAtDate.push(entry);
    });

    return deadlinesProductAtDate;
  }
}
