import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ConfigurationService } from 'src/app/config/configuration.service';
import { ServiceType } from '../model/servicetype';
import { ServiceTypesResponse } from '../model/stao-cache/types.response.type';
import { ServiceTypesCache } from '../model/types-cache.type';
import { toShortLang } from '../util/toShortLang';

@Injectable({
  providedIn: 'root',
})
export class TypesCacheService {
  private currentLang = '';
  private gisApiUrl: string;
  private typeSubject: Observable<ServiceTypesCache>;

  constructor(private http: HttpClient, private configurationService: ConfigurationService, @Inject(LOCALE_ID) private language: string) {
    this.gisApiUrl = this.configurationService.getConfiguration().gisApiUrl;
  }

  /**
   * Initilaizes or refreshes the types cache if a different language is provided
   *
   * @param lang ESRI short language string
   * @returns BehaviorSubject with the transformed ESRI response you can subscribe to
   */
  public getTypes(lang: string = ''): Observable<ServiceTypesCache> {
    const useLang = lang || toShortLang(this.language);

    // Cache strategy: check if lang is the same as before
    if (useLang !== this.currentLang || !this.typeSubject) {
      this.currentLang = useLang;
      this.typeSubject = this.requestTypes(useLang).pipe(shareReplay());
    }

    return this.typeSubject;
  }

  /**
   * You have a post id and want to know the service type, use this method
   *
   * @param id The post id for the service type
   * @param language Specify the returned language
   * @returns The matching service type or undefined
   */
  public getTypeById(id: string, language: string = ''): Observable<ServiceType> | undefined {
    return this.getTypes(language).pipe(map((cache) => cache.typesById[id]));
  }

  /**
   * You have an ESRI tag and want to know the service type, use this method
   *
   * @param tag ESRI tag
   * @param language Specify the returned language
   * @returns The matching service type or undefined
   */
  public getTypeByTag(tag: string, language: string = ''): Observable<ServiceType> | undefined {
    return this.getTypes(language).pipe(map((cache) => cache.typesByTag[tag]));
  }

  /**
   * Gets the flag text from id
   *
   * @param flag The flag id
   * @param language Specify the returned language
   * @returns The matching flag text or undefined
   */
  public getFlag(flag: string, language: string = ''): Observable<string> | undefined {
    return this.getTypes(language).pipe(map((cache) => cache.flags[flag]));
  }

  /**
   * Create indices for fast reference by property
   *
   * @param types Array of returned service types
   * @param prop Property to map service types by
   * @returns Service type index
   */
  private mapTypesBy(types: ServiceType[], prop: string = 'id'): { [key: string]: ServiceType } {
    return types.reduce((typeMap: { [key: string]: ServiceType }, type: ServiceType) => {
      typeMap[type[prop]] = type;
      return typeMap;
    }, {});
  }

  private parseTypesResponse(res: ServiceTypesResponse): ServiceTypesCache {
    return {
      ok: res.ok,
      info: res.info,
      time: res.time,
      opentmin: res.opentmin,
      opentmax: res.opentmax,
      count: res.count,
      flags: res.flags,
      typesById: this.mapTypesBy(res.types, 'id'),
      typesByTag: this.mapTypesBy(res.types, 'tag'),
      distributionTypes: res.distributionTypes,
    };
  }

  private requestTypes(language: string) {
    const url = `${this.gisApiUrl}/Types?lang=${language}`;
    return this.http.get<ServiceTypesResponse>(url).pipe(map((x) => this.parseTypesResponse(x)));
  }
}
