<app-detailcontainer *appToposOnlyClient></app-detailcontainer>

<div class="container" *appToposOnlyClient>
  <h1 class="fw-bold" translate>common.application.title</h1>
  <app-search></app-search>
</div>

<div id="results" class="results" #results *appToposOnlyClient>
  <app-result-filter #resultFilter [class.hidden]="!poiFilterVisible" *appToposOnlyClient></app-result-filter>

  <div class="result-container">
    <app-result-list [class.empty]="!listViewVisible" [class.no-results]="poiList && poiList.pois.length" *appToposOnlyClient></app-result-list>
    <app-map *appToposOnlyClient></app-map>
  </div>
</div>
