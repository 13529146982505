import { PoiClusterListItem } from '../model/poibase';

/**
 * Generate an id from coordinates and size of an ESRI cluster
 *
 * @param cluster ESRI cluster
 * @returns
 */
export const getClusterId = (cluster: PoiClusterListItem) =>
  `${cluster.coordinates.latitude}${cluster.coordinates.longitude}${cluster.pois.length}`;
