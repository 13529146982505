import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SeoService } from 'src/app/common/seo.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { ControllerService } from 'src/app/controller.service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { PoiListItem } from '@app/app/gis/model/poibase';
import { PoiDetailService } from 'src/app/gis/services/poidetail.service';
import { TagManagerService } from '@app/app/common/tag.service';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Flickity: any;
  }
}

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeDetail: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('detailTitle') detailTitle: ElementRef;
  @ViewChild('detailClose') detailClose: ElementRef;

  public poi: PoiDetail;
  public active = 1;

  private tabsInitialized = false;
  private resizeSubscription: Subscription;
  private poiSelectedSubscription: Subscription;
  private webBaseUrl: string;
  private lastScrollPosition = 0;

  constructor(
    // NOSONAR
    private readonly poiDetailService: PoiDetailService,
    private readonly controllerService: ControllerService,
    private readonly configurationService: ConfigurationService,
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly tagManagerService: TagManagerService,
    @Inject(LOCALE_ID) private readonly currentLanguage: string,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.webBaseUrl = this.configurationService.getConfiguration().webBaseUrl;
    this.poiSelectedSubscription = this.controllerService.getPoiSelectedObservable().subscribe((poi: PoiListItem) => {
      if (!poi) {
        return;
      }
      // Get poi details and wait for ng interface update (delay(0)) before
      // initializing flickity
      this.loadPoiData(poi.id);
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(_event: KeyboardEvent) {
    this.closeDetail.emit(true);
  }

  ngOnDestroy(): void {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
    if (this.poiSelectedSubscription) {
      this.poiSelectedSubscription.unsubscribe();
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.document.documentElement.classList.remove('prevent-scrolling');
    /* Check if this is an older browser, IE11, Safari Mobile and patch missing overscroll-behavior
       The prevent scrolling class will set overflow hidden on the body which scrolls the page to the top,
       we need to reset the scroll position manually after the detail gets closed
    */
    if (!('CSS' in window) || !CSS.supports('overscroll-behavior', 'contain')) {
      setTimeout(() => {
        this.document.documentElement.scrollTop = this.lastScrollPosition;
      }, 0);
    }
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.lastScrollPosition = this.document.documentElement.scrollTop;
    this.document.documentElement.classList.add('prevent-scrolling');

    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        // Only recalculate "top" after the resize is finished.
        this.tabsInitialized = false;
        const container = document.querySelector('#detail-container');
        this.initTabs(container);
      });
  }

  ngAfterViewInit(): void {
    this.detailTitle.nativeElement.focus();

    /*redirect first shift+tab to last input*/
    this.detailTitle.nativeElement.addEventListener('keydown', (e) => {
      if (e.which === 9 && e.shiftKey) {
        e.preventDefault();
        this.detailClose.nativeElement.focus();
      }
    });

    /*redirect last tab to first input*/
    this.detailClose.nativeElement.addEventListener('keydown', (e) => {
      if (e.which === 9 && !e.shiftKey) {
        e.preventDefault();
        this.detailTitle.nativeElement.focus();
      }
    });
  }

  public trackPostLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postappointmentbutton');
  }

  public trackPfLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postfinanceappointmentbutton');
  }

  public trackSpbLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.servicepointbusinessofferbutton');
  }

  public trackMp24LinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.mp24withoutdisplaymanualbutton');
  }

  public trackPfstLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postfachanlageofferbutton');
  }

  public registerCustomButtonEvent(target: string, key: string) {
    forkJoin([this.translateExtendedService.get(key), this.translateExtendedService.getGermanTranslation(key)]).subscribe(
      ([name, nameGer]) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: 'button_click',
          type: 'primary_custom',
          label: nameGer.toLowerCase().replace(/\s/g, '-'),
          text: name.toLowerCase(),
          link_url: target,
          additional_info: 'poi',
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    );
  }

  public onScroll(event: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const hasScrolled = event.target.scrollTop >= 48; // 3rem
    event.target.classList.toggle('scrolled', hasScrolled);

    this.initTabs(event.target);
  }

  public loadPoiData(poiId: string): void {
    this.poiDetailService.getPoiDetail(poiId).subscribe((poi: PoiDetail) => {
      this.poi = poi;
      this.tabsInitialized = false;

      // TOPOS-4196: christmas alert
      const today = new Date();
      const beforeChristmas = new Date('2023-12-20');
      // dates are initialized with 00:00
      const afterChristmas = new Date('2024-01-03');
      if (this.poi.typeId === '001MP24' && today > beforeChristmas && today < afterChristmas) {
        this.poi.hints.push(this.translateExtendedService.instant('detail.mp24christmas'));
      }

      if (isPlatformBrowser(this.platformId)) {
        const url = SeoService.createSeoDetailUrl(this.webBaseUrl, poiId, poi.description, this.currentLanguage);
        this.tagManagerService.change({
          event: 'page_change',
          pageId: poiId,
          pageName: poi.title,
          unifiedPageName: poi.titleDe,
          pageType: 'detail',
          unifiedURL: SeoService.createSeoDetailUrl(this.webBaseUrl, poiId, poi.descriptionDe, 'de'),
        });

        if (url !== window.location.href && history) {
          history.pushState({}, poi.title, url);
        }
      }
    });
  }

  public getFirstActiveTab(poi: PoiDetail): number {
    if (poi?.hasOpeningHours) {
      return 1;
    } else if (poi?.hasDeadlines) {
      return 2;
    } else if (poi?.hasServices) {
      return 3;
    } else if (poi?.hasAddresses) {
      return 4;
    }
  }

  public closeDialog() {
    this.closeDetail.emit(true);
  }

  private initTabs(target) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (this.tabsInitialized) {
      return;
    }
    this.tabsInitialized = true;
    // Check for IE, Source: https://stackoverflow.com/a/48182999
    const isIE = (document as any).documentMode;
    if (isIE) {
      return; // IE is not supporting position: sticky, so the new "top"-value should not be set.
    }
    const element = target as Element;

    const titleElement = element.querySelector('.sticky-title');
    const titleHeightPx = titleElement.clientHeight;

    if (titleHeightPx === 0) {
      // If element is not rendered yet
      this.tabsInitialized = false;
      return;
    }

    // This part depends on styling for the ".sticky-title"
    // - Window-Width >= 1024px: Remove 1.5rem from top (media-breakpoint-up(lg))
    // - Window-Width < 1024px: Remove 3.0rem from top (default)
    // - When scrolled, text is only 0.8 times as big
    // - Padding Top: 1.5rem, Padding Bottom: 1rem
    const pxPerRem = 16;
    const mediaBreakpointUpLgPx = 1024;

    const scaleMultiplier = 0.8;

    const titlePaddingTopRem = 1.5;
    const titlePaddingBottomRem = 1;

    const titleTopDefaultRem = 3;
    const titleTopMediaBreakpointUpLgRem = 1.5;

    // Seperate padding from text height
    const titlePaddingPx = (titlePaddingBottomRem + titlePaddingTopRem) * pxPerRem;
    const titleTextHeightPx = titleHeightPx - titlePaddingPx;

    // Calculate the height of the scaled text
    // Round to full pixel, to avoid blurry lines
    const titleTextHeightScaledPx = Math.round(titleTextHeightPx * scaleMultiplier);

    // Calculate the "top" attribute of the title (depends on window size)
    const windowWidthPx = window.innerWidth;
    const titleTopModifierPx = (windowWidthPx < mediaBreakpointUpLgPx ? titleTopDefaultRem : titleTopMediaBreakpointUpLgRem) * pxPerRem;

    // Calculate effective height of the title element
    const titleHeightCssAdapted = titleTextHeightScaledPx + titlePaddingPx - titleTopModifierPx;

    // Set the "top" attribute of the tabset-list to the calculated value
    const tabsetList = element.querySelector('post-tabs').shadowRoot.querySelector('div[role="tablist"]');
    tabsetList.setAttribute('style', 'top: ' + titleHeightCssAdapted + 'px;');
  }
}
