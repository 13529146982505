interface CustomMarker extends google.maps.OverlayView {
  imageUrl?: string;
}

export const createLocationMarker = (position: any, accuracyInPixels: number, imageUrl: string) => {
  const customMarker: CustomMarker = new google.maps.OverlayView();
  customMarker.set('position', position);
  customMarker.imageUrl = imageUrl;

  customMarker.onAdd = function customMarkerAdd() {
    this.div = document.createElement('div');
    this.div.className = 'current-location-div';

    const img = document.createElement('img');
    img.className = 'current-location-image';
    img.src = this.imageUrl;
    this.div.appendChild(img);

    const pulseDiv = document.createElement('div');
    pulseDiv.className = 'pulse';
    this.div.appendChild(pulseDiv);

    if (accuracyInPixels <= 28) {
      accuracyInPixels = 28;
    }
    document.documentElement.style.setProperty('--pulse-size', accuracyInPixels * 2 + 'px');

    const panes = this.getPanes();
    panes.overlayLayer.appendChild(this.div);
    panes.overlayLayer.style['zIndex'] = 200;
  };

  customMarker.draw = function customMarkerDraw() {
    const pos = this.get('position');
    const curPosition = new google.maps.LatLng(pos.latitude, pos.longitude);
    const point = this.getProjection().fromLatLngToDivPixel(curPosition);

    if (point) {
      this.div.style.left = point.x + 'px';
      this.div.style.top = point.y + 'px';
    }
  };

  customMarker.onRemove = function customMarkerRemove() {
    if (this.div) {
      (this.div.parentNode as HTMLElement).removeChild(this.div);
      delete this.div;
    }
  };

  return customMarker;
};
