import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';

import { Observable, of, zip } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ControllerService } from 'src/app/controller.service';
import { NeedsCacheService } from 'src/app/gis/services/needs-cache.service';

@Component({
  selector: 'app-search-info-mobile',
  templateUrl: './search-info-mobile.component.html',
  styleUrls: ['./search-info-mobile.component.scss'],
})
export class SearchInfoMobileComponent implements OnInit {
  public need: Observable<string>;
  public time: Observable<string>;
  public query: Observable<string>;
  public useCurrentLocation: Observable<boolean> = of(false);
  public showSearchInfo = false;

  constructor(
    @Inject(LOCALE_ID) private language: string,
    private controllerService: ControllerService,
    private needsCache: NeedsCacheService,
    private readonly translateExtendedService: TranslateExtendedService,
    @Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    const searchParameters$ = zip(this.controllerService.getSearchParameters(), this.controllerService.getSearchObservable()).pipe(
      tap(([params, pois]) => (this.showSearchInfo = !!params && !!pois)),
      filter(([params]) => params !== null),
      map(([params]) => params)
    );

    this.need = searchParameters$.pipe(
      switchMap((params) => this.needsCache.getNeedById(params.needId)),
      map((needConfig) => this.translateExtendedService.getTranslatedNeedText(needConfig, this.language))
    );

    this.useCurrentLocation = searchParameters$.pipe(map((params) => params.useCurrentLocation));

    this.query = searchParameters$.pipe(map((params) => params.query || 'map.searchinfo.currentmapview'));

    this.time = searchParameters$.pipe(
      map((params) => {
        if (params.date || params.time) {
          return `${params.date.day}.${params.date.month}.${params.date.year} ${params.time}`;
        }
        return this.translateExtendedService.instant('common.pointintime.alltasks');
      })
    );
  }

  scrollToSearch() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    try {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } catch {
      document.documentElement.scrollTop = 0;
    }
  }
}
