<div>
  <div class="py-4">
    <h3 class="h5 fw-bold mb-3 mt-0" translate>detail.partners.offers</h3>

    <div class="row">
      <div class="col-12 masonry-container">
        <div class="masonry-item" *ngFor="let serviceGroup of poi?.partnerServiceGroups">
          <div class="time-item font-curve-small">
            <img class="mb-2" [src]="getImgSrc(serviceGroup.groupNameDe)" [alt]="serviceGroup.groupName"
              [height]="getImgHeight(serviceGroup.groupNameDe)">
            <h4 class="fw-bold font-curve-small mb-0">{{serviceGroup.groupName}}</h4>
            <ul class="services-list">
              <li *ngFor="let service of serviceGroup.services">
                <span *ngIf="!service.url">{{service.serviceName}}</span>
                <a *ngIf="service.url" [href]="service.url" target="_blank"
                  (click)="trackLinkClick($event, service)">{{service.serviceName}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>