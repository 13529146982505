import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ControllerService } from 'src/app/controller.service';
import { PoiList } from '@app/app/gis/model/poilist';
import { BaseResultListComponent } from '@app/app/base/components/base-result-list.component';
import { TranslateExtendedService } from '@app/app/common/translate-extended.service';
import { TagManagerService } from '@app/app/common/tag.service';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss'],
})
export class ResultListComponent extends BaseResultListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('resultlist') resultListElement: ElementRef;

  public poiList: PoiList;
  public showPleaseZoom = false;
  public showNoResults = false;
  public noPoisOnMap = false;
  public resultCount: Observable<number>;
  public resultCountObserver: IntersectionObserver;
  public scrollListener: EventListener;

  constructor(protected controllerService: ControllerService,
    protected readonly tagManagerService: TagManagerService,
    protected readonly translateExtendedService: TranslateExtendedService,
    @Inject(PLATFORM_ID) platformId: any) {
    super(controllerService, platformId);
  }

  editSearch() {
    this.scrollToSearch();

    this.translateExtendedService.get('resultlist.editsearch').subscribe((name) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      this.tagManagerService.click({
        event: 'button_click',
        type: 'primary_editsearch',
        label: 'suche-anpassen',
        text: name.toLowerCase(),
        additional_info: 'result-list'
      });
      /* eslint-enable @typescript-eslint/naming-convention */
    }
    );
  }

  expandSearchClick() {
    this.expandSearch();

    this.translateExtendedService.get('resultlist.expandsearch').subscribe((name) => {
      /* eslint-disable @typescript-eslint/naming-convention */
      this.tagManagerService.click({
        event: 'button_click',
        type: 'primary_expandsearch',
        label: 'suchradius-vergrössern',
        text: name.toLowerCase(),
        additional_info: 'result-list'
      });
      /* eslint-enable @typescript-eslint/naming-convention */
    }
    );
  }

  showResult(poiList: PoiList) {
    this.showPleaseZoom = poiList.aggregates && poiList.aggregates.length > 0;
    this.noPoisOnMap = poiList.aggregates.length === 0 && poiList.pois.length === 0 && poiList.extent !== null;
    this.showNoResults = poiList.extent === null;
    this.poiList = poiList;

    if (this.poiList.count === 0 && this.zoomLevel <= 7) {
      this.noPoisOnMap = false;
      this.showNoResults = true;
      this.isExpanding = false;
    } else if (this.poiList.count === 0 && this.isExpanding) {
      this.expandSearch();
    } else {
      this.isExpanding = false;
    }
  }
}
