import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { BaseLocationService } from 'src/app/gis/location/base.location.service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { MapService } from 'src/app/gis/services/map.service';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { TagManagerService } from '@app/app/common/tag.service';

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.scss'],
})
export class StaticMapComponent implements OnChanges, OnDestroy {
  @Input() poi: PoiDetail;
  @ViewChild('mapImage', { static: true }) mapImage: ElementRef;

  public mapImageSrc = '';
  public markerImageSrc = '';
  public directionsUrl = '';
  private resizeEvent = new Subject<void>();

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private mapService: MapService,
    private locationService: BaseLocationService,
    private translateExtendedService: TranslateExtendedService,
    private logger: NGXLogger,
    private readonly tagManagerService: TagManagerService
  ) {
    this.resizeEvent.pipe(throttleTime(200)).subscribe(() => {
      this.getMapImage(this.poi);
    });
  }

  @HostListener('window:resize')
  public onWindowResize() {
    this.resizeEvent.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getDirectionsLink();
    this.getMapImage(changes.poi.currentValue);
  }

  ngOnDestroy() {
    this.mapImageSrc = '';

    if (this.resizeEvent) {
      this.resizeEvent.unsubscribe();
    }
  }

  public trackCalculateRouteClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;

    this.translateExtendedService.get('detail.map.calculateRoute').subscribe((calRoute) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: 'button_click',
          type: 'primary_map',
          label: 'route-berechnen',
          text: calRoute.toLowerCase(),
          link_url: target,
          additional_info: 'poi'
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    );
  }

  private getGoogleMapsApiKey(): string {
    return this.configurationService.getConfiguration().googleMapsApiKey;
  }

  private getMapImage(poi: PoiDetail) {
    if (!poi) {
      return;
    }

    const img = this.mapImage.nativeElement;
    let width = Math.ceil(img.clientWidth);
    let height = Math.ceil(img.clientHeight);

    // Don't request invisible map
    if (isNaN(width)) {
      width = 584;
    }
    if (isNaN(height)) {
      height = 350;
    }
    const API_SIGN_URL = `${this.configurationService.getConfiguration().webBaseUrl}/signstaticmap`;
    const API_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap';
    const API_KEY = `key=${this.getGoogleMapsApiKey()}`;
    const zoom = 'zoom=17';
    const center = `center=${poi.coordinates.latitude},${poi.coordinates.longitude}`;

    this.configurationService.getMapStyleQueryString().subscribe((styles) => {
      if (height === 0 && width === 0) {
        return;
      }

      const size = `size=${width}x${height}`;
      const params = [zoom, center, size, API_KEY, styles].join('&');
      const url = `${API_BASE_URL}?${params}`;

      if (this.configurationService.getConfiguration().env !== 'local') {
        const observable = this.http.post(API_SIGN_URL, { url }, { responseType: 'text' });

        observable.subscribe((result) => {
          this.mapImageSrc = result;
        });
      } else {
        this.mapImageSrc = url;
      }
    });

    this.markerImageSrc = this.mapService.markerUrl(poi);
  }

  private getDirectionsLink() {
    const baseUrl = 'https://www.google.ch/maps/dir/?api=1&';

    this.locationService.getLocation().subscribe((currentLocation) => {
      if (!this.poi) {
        return;
      }

      const destination = `destination=${this.poi.coordinates.latitude},${this.poi.coordinates.longitude}`;
      let location = '';
      if (currentLocation) {
        location = `&origin=${currentLocation.latitude},${currentLocation.longitude}`;
      }
      this.directionsUrl = `${baseUrl}${destination}${location}`;
    });
  }
}
