import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';

import { NgbDateStruct, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ControllerService } from 'src/app/controller.service';
import { CounterState } from 'src/app/gis/model/poidetail/counterstate';
import { OpeningHoursCounterAtDate } from 'src/app/gis/model/poidetail/openinghourscounteratdate';
import { OpeningHoursNextDaysSingeDay } from 'src/app/gis/model/poidetail/openinghoursnextdayssingleday';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { SearchParameters } from 'src/app/gis/model/searchparameters';
import { OpeningEventService } from 'src/app/gis/services/opening-event.service';
import { fromNgbDateStructToDate, isSameDay } from 'src/app/gis/util/dateutil';
import { AccordionTrackerComponent } from '../accordion-tracker.component';

@Component({
  selector: 'app-openinghours',
  templateUrl: './openinghours.component.html',
  styleUrls: ['./openinghours.component.scss'],
})
export class OpeninghoursComponent extends AccordionTrackerComponent implements OnInit, OnDestroy {
  public showOpeningHoursToday: boolean;
  public showOpeningHoursAtDate: boolean;

  public openingHoursAtTitle: string;
  public openingHoursAtHoliday: string;
  public openingHoursAtCounters: OpeningHoursCounterAtDate[] = [];

  private intervalSubscripton: Subscription;

  private _poi: PoiDetail;

  constructor(
    private currentDateTimeService: CurrentDateTimeService,
    private openingEventService: OpeningEventService,
    private controllerService: ControllerService,
    protected readonly translateExtendedService: TranslateExtendedService,
    protected readonly tagManagerService: TagManagerService
  ) {
    super(translateExtendedService, tagManagerService);
  }

  public get poi() {
    return this._poi;
  }
  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
    this.initPoiData();
  }

  public trackPanelChange(change: any) {
    super.internalTrackPanelChange('tab-öffnungszeiten', change.nextState, 'detail.hours.nextdays');
  }

  ngOnDestroy(): void {
    if (this.intervalSubscripton) {
      this.intervalSubscripton.unsubscribe();
    }
  }

  ngOnInit() {
    this.intervalSubscripton = this.controllerService.getUpdateDataInterval().subscribe(() => this.updateFields());
  }

  private initPoiData() {
    this.showOpeningHoursAtDate = false;
    this.showOpeningHoursToday = true;

    if (this.controllerService.getSearchParameters().value) {
      const searchparams: SearchParameters = this.controllerService.getSearchParameters().value;
      if (searchparams.date) {
        this.initOpenAtSpecificDate(searchparams.date);
      }
    }
    this.updateFields();
  }

  private initOpenAtSpecificDate(dateStruct: NgbDateStruct) {
    this.showOpeningHoursAtDate = true;
    this.showOpeningHoursToday = false;

    this.translateExtendedService
      .get('detail.hours.openat', { date: `${dateStruct.day}.${dateStruct.month}.${dateStruct.year}` })
      .subscribe((title) => {
        this.openingHoursAtTitle = title;
      });

    this.openingHoursAtCounters = [];
    const date = fromNgbDateStructToDate(dateStruct);
    const openingHoursAtThisDay: OpeningHoursNextDaysSingeDay = this.poi.openingHoursNextDays.nextDays.find((x) => isSameDay(x.date, date));
    if (openingHoursAtThisDay) {
      for (let i = 0; i < this.poi.openingHoursNextDays.counterTypes.length; i++) {
        const entry: OpeningHoursCounterAtDate = {
          counterTypeName: this.poi.openingHoursNextDays.counterTypes[i],
          timeStrings: openingHoursAtThisDay.counters[i].timeStrings,
        };
        this.openingHoursAtCounters.push(entry);
      }

      this.openingHoursAtHoliday = openingHoursAtThisDay.holidayName;
    }
  }

  private updateFields() {
    if (this.poi) {
      for (const counterState of this.poi.counterStates) {
        this.updateCounterState(counterState);
      }
    }
  }

  private updateCounterState(counterState: CounterState) {
    // Case where we just don't know if the counter is open or closed.
    if (!counterState.openUntil && !counterState.openAgain) {
      counterState.openNow = null;
      return;
    }

    // check if counter is open or closed.
    const now = this.currentDateTimeService.getCurrentDateTime();
    if (counterState.openUntil && now <= counterState.openUntil) {
      counterState.openNow = true;
    } else {
      counterState.openNow = false;
    }

    // get text for open closed events
    this.openingEventService
      .getOpeningEvent(counterState.openNow, now, counterState.openUntil, counterState.openAgain)
      .subscribe((translation) => {
        counterState.timeString = translation;
      });
  }
}
