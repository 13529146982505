import { BoundingBox } from './boundingbox';
import { clusterToAggregate } from '../util/clusterToAggregate';
import { flattenPois } from '../util/flattenPois';
import { PoiClusterListItem, PoiListAggregate, PoiListItem } from './poibase';

export class PoiList {
  extent: BoundingBox = null;
  count = 0;
  aggregates: PoiListAggregate[] = [];
  pois: Array<PoiListItem | PoiClusterListItem> = [];

  get poisOnly(): PoiListItem[] {
    const pois = this.pois.filter((poi) => 'id' in poi);
    return pois as PoiListItem[];
  }

  get clustersOnly(): PoiClusterListItem[] {
    const clusters = this.pois.filter((poi) => 'pois' in poi);
    return clusters as PoiClusterListItem[];
  }

  get flattenedPois(): PoiListItem[] {
    return flattenPois(this.pois);
  }

  get clustersAsAggregates(): PoiListAggregate[] {
    return this.clustersOnly.map((cluster) => clusterToAggregate(cluster));
  }

  /**
   * Clusters pois according to the clusterThreshold parameter
   *
   * @param clusterThreshold maximum cluster size (0 = always cluster)
   * @returns PoiListItem array
   */
  optimizedPois(clusterThreshold: number): PoiListItem[] {
    if (clusterThreshold === 0) {
      return flattenPois(this.pois.filter((poi) => 'id' in poi || poi.pois.length));
    } else {
      return flattenPois(this.pois.filter((poi) => 'id' in poi || poi.pois.length < clusterThreshold));
    }
  }

  /**
   * Shows big clusters as aggregates according to the clusterThreshold parameter
   *
   * @param clusterThreshold minimal amount of pois to form an aggregate (0 = no aggregates)
   * @returns PoiListAggregate array
   */
  optimizedClustersAsAggregates(clusterThreshold: number): PoiListAggregate[] {
    if (clusterThreshold === 0) {
      return [];
    } else {
      return this.clustersOnly
      .filter((cluster) => cluster.pois.length >= clusterThreshold)
      .map((cluster) => clusterToAggregate(cluster));
    }
  }
}
