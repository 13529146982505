import { isPlatformServer } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TagManagerService } from '@app/app/common/tag.service';
import { forkJoin } from 'rxjs';

import { SeoService } from 'src/app/common/seo.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { HeaderConfigService } from 'src/app/config/header-config.service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { DetailComponent } from '../detail/detail.component';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss'],
})
export class DetailPageComponent implements OnInit {
  @ViewChild('detailComponent', { static: true })
  public detailComponent: DetailComponent;

  public poi: PoiDetail;

  private supportedLanguages = ['it', 'de', 'en', 'fr'];
  private webBaseUrl: string;

  constructor(
    // NOSONAR
    private activatedRoute: ActivatedRoute,
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly seoService: SeoService,
    private readonly configurationService: ConfigurationService,
    private readonly headerConfigService: HeaderConfigService,
    private readonly tagManagerService: TagManagerService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(LOCALE_ID) private readonly currentLanguage: string
  ) {
    this.webBaseUrl = this.configurationService.getConfiguration().webBaseUrl;
  }

  ngOnInit(): void {
    this.poi = this.activatedRoute.snapshot.data['poi'];

    // TOPOS-4196: christmas alert
    const today = new Date();
    const beforeChristmas = new Date('2023-12-20');
    // dates are initialized with 00:00
    const afterChristmas = new Date('2024-01-03');
    if (this.poi.typeId === '001MP24' && today > beforeChristmas && today < afterChristmas) {
      this.poi.hints.push(this.translateExtendedService.instant('detail.mp24christmas'));
    }

    this.setSeoInfo(this.poi);
  }

  public trackPostLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postappointmentbutton');
  }

  public trackPfLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postfinanceappointmentbutton');
  }

  public trackSpbLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.servicepointbusinessofferbutton');
  }

  public trackMp24LinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.mp24withoutdisplaymanualbutton');
  }

  public trackPfstLinkClick(event: Event) {
    const target = (event.currentTarget as HTMLAnchorElement).href;
    this.registerCustomButtonEvent(target, 'detail.postfachanlageofferbutton');
  }

  public registerCustomButtonEvent(target: string, key: string) {
    forkJoin([this.translateExtendedService.get(key), this.translateExtendedService.getGermanTranslation(key)]).subscribe(
      ([name, nameGer]) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: 'button_click',
          type: 'primary_custom',
          label: nameGer.toLowerCase().replace(/\s/g, '-'),
          text: name.toLowerCase(),
          link_url: target,
          additional_info: 'poi',
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    );
  }

  private setSeoInfo(poiDetail: PoiDetail) {
    const cleanTypeId = poiDetail.typeId.replace('-', '');
    const title = poiDetail.title;
    const languageUrls = this.supportedLanguages.map((key) => ({
      lang: key,
      url: SeoService.createSeoDetailUrl(this.webBaseUrl, poiDetail.id, poiDetail.description, key),
    }));

    if (title && isPlatformServer(this.platformId)) {
      this.seoService.setTitle(title);
      this.seoService.setOgTitle(title);
      this.seoService.setLangLinks(languageUrls, this.currentLanguage);

      const description = this.translateExtendedService.instant(`detail.seo.description.${cleanTypeId}`, {
        street: poiDetail.street,
        city: poiDetail.city,
        zip: poiDetail.zip,
        description: poiDetail.title,
      });
      if (description && description !== `detail.seo.description.${cleanTypeId}`) {
        this.seoService.setDescription(description);
        this.seoService.setOgDescription(description);
      }

      const keywords = this.translateExtendedService.instant(`detail.seo.keywords.${cleanTypeId}`);
      if (keywords && keywords !== `detail.seo.keywords.${cleanTypeId}`) {
        this.seoService.setKeywords(keywords === `detail.seo.keywords.${cleanTypeId}` ? '' : keywords);
      }

      this.seoService.setRobots();
    }

    this.tagManagerService.view({
      event: 'page_context',
      pageId: poiDetail.id,
      pageName: poiDetail.title,
      unifiedPageName: poiDetail.titleDe,
      pageType: 'detail',
      unifiedURL: SeoService.createSeoDetailUrl(this.webBaseUrl, poiDetail.id, poiDetail.descriptionDe, 'de'),
    });

    this.headerConfigService.writeHeaderConfiguration(languageUrls);
  }
}
