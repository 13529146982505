import { addDay, fromXmlTimeStringToToposTime, getDayOfWeek, getToposTimeAsDate, stripTimeFromDate } from './dateutil';

export const getNextDeadline = (input: any[], date: Date): Date => {
  let dateCursor = stripTimeFromDate(date);

  // ganze Woche ausprobieren
  for (let i = 0; i < 7; i++) {
    const dateWithTime = getDeadLine(input, dateCursor);
    if (dateWithTime) {
      if (dateWithTime.getTime() >= date.getTime()) {
        return dateWithTime;
      }
    }
    dateCursor = addDay(dateCursor);
  }

  return null;
};

const getDeadLine = (deadlineArray: any[], date: Date): Date => {
  const dayOfWeek = getDayOfWeek(date).toString();
  const deadlineTodayObj = deadlineArray.find((deadline) => deadline.Day._text && deadline.Day._text === dayOfWeek);

  if (deadlineTodayObj) {
    const latestTimeText = deadlineTodayObj.LatestTime._text;
    const topostime = fromXmlTimeStringToToposTime(latestTimeText);
    return getToposTimeAsDate(date, topostime);
  }
  return null;
};
