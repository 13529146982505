import { isPlatformServer } from '@angular/common';
import { Directive, Inject, InjectionToken, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';

export const IS_BOT = new InjectionToken<boolean>('isBot');
/**
 * An element marked with toposOnlyClientAndBot will only be visible (part of the dom; rendered)
 * if the application runs in the browser or if the requestor is a bot. In this case the element will be shown anyway, also on ssr.
 */

@Directive({
    selector: '[appToposOnlyClientAndBot]'
})
export class ClientAndBotOnlyRenderDirective implements OnInit {
    constructor(
        private readonly viewContainer: ViewContainerRef,
        private readonly templateRef: TemplateRef<any>,
        @Inject(IS_BOT) private readonly isBot: boolean,
        @Inject(PLATFORM_ID) private readonly platformId: any) {}

    ngOnInit() {
        if (isPlatformServer(this.platformId) && !this.isBot) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
