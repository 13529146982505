<div class="app-search-component row justify-content-center">
  <form class="col-xl-12" [formGroup]="form" #formDir="ngForm" (ngSubmit)="submitSearch()">
    <div class="row">

      <!-- Needs select -->
      <div class="col-md-6 custom-row order-1 mb-md-0 mb-3" *ngIf="!hiddenNeedMode">
        <div class="mb-0 form-floating" [ngClass]="defaultNeed ? 'custom-w-100' : 'custom-flex-50'">
          <select id="search-component-needs" class="form-select form-select-lg pe-big"
            [ngClass]="{'custom-select-border1': !defaultNeed}" formControlName="needId" required
            [title]="selectedNeedText | async">
            <option *ngFor="let need of visibleNeeds" [value]="need.id">
              {{ getTranslatedNeedText(need) }}
            </option>
          </select>
          <label class="form-label z-index-4" for="search-component-needs" translate>search.selectneed</label>
        </div>
        <div class="position-relative" [ngClass]="defaultNeed ? 'w-0': 'custom-flex-50'">
          <div id="borderLeft" [ngClass]="defaultNeed ? 'd-none': 'd-block'"></div>
          <div class="mb-regular form-floating" [ngClass]="defaultNeed ? 'd-none': 'd-block'">
            <select *ngIf="secondlevelneeds.length !== 1" id="search-component-secondlevelneed"
              class="custom-select-border2 form-select form-select-lg pe-big" formControlName="secondLevelNeed"
              required>
              <option *ngFor="let secondlevelneed of secondlevelneeds" [value]="secondlevelneed.id">
                {{ getTranslatedSecondlevelneedText(secondlevelneed) }}
              </option>
            </select>
            <div *ngIf="secondlevelneeds.length === 1"
              class="form-select custom-search-control-div custom-select-border2 bg-image-unset">
              <label class="form-label custom-search-control-label">{{
                getTranslatedSecondlevelneedText(secondlevelneeds[0])
                }}</label>
            </div>
            <label *ngIf="needId && needId === searchLocationNeedId" class="form-label z-index-4"
              for="search-component-secondlevelneed">{{selectLocationText}}</label>
            <label *ngIf="needId && needId !== searchLocationNeedId" class="form-label z-index-4"
              for="search-component-secondlevelneed">{{selectSecondLevelNeedText}}</label>
          </div>
        </div>
      </div>

      <!-- Hiden need -->
      <div class="col-md-6 custom-row order-1" *ngIf="hiddenNeedMode">
        <div class="mb-regular position-relative form-floating w-100">
          <div class="hidden-need custom-search-control-div form-select">
            <label class="form-label custom-search-control-label" translate>{{ getTranslatedNeedText(selectedHiddenNeed) }}</label>
          </div>
          <label class="form-label" for="search-component-secondlevelneed"
            translate>search.yourneed</label>
        </div>
      </div>

      <!-- Point in time select -->
      <div class="col-md-3 col-sm-12 order-2 order-md-2 order-sm-last mb-3">
        <div class="position-relative">
          <div #pointintimeDropdown="ngbDropdown" ngbDropdown placement="bottom-left"
            (openChange)="initFlickity($event)" class="form-floating">
            <button id="search-component-pointintime" type="button"
              class="custom-search-control-div form-select form-select-lg" ngbDropdownToggle>
              <label *ngIf="allTimes" class="form-label custom-search-control-label"
                translate>common.pointintime.alltasks</label>
              <label *ngIf="!allTimes" class="form-label custom-search-control-label">{{pointintimeString}}</label>
            </button>
            <label class="form-label" for="search-component-pointintime" translate>search.pointintime</label>

            <div ngbDropdownMenu aria-labelledby="search-component-pointintime"
              class="search-component-pointintime-dropdown">
              <form class="p-3" [formGroup]="timeForm" (ngSubmit)="submitTime()">

                <!-- Date -->
                <label translate>search.label.date</label>
                <app-date-slider [dates]="dates" [value]="selectedDate"
                  (dateChange)="onDateChange($event)"></app-date-slider>

                <!-- Time -->
                <div class="mb-regular">
                  <label class="form-label" for="search-component-time" translate>search.label.time</label>
                  <input type="text" id="search-component-time" class="form-control" placeholder="Zeit"
                    formControlName="time"
                    [class.is-invalid]="timeForm.get('time').invalid && timeForm.get('time').dirty">
                  <label for="search-component-time" class="form-label invalid-feedback">
                    <span *ngIf="timeForm.get('time').errors?.required" translate>search.validation.required</span>
                    <span *ngIf="timeForm.get('time').errors?.pattern" translate>search.validation.timeformat</span>
                  </label>
                </div>

                <div class="mb-regular position-relative mb-2 pt-3 text-center">
                  <button type="button" class="btn btn-link btn-rg" (click)="resetTimeForm()">{{'common.reset' |
                    translate}}</button>
                  <button type="submit" class="btn btn-primary btn-rg" translate>common.confirm</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 order-3 mb-3 mb-md-0">

        <!-- PLZ/Place freetext -->
        <div class="location-control form-floating">
          <ng-template #resultTemplatePlzPlace let-r="result" let-t="term">
            <!-- Show crosshair icon with current location item -->
            <post-icon name="2029" *ngIf="r.geocodedAreaType === currentLocationAreaType"></post-icon>
            {{r.name}}
          </ng-template>

          <input formControlName="location" id="search-component-zip" class="form-control"
            [ngbTypeahead]="autocompletePlzPlace" [resultTemplate]="resultTemplatePlzPlace"
            [inputFormatter]="formatterPlzPlace" [focusFirst]="false" (focus)="showAutocomplete($event)"
            (selectItem)="selectedPlzPlace($event)" [placeholder]="'search.location' | translate"/>
          <label class="form-label" for="search-component-zip" translate>search.location</label>

          <!-- Current location -->
          <button type="button" class="btn btn-default btn-icon btn-lg location-control-button"
            (click)="onCurrentLocationClick($event)">
            <post-icon name="2029" scale="1.4" *ngIf="!useCurrentLocation"></post-icon>
            <post-icon name="2043" scale="1.4" *ngIf="useCurrentLocation"></post-icon>
            <span class="visually-hidden" translate>search.usecurrentlocation</span>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-2 mb-4 d-flex align-items-center search-submit-row">
      <button type="submit" [disabled]="searchButtonDisabled" class="btn btn-primary btn-animated search-button"><span
          translate>common.search</span></button>
      <button type="button" *ngIf="form.dirty || selectedDate !== null" class="btn btn-link reset-button btn-icon"
        (click)="resetForm()"><post-icon name="2042"></post-icon> {{'common.reset' | translate}}</button>
    </div>
  </form>
</div>


<div role="alert" id="search-component-alert" class="alert alert-fixed-bottom alert-action collapse">
  <div class="search-component-alert__content d-flex align-items-center">
    <post-icon name="3137" class="fs-huge"></post-icon>
    <div class="search-component-alert__content__main d-flex flex-column">
      <h4 class="alert-heading" translate>search.alert.location.title</h4>
      <p class="font-size-17 m-0" translate>
        search.alert.location.message
      </p>
    </div>
  </div>
  <div class="alert-buttons">
    <button class="btn btn-animated btn-notnow btn-secondary" type="button" (click)="hideFixedAlert()">
      <span translate>search.alert.location.notnow</span>
    </button>
    <button class="btn btn-primary btn-animated" type="button" (click)="openManual()">
      <span translate>search.alert.location.manual</span>
    </button>
  </div>
</div>