import { Inject, Injectable } from '@angular/core';
import { ToposConfiguration } from '../common/model/toposconfiguration';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay, map } from 'rxjs/operators';
import { EnvConfigService } from './env-config.service';
import { configuraton } from './configuration';
import { WINDOW } from '../common/window.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private _mapStylesCache: Observable<any[]> = null;
  private toposConfiguration: ToposConfiguration;

  constructor(private httpClient: HttpClient, private envConfigService: EnvConfigService, @Inject(WINDOW) private window: Window) {
    this.toposConfiguration = { ...configuraton, ...this.envConfigService.getConfig() };
  }

  getConfiguration(): ToposConfiguration {
    return this.toposConfiguration;
  }

  /**
   * Get google maps styles and cache the response for the duration of the session
   */
  getMapStyles(): Observable<google.maps.MapTypeStyle[]> {
    /**
     * defaultstyle.json can be supplied using 2 methods:
     * - cached HTTP response (default)
     * - inlined javascript object (used for external integration)
     */

    if (this.window['__SWISSPOST_TOPOS_DEFAULTSTYLE__'] !== undefined) {
      this._mapStylesCache = of(this.window['__SWISSPOST_TOPOS_DEFAULTSTYLE__']);
    }

    if (!this._mapStylesCache) {
      const url = `${this.toposConfiguration.webBaseUrl}/assets/map/styles/defaultstyle.json`;
      this._mapStylesCache = this.httpClient.get<google.maps.MapTypeStyle[]>(url).pipe(shareReplay(1));
    }

    return this._mapStylesCache;
  }

  /**
   * Transform the map style json into a query string like format
   */
  getMapStyleQueryString(): Observable<string> {
    return this.getMapStyles().pipe(map((styles) => this.queryStringStyles(styles)));
  }

  // Parse style settings as query string
  // https://developers.google.com/maps/documentation/maps-static/styling
  private queryStringStyles(styleSet: any[]): string {
    return styleSet
      .reduce((qa, rule) => {
        let q = 'style=';
        if (rule.featureType) {
          q += `feature:${rule.featureType}|`;
        }

        if (rule.elementType) {
          q += `element:${rule.elementType}|`;
        }

        if (rule.stylers) {
          q += rule.stylers
            .map((style) =>
              Object.entries(style)
                .map(([key, value]) => {
                  // Colors need to be hex values
                  value = value.toString().replace('#', '0x');
                  return `${key}:${encodeURIComponent(value as any)}`;
                })
                .join('|')
            )
            .join('|');
        }
        qa.push(q);
        return qa;
      }, [])
      .join('&');
  }
}
