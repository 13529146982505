/// <reference types="node" />
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

import { NGXLogger } from 'ngx-logger';

import config from '../../environments/config/env.json';
import { WINDOW } from '../common/window.service';

export const ENV_BASE_URL = new InjectionToken<string>('envBaseUrl');

@Injectable({
  providedIn: 'root',
})
export class EnvConfigService {
  private envConfig: any;

  constructor(
    private logger: NGXLogger,
    @Inject(WINDOW) private window: Window,
    @Inject('HOSTNAME') private hostname: string,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  loadAppConfig(): Promise<any> {
    // we use this global var to override any settings per apiKey when integrating using components
    if (this.window['__SWISSPOST_TOPOS_ENVCONFIG__'] !== undefined) {
      this.envConfig = this.window['__SWISSPOST_TOPOS_ENVCONFIG__'];
      this.logger.debug(`loading app config from __SWISSPOST_TOPOS_ENVCONFIG__ global var.`);
      return Promise.resolve();
    }

    // we can load the configuration directly from our import because it is correctly set during build per env (see angular.json)
    return Promise.resolve(config)
      .then((data) => {
        this.envConfig = data;
        this.logger.debug(`loading app config from import.`);
      });
  }

  getConfig() {
    if ('gisApiUrl' in this.envConfig) {
      // we have a directly loadable config, return
      return this.envConfig;
    }

    let currentEnv = 'local';

    if (isPlatformServer(this.platformId)) {
      // use environment var to get environment on the server
      currentEnv = process.env.SYSTEM_ENV || 'local';
    } else {
      for (const [env, envCfg] of Object.entries<any>(this.envConfig)) {
        if (envCfg.envHostnames.indexOf(this.hostname) > -1) {
          currentEnv = env;
          break;
        }
      }
    }

    return this.envConfig[currentEnv];
  }
}
