<div class="py-4" *ngIf="hasAnyAddress()">

  <form>
    <h3 class="font-curve-regular fw-bold mb-4" translate>detail.addressing.generateAddress</h3>

    <div class="row">
      <div class="col-rg-6">

        <!-- Switch zwischen Pickpost und Postlagernd und MyPost24 -->
        <fieldset>
          <legend class="visually-hidden" translate>
            detail.addressing.addresstype
          </legend>

          <div class="btn-group mb-regular position-relative" role="group">
            <!-- Pickpost -->
            <ng-container *ngIf="hasPickPostAddress()">
              <input id="detail-addressing-pickpost" class="btn-check" type="radio" [value]="addressType.PICKPOST"
                [(ngModel)]="at" name="addressType" autocomplete="off" />
              <label for="detail-addressing-pickpost" class="btn btn-secondary"
                [ngClass]="at === addressType.PICKPOST ? 'active' : ''" translate>
                detail.addressing.pickpost
              </label>
            </ng-container>

            <!-- Postlagernd -->
            <ng-container *ngIf="hasPostlagerndAddress()">
              <input id="detail-addressing-postlagernd" class="btn-check" type="radio"
                [value]="addressType.POST_LAGERND" [(ngModel)]="at" name="addressType" autocomplete="off" />
              <label for="detail-addressing-postlagernd" class="btn btn-secondary"
                [ngClass]="at === addressType.POST_LAGERND ? 'active' : ''" translate>
                detail.addressing.postlagernd
              </label>
            </ng-container>

            <!-- MyPost24 -->
            <ng-container *ngIf="hasMyPost24Address()">
              <input id="detail-addressing-mypost24" class="btn-check" type="radio" [value]="addressType.MY_POST_24"
                [(ngModel)]="at" name="addressType" autocomplete="off" />
              <label for="detail-addressing-mypost24" class="btn btn-secondary"
                [ngClass]="at === addressType.MY_POST_24 ? 'active' : ''" translate>
                detail.addressing.mypost24
              </label>
            </ng-container>
          </div>
        </fieldset>

        <!-- Anrede -->
        <div class="form-floating mb-regular">
          <select id="anrede" class="form-select form-select-lg" required [(ngModel)]="anredeDropDownVal" name="anrede">
            <option selected translate value="nochoice">
              detail.addressing.chooseSalutation
            </option>
            <option value="male" translate>
              detail.addressing.male
            </option>
            <option value="female" translate>
              detail.addressing.female
            </option>
            <option value="family" translate>
              detail.addressing.family
            </option>
            <option value="nosalutation" translate>
              detail.addressing.noSalutation
            </option>
          </select>
          <label for="anrede" class="form-label" translate>detail.addressing.salutation</label>
        </div>

        <!-- Vor- und Nachname -->
        <div class="form-floating mb-regular">
          <input id="nameVorname" class="form-control" [(ngModel)]="nameVorname" name="nameVorname"
            placeholder="Vor- und Nachname" />
          <label for="nameVorname" class="form-label" translate>detail.addressing.fullname</label>
        </div>

        <!-- Userid, nur bei Pickpost und MyPost24 relevant -->
        <div *ngIf='hasUserId()'>
          <div class="form-floating mb-regular">
            <input id="userId" name="userId" [(ngModel)]="userId" class="form-control"
              placeholder="Vor- und Nachname" />
            <label for="userId" class="form-label" translate>detail.addressing.userId</label>
          </div>
          <p>
            <a [href]="klpUrl" target="_blank" (click)="trackLinkClick($event)">
              <span class="font-size-15 fw-normal" [innerHTML]="'detail.addressing.wtfIsUserId' | translate"></span>&nbsp;<post-icon name="3020"></post-icon>
            </a>
          </p>
        </div>
        <hr>

      </div>
      <div class="col-rg-6">
        <span id="detail-adressing-info" class="visually-hidden" translate>detail.addressing.info</span>
        <div class="p-4 bg-gray" tabindex="0">

          <h4 class="font-curve-regular fw-bold" aria-labelledby="detail-adressing-info">{{getTitleForAddressType() |
            translate}}</h4>
          <p class="font-curve-regular">

            <!-- Anrede -->
            <span class="addresspart-placeholder" *ngIf="showAnredePlaceholder()"
              translate>detail.addressing.salutation</span>
            <span *ngIf="hasAnrede()">{{getAnrede() | async}}&nbsp;</span>

            <!-- Vorname Nachname -->
            <span class="addresspart-placeholder" *ngIf="showVornameNachnamePlaceholder()"
              translate>detail.addressing.fullname</span>
            <span>{{nameVorname}}</span>

            <br>

            <!-- UserId -->
            <ng-container *ngIf="hasUserId()">
              <span *ngIf="at === 'mypost24'">{{'detail.addressing.addressPartMyPost24' | translate}}&nbsp;</span>
              <span *ngIf="at === 'pickpost'">{{'detail.addressing.addressPartPickpost' | translate}}&nbsp;</span>
              <span class="addresspart-placeholder fst-italic text-uppercase" *ngIf="showUserIdPlaceholder()"
                translate>detail.addressing.yourUserId</span>
              <span>{{userId}}</span>
            </ng-container>
            <span *ngIf="at === 'postlagernd'">{{'detail.addressing.addressPartPostlagernd' | translate}}&nbsp;</span>
            <br>
            <span>{{address?.street}}</span>
            <br>
            <span>{{address?.zip}} {{address?.city}}</span>
          </p>
        </div>
      </div>
    </div>
  </form>
</div>