import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements as definePostComponents } from '@swisspost/design-system-components/loader';
import { defineCustomElementPostIcon } from '@swisspost/design-system-components/loaders';
import { defineCustomElements as definePostHeader } from '@swisspost/internet-header/loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const providers = [];

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic([providers]).bootstrapModule(AppModule);
});
void definePostComponents();
void definePostHeader();
void defineCustomElementPostIcon();
