import { AngularInfoWindow } from './googlemaps';

export interface SpiderMarker extends google.maps.Marker {
  status?: SpiderfyStatus;
  infoWindow?: AngularInfoWindow;
}

export enum SpiderfyStatus {
  undefined = 'UNDEFINED',
  spiderified = 'SPIDERFIED',
  spiderifiable = 'SPIDERFIABLE',
  unspiderified = 'UNSPIDERFIABLE',
}
