import { CounterHoliday } from './counterholiday';

export class CounterState {
  public counterTypeName: string;
  public openNow: boolean;
  public timeString: string;
  public openUntil: Date;
  public openAgain: Date;
  public holidays: CounterHoliday[];
}
