import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { BaseResultListDeadlineComponent } from '@app/app/base/components/base-result-deadline.component';

import { CurrentDateTimeService } from '@app/app/common/current-date-time.service';
import { ControllerService } from '@app/app/controller.service';
import { NeedsCacheService } from '@app/app/gis/services/needs-cache.service';
import { OpeningEventService } from 'src/app/gis/services/opening-event.service';
import { SearchParameters } from '@app/app/gis/model/searchparameters';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-result-list-deadline',
  templateUrl: './result-list-deadline.component.html',
  styleUrls: ['./result-list-deadline.component.scss'],
})
export class ResultListDeadlineComponent extends BaseResultListDeadlineComponent {
  constructor(
    protected openingEventService: OpeningEventService,
    protected currentDateTimeService: CurrentDateTimeService,
    protected controllerService: ControllerService,
    protected needsCacheService: NeedsCacheService,
    protected translateService: TranslateService
  ) {
    super(openingEventService, currentDateTimeService, controllerService, needsCacheService, translateService);
  }

  public productTypeDeadlines(): Observable<string[]> {
    const searchParameters: SearchParameters = this.controllerService.getSearchParameters().value;

    if (!searchParameters?.needId || !searchParameters.secondLevelNeedId || !this.poi) {
      return of([]);
    }
    const secondLevelNeedId = searchParameters.secondLevelNeedId;

    return this.needsCacheService.getSecondLevelNeedById(secondLevelNeedId).pipe(map((secondLevelNeed) => {
      if (secondLevelNeed.productTypeDeadlineInListView && secondLevelNeed.productTypeDeadlineInListView.length > 0) {
        return secondLevelNeed.productTypeDeadlineInListView;
      } else {
        return [];
      }
    }));
  }
}
