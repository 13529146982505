/**
 * Return a value based on viewport width but clamped to a min and max
 *
 * @param viewportRange Responsive viewport range to clamp to
 * @param range Range of numbers to choose from
 * @param viewportWidth Current viewport width
 * @returns Percentage of range based on viewport width
 */
export const viewportClamp = (
  viewportRange: [number, number],
  range: [number, number],
  viewportWidth: number
): number => {
  const percent = Math.min(
    Math.max(
      (viewportWidth - viewportRange[0]) /
        (viewportRange[1] - viewportRange[0]),
      0
    ),
    1
  );
  return Math.floor((range[1] - range[0]) * percent + range[0]);
};
