/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { ConfigurationService } from 'src/app/config/configuration.service';
import { PoiDetail } from '../model/poidetail/poidetail';
import { PoiListItem, PoiListItemType, PoiOrAggregate } from '../model/poibase';

const poiTypeMap = {
  '001PST': { marker: 'poi-branch', icon: 1029 },
  '001MP24': { marker: 'poi-mypost24', icon: 1006 },
  '001AG-PICK': { marker: 'poi-pickpost', icon: 1011 },
  '003BE': { marker: 'poi-letterbox', icon: 2166 },
  '004PSTMAT': { marker: 'poi-atm', icon: 3160 },
  '0017': { marker: 'poi-postofficebox', icon: 1030 },
  '001HS': { marker: 'poi-homeservice', icon: 1012 },
  '001PFFIL': { marker: 'poi-pf', icon: 10001 },
  '001BZ': { marker: 'poi-center-letters', icon: 2164 },
  '001BZ-INTERN': { marker: 'poi-center-letters', icon: 2164 },
  '001LZB': { marker: 'poi-logistic-center-letters', icon: 2162 },
  '001LZB-INTERN': { marker: 'poi-logistic-center-letters', icon: 2162 },
  '001LZP': { marker: 'poi-logistic-center-parcel', icon: 2163 },
  '001LZP-INTERN': { marker: 'poi-logistic-center-parcel', icon: 2163 },
  '001PZ': { marker: 'poi-center-parcel', icon: 10000 },
  '001PZ-INTERN': { marker: 'poi-center-parcel', icon: 10000 },
  '001AL': { marker: 'poi-ap-logistics', icon: 10002 },
  '001GLS': { marker: 'poi-ap-logistics', icon: 10002 },
  '001TKST': { marker: 'poi-gas', icon: 3122 },
  '001PFST': { marker: 'poi-postofficebox', icon: 1030 },
  '001GKSS': { marker: 'poi-gk-self-service', icon: 2290 },
  '001VPA': { marker: 'poi-postbus-sales-office', icon: 3107 },
};

const defaultTypeMapEntry = { marker: 'poi', icon: 1029 };

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private readonly configurationService: ConfigurationService,
    private logger: NGXLogger
  ) {}

  public markerUrl(item: PoiOrAggregate | PoiDetail, cluster = false, active = false): string {
    let marker = null;

    if (cluster) {
      marker = `poi-cluster`;
    } else if (item instanceof PoiDetail) {
      marker = poiTypeMap[item.typeId] ? poiTypeMap[item.typeId].marker : defaultTypeMapEntry.marker;
    } else if (item.type === PoiListItemType.poi) {
      const mappedPoi = poiTypeMap[(item as PoiListItem).serviceType.id];
      marker = mappedPoi ? mappedPoi.marker : defaultTypeMapEntry.marker;
    } else if (item.type === PoiListItemType.aggregate) {
      marker = `aggregate`;
    }

    if (!marker) {
      this.logger.warn((item as PoiListItem).serviceType.id, ' has no marker defined');
      marker = `poi`;
    }

    return `${this.configurationService.getConfiguration().webBaseUrl}/assets/map/markers/${marker}${active ? '-active' : ''}.png`;
  }

  public currentLocationUrl() {
    return `${this.configurationService.getConfiguration().webBaseUrl}/assets/map/markers/current-location.png`;
  }

  public iconUrl(item: PoiListItem) {
    if (item?.serviceType?.id in poiTypeMap) {
      return poiTypeMap[item.serviceType.id].icon;
    }
    return defaultTypeMapEntry.icon;
  }
}
