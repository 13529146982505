import {
  APP_BASE_HREF,
  CommonModule,
  DatePipe,
  isPlatformBrowser,
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PlatformLocation,
  registerLocaleData,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDeCH from '@angular/common/locales/de-CH';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeItCH from '@angular/common/locales/it-CH';
import { APP_ID, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ToastrModule } from 'ngx-toastr';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '@app/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { CommonToposModule } from './common/common.module';
import { BaseLanguageService } from './common/language/base-language.service';
import { IS_BOT } from './common/ssr/client-and-bot-only-render.directive';
import { TagManagerService } from './common/tag.service';
import { trimLastSlashFromUrl } from './common/urltrimmer';
import { WebpackTranslateLoader } from './common/webpack-translate-loader';
import { ConfigModule } from './config/config.module';
import { ENV_BASE_URL, EnvConfigService } from './config/env-config.service';
import { DetailModule } from './detail/detail.module';
import { ErrorModule } from './error/error.module';
import { GisModule } from './gis/gis.module';
import { HomeModule } from './home/home.module';

registerLocaleData(localeDeCH, 'de');
registerLocaleData(localeItCH, 'it');
registerLocaleData(localeFrCH, 'fr');
registerLocaleData(localeEnGB, 'en');

const hostFactory = (platformId: object) => {
  if (isPlatformBrowser(platformId)) {
    return window.location.host;
  }
  return '';
};

const appInitializerFn = (
  envConfig: EnvConfigService,
  tagManagerService: TagManagerService,
  platformId: object) => () =>
    envConfig.loadAppConfig().then(() => {
      const config = envConfig.getConfig();
      tagManagerService.setConfig({
        platform: config.tagManagerPlatform,
        environment: config.tagManagerEnvironment,
        container: config.tagManagerContainer,
      });
      if (isPlatformBrowser(platformId)) {
        tagManagerService.loadScript();
      }
    });

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CommonToposModule,
    BrowserModule,
    TransferHttpCacheModule,
    FormsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/logrcv',
      level: environment.production === true ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      timestampFormat: 'yyyy-MM-dd HH:mm:ss.SS',
      enableSourceMaps: true,
      httpResponseType: 'text'
    }),
    NgbModule,
    NgbAccordionModule,
    ToastrModule.forRoot({
      timeOut: 0,
      extendedTimeOut: 0,
      closeButton: true,
      preventDuplicates: true,
      toastClass: 'toast',
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    AppRoutingModule,
    ErrorModule,
    GisModule,
    HomeModule,
    DetailModule,
    ConfigModule,
    ReactiveFormsModule,
    CookieModule.withOptions(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Location,
    DatePipe,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_ID, useValue: 'topos' },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => trimLastSlashFromUrl(s.getBaseHrefFromDOM()),
      deps: [PlatformLocation],
    },
    {
      provide: ENV_BASE_URL,
      useFactory: (s: PlatformLocation) => trimLastSlashFromUrl(s.getBaseHrefFromDOM()),
      deps: [PlatformLocation],
    },
    {
      provide: 'HOSTNAME',
      useFactory: hostFactory,
      deps: [PLATFORM_ID]
    },
    {
      provide: LOCALE_ID,
      useFactory: (languageService: BaseLanguageService) => languageService.getLanguage(),
      deps: [BaseLanguageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnvConfigService, TagManagerService, PLATFORM_ID],
    },
    {
      provide: IS_BOT,
      useValue: false,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
