import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationService } from './configuration.service';
import { HeaderConfigService } from './header-config.service';
import { EnvConfigService } from './env-config.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [ConfigurationService, HeaderConfigService, EnvConfigService]
})
export class ConfigModule { }
