<ng-container *ngIf="terminal">
  <section class="row">
    <div class="col-12 col-lg-6">
      <h3 class="fw-bold font-curve-regular mb-0 mt-4" translate>detail.mp24.boxes.actual</h3>
      <p translate>{{ 'detail.mp24.boxes.lastUpdated' | translate: { time:
        terminal.timestamp | date: 'HH:mm' } }}</p>

      <div class="mp24-table-wrapper">
        <table class="table mp24-table">
          <thead>
            <tr>
              <th translate>
                detail.mp24.boxes.size
              </th>
              <th translate>
                detail.mp24.boxes.free
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let compartment of orderedCompartment">
              <td>
                {{compartment.size}}
              </td>
              <td>
                {{compartment.free}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <h3 class="fw-bold font-curve-regular mb-4 mt-4" translate>detail.mp24.boxes.sizes</h3>
      <div class="btn-group" role="group" aria-label="sizes">
        <button type="button" class="btn btn-lg btn-secondary btn-size" *ngFor="let size of sizes"
          [class.active]="size === selectedSize" (click)="onSizeClick(size)">{{size}}</button>
      </div>
      <div class="d-flex mt-2">
        <div class="card compartment-card w-100">
          <div class="card-body d-flex align-items-center">
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-content-center align-items-center w-100">
                <div class="text-left">
                  {{ 'detail.mp24.boxes.dimension.height' | translate: { cm:
                  selectedCompartmentDimension.height } }}
                </div>
                <img [src]="'assets/mp24/mp24-' + selectedSize + '.png'" alt="Compartment Image"
                  class="compartment-image" />
                <div class="text-right">
                  {{ 'detail.mp24.boxes.dimension.depth' | translate: { cm:
                  selectedCompartmentDimension.depth } }}
                </div>
              </div>
              <div class="mt-2 text-center">
                {{ 'detail.mp24.boxes.dimension.width' | translate: { cm:
                selectedCompartmentDimension.width } }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>