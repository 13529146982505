import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription, interval, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';
import { ConfigurationService } from './config/configuration.service';
import { BaseLocationService } from './gis/location/base.location.service';
import { BoundingBox } from './gis/model/boundingbox';
import { GeocodedArea } from './gis/model/geocodedArea';
import { GeoCodedAreaType } from './gis/model/geocodedAreaType';
import { PoiListItem, PoiOrAggregate } from './gis/model/poibase';
import { PoiList } from './gis/model/poilist';
import { SearchParameters } from './gis/model/searchparameters';
import { GisService } from './gis/services/gis.service';
import { swissBoundaries } from './gis/util/coordinatesutil';
import { isTechPlz } from './gis/util/isTechPlz';

@Injectable({
  providedIn: 'root',
})
export class ControllerService implements OnDestroy {
  readonly emptyPoiList: PoiList = new PoiList();

  private poiItemList: BehaviorSubject<PoiList>;
  private selectedNeedId: BehaviorSubject<number>;
  private geocodedArea: BehaviorSubject<GeocodedArea>;
  private searchParameters: BehaviorSubject<SearchParameters>;
  private locationActive: BehaviorSubject<boolean>;
  private selectedPoi: BehaviorSubject<PoiListItem>;
  private hoveredPoi: Subject<PoiOrAggregate>;
  private hasMapAndMapIsReadySubj: BehaviorSubject<boolean>;
  private mapBoundaries: BehaviorSubject<BoundingBox>;
  private boundsLocked = false;
  private updateDataInterval: Subject<void>;
  private zoomLevel: Subject<number>;
  private searchStarted: Subject<void>;
  private zoom: Subject<number>;
  private intervalSubscription: Subscription;
  private hasMapAndMapIsReady: boolean;

  constructor(
    private gisService: GisService,
    private ngZone: NgZone,
    private locationService: BaseLocationService,
    private configurationService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private logger: NGXLogger
  ) {
    // These Subjects/Observables act as some kind of state/store to share data across components
    this.poiItemList = new BehaviorSubject<PoiList>(this.emptyPoiList);
    this.geocodedArea = new BehaviorSubject<GeocodedArea>(null);
    this.searchParameters = new BehaviorSubject<SearchParameters>(null);
    this.locationActive = new BehaviorSubject<boolean>(false);
    this.selectedNeedId = new BehaviorSubject<number>(0);
    this.selectedPoi = new BehaviorSubject<PoiListItem>(null);
    this.hoveredPoi = new Subject<PoiOrAggregate>();
    this.mapBoundaries = new BehaviorSubject<BoundingBox>(null);
    this.hasMapAndMapIsReadySubj = new BehaviorSubject<boolean>(true);
    this.hasMapAndMapIsReady = true;
    this.updateDataInterval = new Subject();
    this.zoomLevel = new Subject();
    this.zoom = new Subject();
    this.searchStarted = new Subject();
    if (isPlatformBrowser(this.platformId)) {
      this.intervalSubscription = interval(50 * 1000).subscribe(() => this.updateData());
    }
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }


  public resetController() {
    this.poiItemList.next(this.emptyPoiList);
    this.geocodedArea.next(null);
    this.searchParameters.next(null);
    this.locationActive.next(false);
    this.selectedNeedId.next(0);
    this.selectedPoi.next(null);
    this.mapBoundaries.next(null);
  }

  public isInitialSearchDone(): boolean {
    return this.poiItemList.value !== this.emptyPoiList;
  }

  public getIsLocationActiveObservable(): Observable<boolean> {
    return this.locationActive.asObservable();
  }

  public getBoundsLocked(): boolean {
    return this.boundsLocked;
  }

  public getUpdateDataInterval(): Observable<any> {
    return this.updateDataInterval.asObservable();
  }

  public getMapBoundaries(): Observable<BoundingBox> {
    return this.mapBoundaries.asObservable();
  }

  public getSearchObservable(): Observable<PoiList> {
    return this.poiItemList.asObservable();
  }

  public getNeedObservable(): Observable<number> {
    return this.selectedNeedId.asObservable();
  }

  public getGecocodedAreaObservable(): Observable<GeocodedArea> {
    return this.geocodedArea.asObservable();
  }

  public getSearchParameters(): BehaviorSubject<SearchParameters> {
    return this.searchParameters;
  }

  public getPoiSelectedObservable(): Observable<PoiListItem> {
    return this.selectedPoi.asObservable();
  }

  public getHoveredPoiObservable(): Observable<PoiOrAggregate> {
    return this.hoveredPoi.asObservable();
  }

  public getZoomLevelObservable(): Observable<number> {
    return this.zoomLevel.asObservable();
  }

  public getZoomObservable(): Observable<number> {
    return this.zoom.asObservable();
  }

  public getSearchStartedObservable(): Observable<void> {
    return this.searchStarted.asObservable();
  }

  public getHasMapAndMapIsReadyObservable(): Observable<boolean> {
    return this.hasMapAndMapIsReadySubj.asObservable();
  }

  public getHasMapAndMapIsReadValue(): boolean {
    return this.hasMapAndMapIsReady;
  }

  public setHasMapAndMapIsReady(value: boolean): void {
    this.hasMapAndMapIsReadySubj.next(value);
    this.hasMapAndMapIsReady = value;
  }

  /**
   * Calculate pois based on transformed results and don't use the esri provided count
   * which is wrong due to duplicate pois
   */
  public getResultCount(): Observable<number> {
    return this.poiItemList.pipe(
      map((poiList) => {
        if (!poiList) {
          return 0;
        }

        return poiList.count;
      })
    );
  }

  public geocodeArea(area: GeocodedArea) {
    return this.geocodedArea.next(area);
  }

  public setSelectedNeedId(needId: number) {
    this.selectedNeedId.next(needId);
  }

  public resetSearch() {
    this.getSearchParameters().next(null);
    this.showPois(this.emptyPoiList);
  }

  public getDefaultSearchParams(defaultNeedId: number, secondLevelNeedId: string): SearchParameters {
    const config = this.configurationService.getConfiguration();
    const swissGeocodedArea: GeocodedArea = {
      boundingBox: swissBoundaries,
      center: config.defaultCenterOfMap,
      name: 'Switzerland',
      geocodedAreaType: GeoCodedAreaType.unknown,
    };

    // This would show the whole of switzerland on mobile, but it's very tiny
    // fitBounds: true will zoom in a little closer while still showing many aggregates
    // this.map.fitBounds(this.boundingBoxToLatLngBounds(swissBoundaries()));
    return {
      accessibleByWheelChair: false,
      location: swissGeocodedArea,
      date: null,
      fitBounds: true,
      needId: defaultNeedId,
      secondLevelNeedId,
      openNow: false,
      resetFilter: true,
      time: null,
      useCurrentLocation: false,
      viewportWidth: window.innerWidth,
      scrollToMap: false,
    };
  }

  public startDefaultSearch(defaultNeedId: number, secondLevelNeedId: string = null, serviceTypes: string[] = []): SearchParameters {
    const searchParameters = this.getDefaultSearchParams(defaultNeedId, secondLevelNeedId);
    if (serviceTypes.length > 0) {
      searchParameters.serviceTypeFilter = serviceTypes;
    }
    this.startSearch(searchParameters);

    return searchParameters;
  }

  public showPois(pois: PoiList) {
    this.ngZone.run(() => {
      this.poiItemList.next(pois);
    });
  }

  public setSearchParameters(searchParameters: SearchParameters) {
    this.searchParameters.next(searchParameters);
  }

  public showPoi(poi: PoiListItem) {
    this.ngZone.run(() => {
      this.selectedPoi.next(poi);
    });
  }

  public hoverPoi(poi: PoiOrAggregate) {
    this.ngZone.run(() => {
      this.hoveredPoi.next(poi);
    });
  }

  public setMapBoundaries(bbox: BoundingBox) {
    this.ngZone.run(() => {
      this.mapBoundaries.next(bbox);
    });
  }

  public setLocationIsActive(isActive: boolean) {
    if (isActive !== this.locationActive.value) {
      this.locationActive.next(isActive);
    }
  }

  public setZoomLevel(level: number) {
    this.zoomLevel.next(level);
  }

  public setZoom(zoom: number) {
    this.zoom.next(zoom);
  }

  /**
   * Start a search based on user data from the search form and, if applicable, map boundaries
   *
   * @param searchParameters User defined form values
   */
  public startSearch(searchParameters: SearchParameters): Observable<PoiList> {
    //If i have the maps component i have to check if it is ready
    if (!this.hasMapAndMapIsReady) {
      //I trigger search started. in map component it will tell the map to load
      this.searchStarted.next();
      //I give a timeout of 150ms to give time to the map to load the boundaries ecc..
      setTimeout(() => this.executeSearch(searchParameters), 150);
    } else {
      this.searchStarted.next();
      return this.executeSearch(searchParameters);
    }
  }

  public executeSearch(searchParameters: SearchParameters): Observable<PoiList> {
    // Lock bounds so changes to map bounds do not trigger new searches during result displaying process
    this.boundsLocked = true;
    this.searchParameters.next(searchParameters);

    const poiListObservable = this.getBoundingBox(searchParameters)
      .pipe(
        switchMap((boundingBox) => {
          if (boundingBox) {

            this.setMapBoundaries(boundingBox);
            return this.gisService.findByNeed(searchParameters, boundingBox);
          }

          // in this case geocode did not find anything.
          return of(this.emptyPoiList);
        })
      );

    poiListObservable.subscribe({
      next: (poiList: PoiList) => {
        // OMG, we have results
        if (!poiList) {
          this.boundsLocked = false;
          throw new Error(`Can't search with current params: ${JSON.stringify(searchParameters)}`);
        }

        // Keep bounds locked in case there is no result for PLZ/Ort (Geocode).
        // Will be unlocked if the user searches again.
        if (!(poiList && poiList.extent === null)) {
          this.boundsLocked = false;
        }

        this.showPois(poiList);

        return of(poiList);
      },
      error: (error) => {
        this.boundsLocked = false;
        throw error;
      }
    });

    return poiListObservable;
  }

  /**
   * Scroll to the map area, display filters and account for header height
   */
  public scrollMapIntoView() {
    if (!isPlatformBrowser(this.platformId) || !window) {
      return;
    }

    window.requestAnimationFrame(() => {
      const resRef = this.document.querySelector('.results');
      const resultFilter = this.document.querySelector('.result-filter');
      const fullHeader = this.document.querySelector('swisspost-internet-header');
      const scrollHeader = fullHeader?.shadowRoot.querySelector('.main-navigation-container');

      if (!resRef || !resultFilter) {
        return;
      }

      const rect = resRef.getBoundingClientRect();
      const offset = window.scrollY;
      let newTop = Math.ceil(rect.top + offset);

      const headerIsVisible = fullHeader && fullHeader.classList.contains('scrolling-up');
      const headerExactlyOnMapTop = scrollHeader && headerIsVisible && offset === newTop;
      const scrollUpNecessary = scrollHeader && offset > newTop;
      const headerOffset = headerExactlyOnMapTop || scrollUpNecessary ? scrollHeader.clientHeight : 0;
      newTop = newTop - headerOffset;

      if (!window.scrollTo || (window.innerWidth > 1024 && rect.top < resultFilter.clientHeight)) {
        this.document.documentElement.scrollTop = newTop;
      } else {
        window.scrollTo({
          top: newTop,
          behavior: 'smooth',
        });
      }
    });
  }

  /**
   * Sorts the list of geocoded areas (autocomplete) depending on the entered search term.
   * Items are sorted in the order city, zip, region, everything else.
   * Exception: If an item matches exactly the search term, then it should be the top result.
   *
   * @param term
   * @param locations
   */
  public sortGeocodedAreas(term: string, locations: GeocodedArea[]): GeocodedArea[] {
    const prioFn = (areaType: GeoCodedAreaType) => {
      switch (areaType) {
        case GeoCodedAreaType.city:
          return 1;
        case GeoCodedAreaType.zip:
          return 2;
        case GeoCodedAreaType.region:
          return 3;
      }
      return 100;
    };

    locations.sort((a, b) => {
      // if the name matches exactly the search term, it should be the top result
      if (a.name.localeCompare(term, undefined, { sensitivity: 'base' }) === 0) {
        return -1;
      }
      if (b.name.localeCompare(term, undefined, { sensitivity: 'base' }) === 0) {
        return 1;
      }

      const valA = prioFn(a.geocodedAreaType);
      const valB = prioFn(b.geocodedAreaType);
      if (valA === valB) {
        return 0;
      }
      if (valA > valB) {
        return 1;
      }
      return -1;
    });

    return locations;
  }

  /**
   * Figure out what bounding box to use during search depending on various search parameters
   *
   * @param searchParameters
   */
  private getBoundingBox(searchParameters: SearchParameters): Observable<BoundingBox> {
    // Current location
    if (searchParameters.useCurrentLocation) {
      return this.locationService.getLocation().pipe(
        map((location) => {
          // Just in case that the location is null, we do not want the application to crash.
          if (!location) {
            location = this.configurationService.getConfiguration().defaultCenterOfMap;
          }

          // Search in bounding box around the current position (some km's)
          const diffInRadsLat = 0.02;
          const diffInRadsLon = 0.02;

          return {
            northEast: {
              latitude: location.latitude + diffInRadsLat,
              longitude: location.longitude + diffInRadsLon,
            },
            southWest: {
              latitude: location.latitude - diffInRadsLat,
              longitude: location.longitude - diffInRadsLon,
            },
          };
        })
      );
    }

    if (searchParameters.location) {
      return of(searchParameters.location.boundingBox);
    }

    // Geocode
    if (searchParameters.query) {
      return this.gisService.geocode(searchParameters.query).pipe(
        map((locations: GeocodedArea[]) => {
          if (isTechPlz(searchParameters.query)) {
            // Exception for technical plz
            return this.mapBoundaries.value;
          }

          if (locations.length === 0) {
            // Geocode did not find anything
            return null;
          }

          // Pick best guess
          return this.sortGeocodedAreas(searchParameters.query, locations)[0].boundingBox;
        })
      );
    }

    if (!this.mapBoundaries.value) {
      // Whoppa
      throw new Error(
        'Map Boundaries could not be determined, is google maps loaded? mapBoundaries: ' + JSON.stringify(this.mapBoundaries.value)
      );
    }

    return of(this.mapBoundaries.value);
  }

  private updateData() {
    this.updateDataInterval.next();
  }
}
